<template>
  <div class="container flex flex-column justify-center align-center">

    <div v-if="type.main === 'ERROR' && type.subType === 'CLOUD'" class="status-type flex flex-column justify-center align-center">
      <div>
        <img src="../../../../assets/mygmt_v2_images/errors/ErrorCloud.svg" alt="">
      </div>
      <h1 class="title black bold">Something went wrong</h1>
      <div class="description black">
        <p>{{errorMessage}}</p>
      </div>
      <button @click="postError('/Home')" style="width: 60%;" class="btn btn-main">I'll try again later</button>
    </div>
    <div v-if="type.main === 'ERROR' && type.subType === 'CONTACT'" class="status-type flex flex-column justify-center align-center">
      <div>
        <img src="../../../../assets/mygmt_v2_images/errors/Error.svg" alt="">
      </div>
      <h1 class="title black bold">Something went wrong</h1>
      <div class="description black">
        <p>In order to protect your account, you’ve been disconnected from our system due to inactivity.
        </p>
        <p>You can log in again at any time.</p>
      </div>
      <button @click="postError" style="width: 60%;" class="btn btn-m ain">Contact us</button>
       <div @click="backToHomePage" class="pointer">Back to homepage</div>
    </div>
    <div v-if="type.main === 'ERROR' && type.subType === 'DISCONNECTED'" class="status-type flex flex-column justify-between align-center">
      <div >
        <div style="margin-bottom: 30px;" class="flex justify-center">
          <img src="../../../../assets/mygmt_v2_images/errors/diconnected.svg" alt="">
        </div>
        <h1 class="title black bold">You have been disconnected</h1>
        <div class="description black">
          <p>In order to protect your account, you’ve been disconnected 
            from our system due to inactivity.</p>
              
              <p>You can log in again at any time.</p>
        </div>
      </div>
        <button @click="postError('/login')" style="width: 335px;height: 45px;" class="btn btn-m ain">Login</button>
         <!-- <div @click="backToHomePage" class="pointer">Back to homepage</div> -->
    </div>
    <div v-if="type.main === 'SUCCSESS'" class="status-type sucsess flex flex-column justify-center align-center">
      <div><img :src="getSuccsessImg" alt=""></div>
      <h1 class="title black bold">The money successfully
        transferred!</h1>
      <div class="description black">
        <p>You successfully transferred {{calculatedTotal}} {{selectedTransferOption.currency}} to {{$store.getters.getSelectedBeneficiaryDetails.firstName}}.</p>
          <p>
            {{ selectedTransferOption }}
            In a moment you will receive the transfer confirmation via SMS and e-mail.</p>
      </div>
      <button @click="backToHomePage" style="width: 60%;" class="btn btn-main">Back to homepage</button>
      <!-- <div @click="backToHomePage" class="pointer"></div> -->
    </div>

  </div>
      </template>
<script>
import _ from 'lodash';
import { EventBus } from '../../../../event-bus';


export default {
  components: {},
  name: 'TransferStatus',
  props: { 
    type: {
      type: Object,
      required: true,
    },
  },
  created() {
    EventBus.$emit('unload');            
   
  },
  data() {
    return {
     
    };
  },
  computed: {
    getSuccsessImg(){
      return require(`../../../../assets/mygmt_v2_images/beneficiary/${this.type.subType}.svg`)
    },
    selectedTransferOption() {
      return this.$store.getters.getSelectedTransferOption
    },
    calculatedTotal() {
    return this.selectedTransferOption.amount + 
           this.selectedTransferOption.commission - 
           (this.usedCashBack ? this.cashBackCredit : 0);
    },
    errorMessage() {
      if (this.$store.getters.getErrorMessage)
        return this.$store.getters.getErrorMessage;
      else {
        let msg = this.$ml.get(this.$store.getters.getErrorCode);
        return msg ? msg : this.$ml.get('API_EXCEPTION');
      }
    },

  },
  watch: {
  },
  mounted() {
    
  },
  beforeDestroy() {
   this.removeStatus()
   },
  methods: {
    postError(route = '/Home'){
      this.$emit('removeStatus')
      this.$router.push(route)
    },
    backToHomePage(){
      this.$router.push('/Home')
    },
    removeStatus(){
      this.$store.commit('setTransferStatus', null)
    }
   
  },

};
</script>

<style lang="scss" scoped>
@import "../../../../assets/styles/partials/variables";

h1 {
  text-align: center;
}

.container {
  height: 100%;
  width: 100%;
  padding: 25px 45px;
}

.sucsess {
  img {
    margin-top: 60px;
  }
  .title {
    margin-top: -40px;
    text-align: center;
  }

  .description {
    text-align: center;
    margin-top: -50px;
  }
}

.transfer-container[data-v-a1450d20] {
        z-index: 2;
        width: 600px;
        margin: 0 auto;
        min-height: auto;
        overflow: clip;
        max-height: 433px;
        margin-top: 40px;
        padding: 0px 24px;
        border-radius: 20px;
        background-image: none; }

.btn-main {
  height: 60px;
}



.description {
  p {
    margin: 30px 0px;
    text-align: center;
  }
}

.status-type {
  gap: 50px;
}

@media only screen and (min-width: $screen-tablet) {
  
  
}
</style>

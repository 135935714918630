<template>
  <div :dir="$store.getters.getDir" class="body flex flex-column justify-between">
    <form ref="scrollContainer" @submit.prevent="submit" @scroll="handleScroll">
      <div v-if="isTransferProcess" class="flex justify-center">
          <img style="height: 150px; width: 150px;" :src="getBeneficiaryImage" alt="" srcset="">
        </div>
        <h1 v-if="isTransferProcess"> {{ $ml.get('beneficiary.details.' + currentDeliveryOption.transactionClass) }}</h1>
      <div v-if="getNoticeMessage(currentDeliveryOption).mainMessage && isTransferProcess"
        @click="isNoticeMenuOpen = !isNoticeMenuOpen" class="bells-container pointer">
        <div v-if="isNoticeMenuOpen || !getNoticeMessage(currentDeliveryOption).expandedMessage" class="flex relative">
          <div class="flex justify-center align-center"><img class="img-bell " src="../assets/mygmt_v2_images/bell.svg"
              alt="">
          </div>
          <div> <span class="bold">Note:</span> <span v-html="getNoticeMessage(currentDeliveryOption).mainMessage">
            </span></div>
          <img v-if="getNoticeMessage(currentDeliveryOption).expandedMessage" class="chevron chevron-closed"
            src="../assets/mygmt_v2_images/Right-Chevron-blue.svg" alt="">
        </div>
        <div v-else class="flex flex-column">
          <div class="flex justify-between relative">
            <div class="flex align-center">
              <img class="img-bell open" src="../assets/mygmt_v2_images/bell.svg" alt=""> <span>Please Notice</span>
            </div>
            <img src="../assets/mygmt_v2_images/Right-Chevron-blue.svg" class="chevron chevron-open" alt="" srcset="">
          </div>
          <ul>
            <li class="item" v-html="getNoticeMessage(currentDeliveryOption).mainMessage"></li>
            <li class="item" v-html="getNoticeMessage(currentDeliveryOption).expandedMessage"></li>
          </ul>
        </div>
      </div>
      <div ref="titleElement"  class="title">
        {{ $ml.get(title) }}
      </div>

      <div class="" v-if="isTransferProcess">
        <div class="name-row w-100">
          <div class="form-group name-field" v-for="field in getBeneficiaryDetails" :key="field.name">
            <!-- <vue-country-code
                 
                  :preferredCountries="['vn', 'us', 'gb']">
           </vue-country-code> -->
            <label class="label" :for="field.name">{{ $ml.get('beneficiary.' + field.key) || field.name }}</label>
            <div ref="personalDetailsAnchor"></div>
            <div v-if="possibleValues[field.name]" class="">
              <div :ref="field.name" :class="['custom-select', 'relative', 'w-100', {
                'has-error': errors.first(field.name)
              }]" @click="isMobileDevice ? openDrawer(field) : openSelectMenu($event, field)">
                <span class="selected-value">{{ selectedOptions[field.name] || 'Choose an option' }}</span>
              </div>
            </div>
            <div v-else-if="field.name === 'phoneNumber'">
              <div class="form-group">
                <div ref="areaCode" class="phone-input-container">
                  <div @click.prevent="isMobileDevice ? openDrawer({name:'areaCode'}) : openSelectMenu($event, {name:'areaCode'})"  id="area-code" class="area-code custom-select relative flex "   :class="[
                    'area-code-select',
                    { 'has-error': errors.first(field.name) }
                  ]" @blur="validateField('phone')">
                     <div style="gap: 13px;" class="black flex pointer">
                          <img class="flag-icon" :src="imgSrc(selectedAreaCode.iso)" alt="flag" />
                          <div> {{ selectedAreaCode.code }} </div>
                        </div>

                  </div>
                  <div v-if="possibleValues[field.name]" :ref="field.name" class="custom-select relative w-100"
                  :class="{ 'disabled': !possibleValues[field.name].length, 'has-error': errors.first(field.name) }"
                  @click="possibleValues[field.name].length ? (isMobileDevice ? openDrawer(field) : openAreaCodeMenu($event, 'areaCode')) : ''">
                  <span class="selected-value">{{ selectedOptions[field.name] || 'Choose an option' }}</span>
                  </div>

                  <!-- <DesktopMenu :name="'areaCode'" :showCloseButton="false" :parentRef="$refs['areaCode']" :width="'100px'"
                    :position="'custom'" :customPosition="menuPosition">
                    <SearchBoxDropDown :mainClass="'style2'" :possibleValues="areaCodes"
                      placeholder="Search relationship" @select-option="">
                      <template #menu-item="{ item, selectOption }">
                        <div class="menu-item black" @click="selectOption(item.code)">
                          <img class="flag-icon" :src="imgSrc(item.iso)" alt="flag" />
                          {{ item.code }}
                        </div>
                      </template>
                    </SearchBoxDropDown>
                  </DesktopMenu> -->


                  <div class="seperator-container" :class="['seperator-container', {
                    'has-error': errors.first(field.name)
                  }]">
                    <div class="seperator" :class="['seperator', {
                      'has-error': errors.first(field.name)
                    }]"></div>
                  </div>
                  <input id="phone" v-model="beneficiary[field.name]" class="phone-number" type="tel" :class="['form-input phone-number-input', {
                    'has-error': errors.first(field.name)
                  }]" placeholder="" @blur="validatePhoneNumber()" />
                </div>
                <div class="error flex align-center justify-center"><img v-if="errors.first(field.name)"
                  src="../assets/mygmt_v2_images/Info-red.svg" alt="">
                <div>{{ errors.first(field.name) }}</div>
              </div>
              </div>
            </div>
            <div v-else>
              <input  type="text" :id="field.name" :name="field.name" :class="['form-input', 'w-100', {
                'has-error': errors.first(field.name)
              }]" v-model.trim="beneficiary[field.name]" data-vv-validate-on="input"  autocomplete="off" @focus="scrollToTitle"
                v-validate="getConstraints[field.name]">
              <div class="error flex align-center justify-center"><img v-if="errors.first(field.name)"
                  src="../assets/mygmt_v2_images/Info-red.svg" alt="">
                <div>{{ errors.first(field.name) }}</div>
              </div>

            </div>
          </div>

          <div ref="transferInfo"></div>
          <div class="form-group w-100" v-for="field in getTransferInfo" :key="field.name">
            <div v-if="field.individual || !field.company">
              <label style="margin-bottom: 6px;" :for="field.name" v-text="$ml.get('beneficiary.' + field.key)">{{ field.name }}</label>
              <div v-if="possibleValues[field.name]">
                <div :ref="field.name" :class="['custom-select', 'relative', 'w-100', {
                  'has-error': errors.first(field.name)
                }]" @click="isMobileDevice ? openDrawer(field) : openSelectMenu($event, field)">
                  <span class="selected-value">{{ selectedOptions[field.name] || 'Choose an option' }}</span>
                </div>
              </div>
              <input v-else type="text" :id="field.name" :name="field.name" class="form-control"
                v-model.trim="beneficiary[field.name]" data-vv-validate-on="input" 
                v-validate="getConstraints[field.name]">
                <div class="error flex align-center justify-center"><img v-if="errors.first(field.name)"
                src="../assets/mygmt_v2_images/Info-red.svg" alt="">
              <div>{{ errors.first(field.name) }}</div>
            </div>
            </div>
          </div>

          <div class="form-group w-100" v-for="field in getBeneficiaryContacts" :key="field.name">
            <div v-if="field.individual || !field.company">
              <label style="margin-bottom: 6px;" :for="field.name" v-text="$ml.get('beneficiary.' + field.key)">{{ field.name }}</label>
              <div v-if="possibleValues[field.name]" class="custom-select">
                <div :ref="field.name" :class="['custom-select', 'relative', 'w-100', {
                  'has-error': errors.first(field.name)
                }]" @click=" isMobileDevice ? openDrawer(field) : openSelectMenu($event, field);">
                  <span class="selected-value">{{ selectedOptions[field.name] || 'Choose an option' }}</span>
                </div>
              </div>
              <input v-else type="text" :id="field.name" :name="field.name" class="form-control"
                v-model.trim="beneficiary[field.name]" data-vv-validate-on="input"
                v-validate="getConstraints[field.name]">
                <div class="error flex align-center justify-center"><img v-if="errors.first(field.name)"
                src="../assets/mygmt_v2_images/Info-red.svg" alt="">
              <div>{{ errors.first(field.name) }}</div>
            </div>
            </div>
          </div>
        </div>

      </div>
      <div v-else-if="!isTransferProcess">
        <div class="form-group w-100">
          <label for="firstName" v-text="$ml.get('beneficiary.firstName')"></label>
          <input type="text" id="firstName" name="firstName" class="form-control" @focus="scrollToTitle" v-model.trim="beneficiary.firstName"
            v-validate="{ required: 'required', regex: /^[a-zA-Z\s,'-]*$/ }">
          <div class="error"> {{ errors.first('firstName') }}</div>
        </div>
        <div class="form-group w-100">
          <label for="lastName" v-text="$ml.get('beneficiary.lastName')"></label>
          <input type="text" id="lastName" name="lastName" class="form-control" v-model.trim="beneficiary.lastName"
            v-validate="{ required: 'required', regex: /^[a-zA-Z\s,'-]*$/ }">
          <div class="error">{{ errors.first('lastName') }}</div>
        </div>
        <div class="form-group w-100">
          <label for="countryIso3" v-text="$ml.get('destination')"></label>
          <div class="">
            <!-- <model-list-select name="countryIso3" id="countryIso3" class="form-control" data-vv-validate-on="input"
              :list="countries" option-value="iso" v-model="beneficiary.countryIso3"
              :option-text="$store.state.locale.lang" v-validate="'required'">
            </model-list-select> -->
            <div :ref="'countryIso3'" class="custom-select relative w-100 pointer"
                  :class="{ 'disabled': !countries.length, 'has-error': errors.first('countryIso3') }"
                  @click="countries.length ? (isMobileDevice ? openDrawer({name: 'countryIso3'}) : openSelectMenu($event, {name: 'countryIso3'})) : ''">
                  <span class="selected-value">{{ selectedOptions['countryIso3'] }}</span>
           </div>
            <div class="error">{{ errors.first('countryIso3') }}</div>
          </div>
        </div>
        <div class="flex flex-column justify-center align-center">
          <button  style="height: 45px; width: 333px;" class="btn wide"
            :class="{'btn-disabled': !isAllFilled || errors.any()}" @click="submit">{{ $ml.get(isTransferProcess ? 'bntContinue' : 'btnDone') }} <strong>{{ getFieldCounts.validCount }}</strong>  / <strong>{{ getFieldCounts.fieldCount }}</strong></button>
            <div>  </div>
          </div>
          <!-- <div v-if="!isMobileDevice && action !== 'update'" style="height: 600px;"></div> -->
      </div>
      <div v-if="isTransferProcess">
        

        <!-- <hr v-if="getBankInfo" /> -->
        <div class="form-group w-100" v-for="field in getBankInfo" :key="field.name">
          <div v-if="field.individual || !field.company">
            <label style="margin-bottom: 6px;" class="label" :for="field.name"
              v-text="$ml.get('beneficiary.' + field.key + isUnionPay(field.key))">{{
              field.name }}</label>
            <div v-if="possibleValues[field.name]" class="">
              <div v-if="field.dynamic || field.refresh">
                <div v-if="possibleValues[field.name]" :ref="field.name" class="custom-select relative w-100"
                  :class="{ 'disabled': !possibleValues[field.name].length, 'has-error': errors.first(field.name) }"
                  @click="possibleValues[field.name].length ? (isMobileDevice ? openDrawer(field) : openSelectMenu($event, field)) : '';">
                  <span class="selected-value">{{ selectedOptions[field.name] || 'Choose an option' }}</span>

                </div>
              </div>
              <div v-else>
                <!-- <model-select :name="field.name" :id="field.name" :options="possibleValues[field.name]"
                  class="form-control gray" style="width: 100%" data-vv-validate-on="input"
                  v-model="beneficiary[field.name]" v-validate="getConstraints[field.name]" /> -->
                  <div v-if="possibleValues[field.name]" :ref="field.name" class="custom-select relative w-100"
                  :class="{ 'disabled': !possibleValues[field.name].length, 'has-error': errors.first(field.name) }"
                  @click="possibleValues[field.name].length ? (isMobileDevice ? openDrawer(field) : openSelectMenu($event, field)) : ''">
                  <span class="selected-value">{{ selectedOptions[field.name] || 'Choose an option' }}</span>
                </div>
              </div>
            </div>
            <!-- <div v-else-if="field.name === 'phoneNumber'">
              **TODO ADD NEW PHONE NUMBER INPUT
            </div> -->
            <div v-else-if="field.name === 'phoneNumber'">
              <div class="form-group">
                <div ref="areaCode" class="phone-input-container">
                  <div @click.prevent="isMobileDevice ? openDrawer({name:'areaCode'}) : openSelectMenu($event, {name:'areaCode'})"  id="area-code" class="area-code custom-select relative flex "   :class="[
                    'area-code-select',
                    { 'has-error': errors.first(field.name) }
                  ]" @blur="validateField('phone')">
                     <div style="gap: 13px;" class="black flex pointer">
                          <img class="flag-icon" :src="imgSrc(selectedAreaCode.iso)" alt="flag" />
                          <div> {{ selectedAreaCode.code }} </div>
                        </div>

                  </div>
                  <div v-if="possibleValues[field.name]" :ref="field.name" class="custom-select relative w-100"
                  :class="{ 'disabled': !possibleValues[field.name].length, 'has-error': errors.first(field.name) }"
                  @click="possibleValues[field.name].length ? (isMobileDevice ? openDrawer(field) : openAreaCodeMenu($event, 'areaCode')) : ''">
                  <span class="selected-value">{{ selectedOptions[field.name] || 'Choose an option' }}</span>
                  </div>

                  <!-- <DesktopMenu :name="'areaCode'" :showCloseButton="false" :parentRef="$refs['areaCode']" :width="'100px'"
                    :position="'custom'" :customPosition="menuPosition">
                    <SearchBoxDropDown :mainClass="'style2'" :possibleValues="areaCodes"
                      placeholder="Search relationship" @select-option="">
                      <template #menu-item="{ item, selectOption }">
                        <div class="menu-item black" @click="selectOption(item.code)">
                          <img class="flag-icon" :src="imgSrc(item.iso)" alt="flag" />
                          {{ item.code }}
                        </div>
                      </template>
                    </SearchBoxDropDown>
                  </DesktopMenu> -->


                  <div class="seperator-container" :class="['seperator-container', {
                    'has-error': errors.first(field.name)
                  }]">
                    <div class="seperator" :class="['seperator', {
                      'has-error': errors.first(field.name)
                    }]"></div>
                  </div>
                  <input id="phone" v-model="beneficiary[field.name]" class="phone-number" type="tel" :class="['form-input phone-number-input', {
                    'has-error': errors.first(field.name)
                  }]" placeholder="" @blur="validatePhoneNumber()" />
                </div>
                <div class="error flex align-center justify-center"><img v-if="errors.first(field.name)"
                  src="../assets/mygmt_v2_images/Info-red.svg" alt="">
                <div>{{ errors.first(field.name) }}</div>
              </div>
              </div>
            </div>
            <input v-else type="text" :id="field.name" :name="field.name" :ref="field.name"
              class="form-control bank-info w-100" :class="[field.name, {
                'has-error': errors.first(field.name)
              }]" v-model.trim="beneficiary[field.name]" 
              v-validate="field.name === 'maskedCardNumber' ? maskedCardNumberFieldConstraints : getConstraints[field.name]" >
              <div v-if="field.name !== 'phoneNumber'" class="error flex align-center justify-center"><img v-if="errors.first(field.name)"
                src="../assets/mygmt_v2_images/Info-red.svg" alt="">
              <div  >{{ errors.first(field.name) }}</div>
            </div>
          </div>
        </div>
        <!-- <div v-if="isWeChat" v-text="$ml.get('lblDoneWechat1')" /> -->
        <!-- <div v-if="isWeChat" class="tool-tip flex ">
          <img src="../assets/mygmt_v2_images/info.svg" alt="">
          <div>{{ $ml.get('lblDoneWechat') }}</div>
        </div> -->
        <!-- <div v-if="isWeChat" class="tool-tip"/> -->
        <div v-if="isNeedCard && isVisaOrMastercard" class="lblDone">
          <label v-text="$ml.get('cardDepositAccepted')"></label>
        </div>
        <div v-if="isNeedCard" class="lblDone">
          <label v-text="$ml.get('confirmCreditNumber')"></label>
        </div>
        <!-- <div v-if="isBankTransfer" class="lblDone">
          <div class="flex">

            <label class="tool-tip flex align-center" style="white-space: pre-line;"> <img
                src="../assets/mygmt_v2_images/info.svg" alt="">{{ $ml.get('confirmBankAccount') }}</label>

          </div>
          <div v-if="byGmt && !inIsrael" class="lblDone"> <label class="tool-tip flex align-center"><img
                src="../assets/mygmt_v2_images/info.svg" alt="">{{ $ml.get('confirmBankAccount2') }}</label>
          </div>
        </div> -->

        <div style="margin-top: 20px;margin-bottom: 5px;" class="black">Reason of transaction</div>
        <div class="no-arrow custom-select disabled" style="cursor: auto;" @click="">
          <span style="color: grey;" class="selected-value">{{ 'Personal Use' }}</span>
        </div>
        <div class="tool-tip flex align-center">
          <img src="../assets/mygmt_v2_images/info.svg" alt="">
          The transfer is made as a gift or support to a first-degree relative, and it does not constitute income for
          the beneficiary
        </div>
        <div class="flex flex-column justify-center align-center">
        <button  style="height: 45px; width: 333px;" class="btn wide"
          :class="{'btn-disabled': !isAllFilled || errors.any()}" @click="submit">{{ $ml.get(isTransferProcess ? 'bntContinue' : 'btnDone') }} <strong>{{ getFieldCounts.validCount }}</strong>  / <strong>{{ getFieldCounts.fieldCount }}</strong></button>
          <div>  </div>
        </div>
        <!-- <div v-if="!isMobileDevice && action !== 'update'" style="height: 600px;"></div> -->
      </div>


      <!-- <div class="tool-tip flex align-center">
          <img src="../assets/mygmt_v2_images/info.svg" alt="">
          The transfer is made as a gift or support to a first-degree relative, and it does not constitute income for the beneficiary
        </div> -->

      <!-- <div class="lblDone">
        <label v-text="$ml.get('lblDone')"></label>
      </div> -->
    </form>
    <!-- <div>{{ errors }}</div>
    <div>{{ errors.any() }}</div>
    <div>{{ getBeneficiaryDetails }}</div> -->
    
    <DesktopMenu @menuToggled="searchTerm = ''" :name="selectedFieldName" :showCloseButton="false" :width="menuWidth"
      :position="'custom'" :customPosition="menuPosition">
      <div v-if="selectedFieldName !== 'areaCode'">
        <div v-if="possibleValues[selectedFieldName] && possibleValues[selectedFieldName].length > 5"
          class="search-box-container" ref="searchBox">
          <div class="round-box search-box">
            <img src="../assets/mygmt_v2_images/Search.svg" />
            <input v-model="searchTerm" :placeholder="'Search relationship'" type="text" ref="searchBoxInput" />
            <img @click.stop="searchTerm = ''; handleBlur" v-if="searchTerm" class="pointer"
              style="height: 11px; width: 11px;;" src="../assets/mygmt_v2_images/close-black.svg" alt="">
          </div>

        </div>
        <div v-if="possibleValues[selectedFieldName]" class="menu-content">
          <div
            v-for="(item, index) of possibleValues[selectedFieldName].filter(item => item.text.toLowerCase().includes(searchTerm.toLowerCase()))"
            :key="index" @click="selectOption(item, selectedFieldName)" class="menu-item black" style="gap: 5px;"><img v-if="selectedFieldName == 'countryIso3'" class="flag-icon" :src="imgSrc(item.value)" alt="flag" /> <div>{{ item.text }} </div> 
          </div>
        </div>
      </div>
      <SearchBoxDropDown v-else :mainClass="'style2'" :possibleValues="areaCodes"
                      placeholder="Search" @select-option="">
                      <template #menu-item="{ item, selectOption }">
                        <div class="menu-item black" style="gap: 5px;height: 50px;" @click="selectAreaCode(item)">
                          <img class="flag-icon" :src="imgSrc(item.iso)" alt="flag" />
                          <div>
                            {{ item[getLang] }}
                            {{ item.code }}
                          </div>
                        </div>
                      </template>
      </SearchBoxDropDown>
    </DesktopMenu>

    <Drawer ref="optionsRef"
      :title="selectedFieldName ? $ml.get('beneficiary.' + selectedFieldName) : 'Select an option'" @closed=""
      :drawerId="'optionsRef'" class="drawer-wrapper-card flex flex-column justify-between">
      <template v-slot:content>
        <div v-if="selectedFieldName !== 'areaCode'">
          <div v-if="possibleValues[selectedFieldName] && possibleValues[selectedFieldName].length > 6"
            class="search-box-container" ref="searchBox">
            <div class="round-box search-box">
              <img src="../assets/mygmt_v2_images/Search.svg" />
              <input v-model="searchTerm" :placeholder="'Search'" type="text" ref="searchBoxInput" />
              <img @click.stop="searchTerm = ''; handleBlur" v-if="searchTerm" class="pointer"
                style="height: 11px; width: 11px;;" src="../assets/mygmt_v2_images/close-black.svg" alt="">
            </div>
          </div>
          <div v-if="possibleValues[selectedFieldName]" class="menu-content">
            <div
              v-for="(item, index) of possibleValues[selectedFieldName].filter(item => item.text.toLowerCase().includes(searchTerm.toLowerCase()))"
              :key="index" @click="selectOption(item, selectedFieldName);"
              class="menu-item black currency-options-container flex justify-between align-center"> <div class="flex" style="gap: 5px;" ><img style="display: block;margin-left: 30px;" v-if="selectedFieldName == 'countryIso3'" class="flag-icon" :src="imgSrc(item.value)" alt="flag" /><div >{{ item.text }}</div>   </div>   
              <img src="../assets/mygmt_v2_images/Right-Chevron-blue.svg" alt="">

            </div>
          </div>
        </div>
        <div v-else>
          <div
            class="search-box-container" ref="searchBox">
            <div class="round-box search-box">
              <img src="../assets/mygmt_v2_images/Search.svg" />
              <input v-model="searchTerm" :placeholder="'Search Area Code'" type="text" ref="searchBoxInput" />
              <img @click.stop="searchTerm = ''; handleBlur" v-if="searchTerm" class="pointer"
                style="height: 11px; width: 11px;;" src="../assets/mygmt_v2_images/close-black.svg" alt="">
            </div>
          </div>
          <div class="menu-content">
            <div
              v-for="(item, index) of areaCodes.filter(item => item.code.toLowerCase().includes(searchTerm.toLowerCase()))"
              :key="index" @click="selectAreaCode(item);"
              class="menu-item black currency-options-container flex justify-between align-center">
              <img class="flag-icon" :src="imgSrc(item.iso)" alt="flag" />
              {{ item[getLang] }}
              {{ item.code }}
            </div>
          </div>
        </div>
      </template>
    </Drawer>
  </div>
  <!-- <Footer v-if="!isMobileDevice" /> -->
</template>

<script>
import Header from "../components/Header";
import MainNav from "../components/MainNav";
import { Validator } from 'vee-validate';
import countries from '../data/countries';
import { EventBus } from "../event-bus";
import { getBeneficiary, getBeneficiaryDynamicFields, saveBeneficiary, updateBeneficiary } from "../services/api";
import { isMobile, localesDictionary, parseConstraints } from "../services/utils";
import app from "../main";
import Footer from "@/components/Footer.vue";
import beneficiary from "../store/modules/beneficiary";
import DesktopMenu from "../components/DesktopMenu.vue";
import Drawer from "../components/Drawer.vue";
// import VuePhoneNumberInput from '*'
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import VuePhoneNumberInput from 'vue-phone-number-input'
import area_codes from '../data/area_codes.json'
import SearchBoxDropDown from "../components/SearchBoxDropDown.vue";
import _ from 'lodash';



export default {
  components: { MainNav, Header, Footer, DesktopMenu, Drawer, VuePhoneNumberInput, SearchBoxDropDown },
  props: {
    id: {
      type: Number | null | undefined | ''
    },
    action: {
      type: String,
      required: true
    },

  },
  data() {
    return {
      countries,
      areaCode: '',
      beneficiaryDetails: [],
      beneficiaryContacts: [],
      transferInfo: [],
      bankInfo: [],
      constraints: [],
      beneficiary: {
        countryIso3: '',
        maskedCardNumber: ''
      },
      requiredFields: {},
      possibleValues: [],
      isSubmitted: false,
      selectedTransfer: '',
      isMobileDevice: isMobile(),
      errorCount: 0,

      menuPosition: { x: 0, y: 0 },
      menuWidth: 0,
      selectedOption: '',
      selectedAreaCode:'',
      searchTerm: '',
      selectedOptions: {},
      selectedField: {},
      selectedFieldName: '',
      isNoticeMenuOpen: false,
      creationStep: 1,
      fieldCount: 0,
      validCount: 0,
      maxScrollTop: 0,

    }
  },
  beforeRouteEnter(to, from, next) {
    if (to.params.action === 'create' || to.params.action === 'update') {
      next();
    } else {
      next(false);
    }
  },
  computed: {

    areaCodes() {
      return countries
    },

    filledPersonalDetails() {
      return this.creationStep !== 1
    },
    isBeneficiaryDetailsValid() {
    const fields = this.getBeneficiaryDetails || []
    // Check if all required fields in getBeneficiaryDetails are filled and have no errors
    return fields.every((field) => {
      // Ensure the field is required, filled, and has no validation errors
      const value = this.beneficiary[field.name];
      const hasError = this.errors.first(field.name);
      const isFilled = value !== null && value !== undefined && value !== '';
      return isFilled && !hasError;
    });
  }, 
    isBeneficiaryBankInfoValid() {
      const fields = this.getBankInfo || []
    // Check if all required fields in getBeneficiaryDetails are filled and have no errors
    return fields.every((field) => {
      // Ensure the field is required, filled, and has no validation errors
      const value = this.beneficiary[field.name];
      const hasError = this.errors.first(field.name);
      const isFilled = value !== null && value !== undefined && value !== '';
      return isFilled && !hasError;
    });
  },isGetTransferInfoValid() {
    const fields = this.getTransferInfo || []
    // Check if all required fields in getBeneficiaryDetails are filled and have no errors
    return fields.every((field) => {
      // Ensure the field is required, filled, and has no validation errors
      const value = this.beneficiary[field.name];
      const hasError = this.errors.first(field.name);
      const isFilled = value !== null && value !== undefined && value !== '';
      return isFilled && !hasError;
    });
  },

  getAllFields(){
    return [...this.getBeneficiaryDetails || [],...this.getTransferInfo|| [], ...this.getBankInfo|| [], ...this.getBeneficiaryContacts || [] ] 
  },
  isAnyFieldTouched() {
    const fields = this.getAllFields
    // Check if all required fields in getBeneficiaryDetails are filled and have no errors
    return fields.some((field) => {
      // Ensure the field is required, filled, and has no validation errors
      const value = this.beneficiary[field.name];
      // const hasError = this.errors.first(field.name);
      const isTouchd = value !== null && value !== undefined && value !== '';
      return  isTouchd ;
    });
  }, 
  
  isAllFilled() {
    const fields = this.isTransferProcess ? this.getAllFields :['firstName','lastName', 'countryIso3'].map((f)=> {return {name:f}})
    // const fields = this.$refs.scrollContainer.querySelectorAll('input, select, textarea') || [];

  
    return fields.every((field) => {
      const value = this.beneficiary[field.name];
      const isTouchd = value !== null && value !== undefined && value !== '';
      return  isTouchd ;
    });
  }, 
  getFieldCounts(){
    const fields = this.isTransferProcess ? this.getAllFields :['firstName','lastName', 'countryIso3'].map((f)=> {return {name:f}})
    console.log("🚀 ~ getFieldCounts ~ fields:", fields)
    let fieldCount = fields.length
    let validCount = 0

     fields.forEach((field) => {
      const value = this.beneficiary[field.name];
      const isTouchd = value !== null && value !== undefined && value !== '';
      const hasError = this.errors.first(field.name);
      if (isTouchd && !hasError) {
        validCount++
      }
    });
    return  { validCount, fieldCount };

  },
  validateFields(fields) {
  // If the array is empty or non-existent, it's valid
  if (!Array.isArray(fields) || fields.length === 0) {
    return true;
  }

  // Validate each field in the array
  return fields.every((field) => {
    const value = this.beneficiary[field.name];
    const hasError = this.errors.first(field.name);
    const isFilled = value !== null && value !== undefined && value !== '';
    return isFilled && !hasError;
  });
},
    validateField(field){

    },
    getLang() {
      return this.$store.getters.getLang;
    },
    selectedFieldTitle() {
      if (this.selectedField && this.selectedField.name) {
        return this.$ml.get(this.selectedField.name)
      }
      return 'Select an option'
    },
    getBeneficiaryCmp() {
      return this.beneficiary
    },
    getBeneficiaryImage() {
      return require(`../assets/mygmt_v2_images/beneficiary/` + this.currentDeliveryOption.transactionClass + '.svg')
    },
    title() {
      return this.action === 'create' ? 'newBeneficiary' : 'updateBeneficiary'
    },
    getDir() {
      return this.$store.getters.getDir;
    },
    isNeedCard() {
      const bankFields = JSON.parse(sessionStorage.getItem('requiredFields'))['BENEFICIARY']['BENEFICIARY_BANK_INFO']
      return this.isTransferProcess && bankFields && bankFields.some(field => field.name === 'maskedCardNumber')
    },
    isTransferProcess() {
      return this.$router.currentRoute.name === 'transfernew' && this.selectedTransfer;
    },
    isVisaOrMastercard() {
      return this.selectedTransfer && this.selectedTransfer.isVisaOrMastercard;
    },
    isBankTransfer() {
      return this.currentDeliveryOption.transactionClass === 'BANK_TRANSFER'
    },
    isWalletTransfer() {
      return this.currentDeliveryOption.transactionClass === 'WALLET_TRANSFER'
    },
    currentDeliveryOption() {
      return JSON.parse(sessionStorage.getItem('currentDeliveryOption'))
    },
    getBeneficiaryDetails() {
      return this.beneficiaryDetails;
    },
    getBeneficiaryContacts() {
      return this.beneficiaryContacts;
    },
    getTransferInfo() {
      return this.transferInfo;
    },
    getBankInfo() {
      return this.bankInfo;
    },
    getConstraints() {
      return this.constraints
    },
    byGmt() {
      return this.$store.getters.getSelectedTransferOption.correspondentType == 'GMT'
    },
    inIsrael() {
      return this.$store.getters.getSelectedTransferOption.destinationCountry == 'ISR'
    },
    maskedCardNumberFieldConstraints() {
      if (this.action === 'create' || !this.beneficiary['maskedCardNumber']) {
        return this.constraints['maskedCardNumber']
      }
      return {}
    },
    hasErrors() {
      return this.errors.any()
    },

    isWeChat() {
      return this.selectedTransfer && this.selectedTransfer.correspondentIdentifier === "THUNES_WECHATPAY"
    },
    isWalletZambia() {
      return this.selectedTransfer && this.selectedTransfer.destinationCountry === "ZMB" && this.isWalletTransfer
    },
    filteredRelationships() {
      return this.relationships.filter(item =>
        item.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    }
  },
  async created() {
    this.resetFields();
    if (this.action === 'create') {
      this.beneficiary.countryIso3 = this.$store.state.transfer.destinationCountry;
      this.countries = _.orderBy(this.countries, this.getLang).filter(item => {
        return item.moneygram !== false
      });

      if (!this.isTransferProcess) {
        this.possibleValues['countryIso3'] = this.countries.map((country)=>{return{text:country[this.getLang], value: country.iso }} )
      }
    } else if (this.action === 'update') {
      this.beneficiary = await getBeneficiary(this.id);
    }
    this.populateInitialValues()
    this.selectedTransfer = this.$store.getters.getSelectedTransferOption;
    EventBus.$on('changeLang', lang => {
      this.$validator.localize(this.dictionary());
      this.$validator.localize(lang);
    });
    if (this.$store.getters.getSelectedTransferOption && this.$store.getters.getSelectedTransferOption.destinationCountry) {
       this.selectedAreaCode = this.areaCodes.find((areaCode=> this.$store.getters.getSelectedTransferOption.destinationCountry === areaCode.iso)) 
    }
  },
  mounted() {
    this.calculateMaxScroll();
    document.body.setAttribute('dir', this.getDir);
    Validator.localize(this.dictionary());
    Validator.localize(this.getLang);

    let fields = this.getFields();

    if (fields) {
      this.requiredFields = Object.values(fields['BENEFICIARY'])
        .flat()
        .reduce(function (map, obj) {
          map[obj.name] = obj;
          return map;
        }, {});
    }
  },
  watch: {
    'beneficiary.maskedCardNumber'(newValue) {
      const maskedCardNumberField = this.$validator.fields.find({ name: "maskedCardNumber" })
      if (maskedCardNumberField) {
        if (maskedCardNumberField.initialValue !== '' && maskedCardNumberField.initialValue === newValue) {
          maskedCardNumberField.update({ rules: {} })
        } else {
          maskedCardNumberField.update({ rules: this.constraints['maskedCardNumber'] })
        }
      }
    },
  },
  methods: {
    getFields() {
      // console.log(11111111, JSON.parse(sessionStorage.getItem('requiredFields')));
      return JSON.parse(sessionStorage.getItem('requiredFields'));
    },
    validatePhoneNumber() {
    let phone = this.beneficiary.phoneNumber || "";
    let areaCode = this.selectedAreaCode.code || "";
    let msg

    areaCode = String(areaCode);
    phone = String(phone);
    phone = phone.replace(/[^0-9]+|^0+/g, '');
    areaCode = areaCode.replace(/\D/g, "");

    // if (!phone) {
    //   return "Phone number is required.";
    // }

    // Check if phone is already too long
    const MAX_LENGTH = 15; // Adjust based on country
    if (phone.length > MAX_LENGTH) {
      msg = "Phone number is too long.";
    }

   
    const MIN_LENGTH = 7; 
    if (phone.length < MIN_LENGTH) {
      msg = "Phone number is too short.";
    }

    // Combine area code with phone
    // phone = areaCode + phone;

    // Ensure final length is correct
  

    // Save the cleaned number back
    this.beneficiary.phoneNumber = phone;

    console.log("🚀 ~ validatePhoneNumber ~ this.errors:", this.errors)
    this.errors.remove('phoneNumber'); // Removes the error for 'phoneNumber'

    if (msg) {
      this.errors.add({ field: 'phoneNumber', msg });
    }



    return true; // Valid phone number
  },


    scrollToTitle() {
      this.scrollToElement(this.$refs.titleElement);
    },

    // Scroll to the "More Details" section
    scrollToTransferInfo(func, ref = 'transferInfo') {
    return
  const scrollContainer = this.$refs.scrollContainer; // The scrollable container
  const transferInfoElement = this.$refs[ref]; // The target element

  // Calculate the element's position relative to the scroll container
  const elementRect = transferInfoElement.getBoundingClientRect();
  const containerRect = scrollContainer.getBoundingClientRect();

  // Check if the element is already scrolled to the top of the container
  const isAlreadyAtTop = Math.abs(elementRect.top - containerRect.top) < 1; // Adjust the threshold if needed


  if (isAlreadyAtTop) {
    // If already at the top, skip the timeout and execute the function immediately
    if (typeof func === "function") func();
  } else {
    // If not at the top, set the timeout and execute the function after scrolling
    if (typeof func === "function") {
      setTimeout(() => {
        func();
      }, 450);
    }
    // Scroll to the "transferInfo" section
    this.scrollToElement(this.$refs[ref]);
  }
},

scrollToElement(element) {
  if (this.isMobileDevice) return

  const scrollContainer = this.$refs.scrollContainer; // The scrollable container

  if (scrollContainer && element) {
    // Get the bounding rectangles of the element and the container
    const elementRect = element.getBoundingClientRect();
    const containerRect = scrollContainer.getBoundingClientRect();

    // Calculate the offset required to bring the element into view
    const offset = elementRect.top - containerRect.top;

    // Only scroll down if the element is below the visible area of the container
    if (offset > 0) {
      scrollContainer.scrollTo({
        top: scrollContainer.scrollTop + offset,
        behavior: "smooth", // Smooth scrolling
      });
    }
  }
},

calculateMaxScroll() {
      this.$nextTick(() => {
        const scrollContainer = this.$refs.scrollContainer;
        if (scrollContainer) {
          this.maxScrollTop = scrollContainer.scrollHeight - scrollContainer.clientHeight - 600; 
        }
      });
    },
    handleScroll() {
      return
      if (this.isMobileDevice || this.action === 'update') return
      const scrollContainer = this.$refs.scrollContainer;
      if (scrollContainer.scrollTop > this.maxScrollTop) {
        scrollContainer.scrollTop = this.maxScrollTop; // Prevent scrolling past the buffer
      }
    },
    imgSrc(country) {
      try {
        return require(`../assets/mygmt_v2_images/countries/${country}.svg`);
      } catch (error) {
      }
    },

    getNoticeMessage(deliveryOption) {
      const message = { mainMessage: '', expandedMessage: '' };
      if (deliveryOption.transactionClass === 'BANK_TRANSFER') {
        message.mainMessage = this.$ml.get('confirmBankAccount');
      } else if (deliveryOption.correspondentName === 'WeChat') {
        message.mainMessage = `For the first transaction, your recipient needs to approve the WeChat transaction. <br> Tell them to click on the SMS link.`;
        message.expandedMessage = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`;
      }
      return message;
    }
    ,
    openSelectMenu(event, field) {

  // Set the selected field details
  this.selectedFieldName = field.name;
  this.selectedField = field;

  // Get the bounding rectangle of the field
  const ref = Array.isArray(this.$refs[field.name])
    ? this.$refs[field.name][0]
    : this.$refs[field.name];

  if (!ref) {
    return;
  }

  const rect = ref.getBoundingClientRect();

  const {height: rectHeight,width: rectWidth} = rect;
  const menuHeight = 220; 
  const viewportHeight = window.innerHeight; 

  const availableSpaceBelow = viewportHeight - rect.bottom;
  const shouldScroll = availableSpaceBelow < menuHeight;
  const isAreaCodeInput = this.selectedFieldName === 'areaCode'
  let scrollAmount = 0
  if (shouldScroll) {
    const scrollContainer = this.$refs.scrollContainer; 
    scrollAmount = menuHeight - availableSpaceBelow + 100;

    if (scrollContainer) {
      scrollContainer.scrollBy({
        top: scrollAmount,
        behavior: "smooth",
      });
    }
  }


  const scrollX = window.scrollX || document.documentElement.scrollLeft;
  const scrollY = window.scrollY || document.documentElement.scrollTop;

  this.menuWidth =`${rectWidth}px`;


  this.menuPosition = {
    x: `${rect.right + scrollX  }`,
    y: `${rect.bottom + scrollY - scrollAmount  }`,
  };
  console.log("🚀 ~ openSelectMenu ~ menuPosition:", this.menuPosition)

  // Emit the event to toggle the menu
  setTimeout(() => {
    EventBus.$emit("toggleMenu", event, field.name);
  }, shouldScroll ? 450 : 0);
},


      openAreaCodeMenu(event, field) {
      console.log('nefore open');
      setTimeout(() => {
        // Need to fake delay so Vue can registerthe name prop before opening
        EventBus.$emit('toggleMenu', event, 'areaCode');
      }, 0)
    },  
      openSelectMenu2(event, name) {
        EventBus.$emit('toggleMenu', event, name);
    },
    openDrawer(field) {
      this.selectedFieldName = field.name
      this.selectedField = field

      // this.selectedBeneficiary = beneficiary;
      // modalHandler('.modal-mask-message', 'addClassOpen');
      this.$refs.optionsRef.openDrawer()

    },

    selectOption(option, fieldname) {
      if (typeof option === 'string') {
        this.selectedOptions[fieldname] = option;
        this.beneficiary[fieldname] = option
      } else {
        this.selectedOptions[fieldname] = option.text;
        this.beneficiary[fieldname] = option.value
        this.beneficiary = {...this.beneficiary}
      }
      if (this.isMobileDevice) {
        this.$refs.optionsRef.closeDrawer()
      } else {
        EventBus.$emit('toggleMenu', null, fieldname);
      }
      this.searchTerm = ''
      this.selectedFieldName = ''
      if (this.selectedField.dynamic || this.selectedField.refresh) {

        this.updateDynamicFields(fieldname)
        const relatedFields = this.selectedField.relatedFields
        if (relatedFields && relatedFields.length) {
          relatedFields.forEach((fieldName) => delete this.selectedOptions[fieldName])
        }

        this.selectedOptions[relatedFields]
      }
      this.$forceUpdate();


    },
    selectAreaCode(areaCode){
    this.selectedAreaCode = areaCode
    if (this.isMobileDevice) {
        this.$refs.optionsRef.closeDrawer()
      } else {
        EventBus.$emit('toggleMenu',null, 'areaCode');
    }
  },
    resetFields() {
    
      let map = this.getFields();
      if (map) {
        this.beneficiaryDetails = map['BENEFICIARY']['BENEFICIARY_DETAILS'];
        this.beneficiaryContacts = map['BENEFICIARY']['BENEFICIARY_CONTACTS'];
        this.transferInfo = map['BENEFICIARY']['TRANSFER_INFO'];
        this.bankInfo = map['BENEFICIARY']['BENEFICIARY_BANK_INFO'];
        this.constraints = JSON.parse(sessionStorage.getItem('beneficiaryFieldsConstraints'));;
      }
    },
    submit() {
      if (this.errors.any() || !this.isAllFilled) return
      const transactionClass = this.$store.getters.getSelectedTransferOption
      // if (!this.filledPersonalDetails && this.getBankInfo && this.getBankInfo.length && transactionClass) {
      //   console.log(222);
      //   this.creationStep = 2
      //   return
      // }
      console.log('SUBMIT');
      
      this[this.action]();
    },
    isUnionPay(fieldName) {
      return (this.beneficiary.countryIso3 === 'CHN' && fieldName === 'maskedCardNumber') ? '_unionPay' : ''
    },
    addAreaCode(){
    if (this.beneficiary.phoneNumber && this.selectedAreaCode.code) {
      return {...this.beneficiary, phoneNumber: this.beneficiary.phoneNumber && this.selectedAreaCode.code}
    }
    return this.beneficiary
    },
    create() {
      this.$validator.validate().then(result => {
        if (result && !this.isSubmitted) {
          EventBus.$emit('load');;
          this.isSubmitted = true;
          let self = this;
          const beneficiaryCopy = {...this.beneficiary}
          if (this.beneficiary.phoneNumber && this.selectedAreaCode.code) {
            beneficiaryCopy.phoneNumber = this.selectedAreaCode.code.replace(/\D/g, "") + this.beneficiary.phoneNumber
          }


          const data = { beneficiary: beneficiaryCopy  };
          const deliveryOption = this.currentDeliveryOption;
          if (deliveryOption) {
            data['correspondentId'] = deliveryOption.correspondentId;
            data['transaction'] = { amount: deliveryOption.amount, currency: deliveryOption.currency, payoutCurrency: deliveryOption.payoutCurrency, transactionClass: deliveryOption.transactionClass }
          }
          saveBeneficiary(data, this.errors, (response) => {
            self.isSubmitted = false;
            EventBus.$emit('unload');;
            if (!response.errorCode) {
              this.$store.dispatch('loadBeneficiary');
              this.$store.commit('setBeneficiaryValid', true);
              if (this.isTransferProcess) {
                this.$store.commit('setTransferDestinationCountry', this.beneficiary.countryIso3);
                this.$store.commit('setCurrentBeneficiary', response.result);
                this.$store.commit('setCurrentBeneficiaryDetails', this.beneficiary); // after reload is undefined
                this.$analyticsService.transmitSwitchCase('transaction', 'transaction_s_3', { error_count: this.errorCount, beneficiary: 'new_beneficiary' })
                // this.$router.push({ name: 'transfernew' });
                // this.$emit('createdBeneficiary')
              } else {
                // this.$router.push({ name: 'beneficiaries' })
              }
              this.$emit('createdBeneficiary')

            }
          }, this.$ml);
          setTimeout(() => this.isSubmitted = false, 10000);
        } else {
          this.errorCount = this.errorCount + 1
        }
      });
    },
    update() {
      console.log('UPDATE')
      console.trace("Function called");

      this.$validator.validate().then(result => {
        if (result  && !this.isSubmitted) {
          EventBus.$emit('load');;
          this.isSubmitted = true;
          let self = this;
          const maskedCardNumberField = this.$validator.fields.find({ name: "maskedCardNumber" })
          const body = (this.$refs.maskedCardNumber && maskedCardNumberField.initialValue === this.beneficiary.maskedCardNumber) ? Object.assign({}, this.beneficiary, { maskedCardNumber: '' }) : this.beneficiary
          const beneficiaryCopy = {...body}
          if (this.beneficiary.phoneNumber && this.selectedAreaCode.code) {
            beneficiaryCopy.phoneNumber = this.selectedAreaCode.code.replace(/\D/g, "") + this.beneficiary.phoneNumber
          }
          const data = { beneficiary: beneficiaryCopy };
          const deliveryOption = this.currentDeliveryOption;
          if (deliveryOption) {
            data['correspondentId'] = deliveryOption.correspondentId;
            data['transaction'] = { amount: deliveryOption.amount, currency: deliveryOption.currency, payoutCurrency: deliveryOption.payoutCurrency, transactionClass: deliveryOption.transactionClass }
          }
          updateBeneficiary(data, this.errors, (response) => {
            console.log('UPDATING1');
            
            self.isSubmitted = false;
            EventBus.$emit('unload');;
            if (!response.errorCode) {
              this.$store.dispatch('loadBeneficiary');
              this.$store.commit('setBeneficiaryValid', true);
              if (this.isTransferProcess) {
                this.$store.commit('setCurrentBeneficiary', response.result);
                this.$store.commit('setTransferDestinationCountry', this.beneficiary.countryIso3);
                this.$store.commit('setBeneficiaryUpdated', true);
                this.$store.commit('setCurrentBeneficiaryDetails', this.beneficiary);
                this.$emit('update')
                // this.$router.push({ name: 'transfernew' });
              } else {
                this.$router.push({ name: 'beneficiaries' })
              }
            }
          }, this.$ml);
          setTimeout(() => this.isSubmitted = false, 10000);
        }
      });
    },
    populateInitialValues() {
      const beneficiaryFields = [].concat(this.beneficiaryDetails || []).concat(this.beneficiaryContacts || []).concat(this.bankInfo || []).concat(this.transferInfo || [])
      for (const i in beneficiaryFields) {
        const field = beneficiaryFields[i]
        if (!this.beneficiary[field.name] && field.initialValue) {
          this.beneficiary[field.name] = field.initialValue
        }
        if (field.possibleValues) {
          this.possibleValues[field.name] = field.possibleValues.map(element => {
            const text = field['possibleKeys'] ? this.$ml.get(field.name + '.' + element) : element
            return { text: text, value: element }
          });
          if (this.beneficiary[field.name]) {
            this.updateDynamicFields(field.name)

            if (typeof this.beneficiary[field.name] === 'string') {
        this.selectedOptions[field.name] = this.beneficiary[field.name];
        this.beneficiary[field.name] = this.beneficiary[field.name]
      } else {
        this.selectedOptions[field.name] = this.beneficiary[field.name].text;
        this.beneficiary[field.name] = this.beneficiary[field.name].value
      }
          }
        }
      }
    },
    async updateDynamicFields(name) {
      EventBus.$emit('load');;
      let selectedOption = this.$store.getters.getSelectedTransferOption;
      this.beneficiary.country = { iso3: this.beneficiary.countryIso3 }
      const data = {
        updatedField: { name: name, value: this.beneficiary[name] },
        beneficiary: this.beneficiary,
        transaction: {
          amount: selectedOption.amount,
          currency: selectedOption.currency,
          payoutCurrency: selectedOption.payoutCurrency,
          transactionClass: selectedOption.transactionClass
        },
        correspondent: {
          id: selectedOption.correspondentId
        }
      }
      const res = await getBeneficiaryDynamicFields(data);
      if (this.requiredFields[name]['relatedFields']) {
        this.requiredFields[name]['relatedFields'].forEach(field => this.possibleValues[field].splice(0, this.possibleValues[field].length))
        const updatedFields = Object.values(res.data.result['BENEFICIARY'])
          .flat()
          .reduce(function (map, obj) {
            map[obj.name] = obj;
            return map;
          }, {});
        this.requiredFields[name]['relatedFields']
          .forEach(field => {
            this.possibleValues[field]
              .push(...updatedFields[field].possibleValues.map(element => {
                return { text: element, value: element }
              }))
          }
          )
      } else {
        parseConstraints(res.data.result);
        sessionStorage.setItem('requiredFields', JSON.stringify(res.data.result));
      }
      this.resetFields();
      EventBus.$emit('unload');;
    },
    dictionary() {
      const messages = {
        required: this.$ml.get("E_REQUIRED"),
        numeric: this.$ml.get("E_NUMERIC"),
        regex: this.$ml.get("E_REGEX_english"),
        min: (field, value) => this.$ml.get("E_MIN_CHAR.1") + ` ${value} ` + this.$ml.get("E_MIN_CHAR.2"),
        max: (field, value) => this.$ml.get("E_MAX_CHAR.1") + ` ${value} ` + this.$ml.get("E_MAX_CHAR.2"),
        length: (field, value) => this.$ml.get("E_LEMGTH.1") + ` ${value} ` + this.$ml.get("E_LEMGTH.2")
      }
      return localesDictionary({ messages: messages });
    },
    prevStep() {
      if (this.isTransferProcess)
        this.$router.push('/transfer');
      else this.$router.push('/beneficiaries');
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/partials/variables";
@import "../assets/styles/partials/mixins";
@import "../assets/styles/beneficiary_form.less";

.body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80vh;
  // height: calc(100vh - 200px);
}




form {
  max-height: 80vh;
  min-height: 55vh;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.drawer-wrapper {
  left: 0;
  padding: 45px 0px 35px 0px;

}

::v-deep .head-line {
  padding: 10px 25px 10px 25px !important;
}

::v-deep .lines-box-wrapper {
  min-height: 30vh;
}

::v-deep .custom-phone {
  .input-tel.lg .input-tel__input {
    height: 48px !important;

  }
}

.flag-icon {
  height: 20px !important;
  width: 20px !important;
}

::v-deep .custom-phone {
  .vue-recycle-scroller__item-view {
    height: 10px !important;
    margin-top: 10px;
    // background-color: red;

  }

  .select-country-container {
    .country-selector__input {
      height: 48px !important;
    }

    .input-tel {
      height: 48px !important;
    }

    .input-tel__input {
      height: 48px !important;

    }

    .country-selector.lg.has-value .country-selector__input {
      padding-top: 5px !important;
    }


  }
}




input {
  color: black;
 }

.tool-tip {
  color: black;
  background-color: #E3F3FF;
  padding: 10px;
  margin-top: 20px;

  color: var(--Main-Blue, #0E1F81);
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Assistant;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  border-radius: 4px;

  img {
    margin-right: 5px;
  }
}

.no-arrow.custom-select:after {
  display: none;

}


.currency-options-container {

  color: #000;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Assistant;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  height: 70px;
  border-bottom: 1px solid #EEE;
  // z-index: 10;

  .country-img {
    margin-right: 10px;
  }
}

.currency-options-container:last-child {
  border-bottom: none;
}

.title {
  color: #000;
  font-family: Assistant;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;
}

.selected-value {
  font-weight: 600;
}

h1 {
  color: #000;
  font-family: Assistant;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  margin-bottom: 20px
}

.search-box-container {
  padding-top: 10px;
  padding-left: 20px;
  background: white;
  padding-bottom: 1px;
  padding-right: 20px;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  filter: drop-shadow(0px 9px 5px rgba(0, 0, 0, 0.05));

  .search-box {
    display: flex;
    align-items: center;
    cursor: text;
    padding-bottom: 17px;
    padding-top: 17px;

    img {
      width: 23px;
      height: 23px;
    }

    input {
      border: none;
      font-size: 16px;
      width: 100%;
      margin-left: 10px;

    }
  }

}

.group-btn {
  padding: 0px 0px;
}

.btn-disabled {
  background-color: #6B7AB0;
  &:hover {
  background-color: #6B7AB0;
  }
}

.btn {
  margin-top: 25px;
}



.menu-content {
  max-height: 220px;
  overflow-y: auto;
  padding-left: 0;
  padding-right: 0;
}



::v-deep .menu-content {
  padding: 0px 0px 0px 0px !important;
}

.bells-container {
  margin-bottom: 20px;
  margin-top: 20px;
  border-radius: 8px;
  border: 1px solid #E4E8EB;
  background: #FFF;
  padding: 15px 25px 15px 15px;
  color: var(--Main-Blue, #0E1F81);
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Assistant;
  font-size: 13px;
  font-style: normal;
  // font-weight: 700;
  line-height: normal;

  span {
    font-weight: 700;
  }

  .chevron {
    position: absolute;
    right: -20px
  }

  ul {
    list-style: none;
    /* Remove the default bullet */
    padding: 0;
    /* Reset padding to avoid extra space */
    margin: 0;
    /* Adjust margin if needed */
  }

  li {
    position: relative;
    padding-left: 1em;
    /* Space for the custom bullet */
    // text-indent: -0.5em; /* Align text after bullet */
  }

  li::before {
    content: "•";
    /* Custom bullet */
    position: absolute;
    left: 0;
    /* Align bullet to the left of li */
    top: 0.1em;
    /* Adjust vertically if necessary */
    color: black;
    /* Set bullet color */
  }


  box-shadow: 0px 4px 24px 0px rgba(177, 177, 177, 0.13);


  .img-bell {
    height: 32px;
    width: 29px;
    max-width: none;
    // margin-left: 15px;
    margin-right: 15px;
  }
 

  .img-bell.open {
    height: 24px;
    width: 22px;
    max-width: none;
    // margin-left: 15px;
    margin-right: 5px;
    margin-bottom: 5px;

  }

  .chevron-open {
    transform: rotate(270deg);
    width: 35px;
    height: 15px;

  }

  .chevron-closed {
    transform: rotate(90deg);
    width: 35px;
    height: 15px;

  }

}

[dir="rtl"] .bells-container .img-bell
 {
   margin-right: 0px;
   margin-left: 15px;
 }

 [dir="rtl"] .bells-container .chevron {
    position: absolute;
    left: -15px;
    right: auto;
  }
 [dir="rtl"] .bells-container  li::before {
    right: -10px;
  }
 [dir="rtl"] .tool-tip  img {
    margin-right: 0px;
    margin-left: 5px;
  }



.menu-item {
  cursor: pointer;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding: 0 20px 0 20px;

  &:hover {
    border-bottom: 1px solid #EEE;
    border-top: 1px solid #EEE;
    background: #F1F6F9;
  }
}

.form-container {
  max-width: 600px;
  /* margin: 0 auto; */
  padding-top: 20px;
}

.custom-select {
  color: black;
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 5px;
  border: 1px solid #E4E8EB;
  background: #FFF;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;
  background-color: white;
  cursor: pointer;
  position: relative;
  height: 48px;

}

.form-control {
  height: 48px;
}

[dir="rtl"] .area-code {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: none !important;


}

[dir="ltr"] .area-code {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: none !important;


}

::v-deep .vue-phone-number-input label {
  display: none;
}

::v-deep .vue-phone-number-input input::placeholder {
  color: transparent;
}

.disabled {
  border: 1px solid #EBEBEB;
  background: #F3F3F3;
  cursor: auto;
}

.seperator-container {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  padding: 5px 0px 5px 0px;
}

.phone-input-container {
  display: flex;
  /* gap: 10px; */
}

.area-code-select {
  width: 120px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;
  background-color: white;
}

.phone-number-input {
  flex: 1;
}

.seperator {
  border-right: 2px solid #ddd;
  width: 1px;
  height: 100%;
}

[dir="rtl"] .phone-number {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: none !important;
}

[dir="ltr"]  .phone-number {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: none !important;
}



.payment-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-bottom: 25px;
}

.name-row {
  // display: flex;
  // gap: 20px;
  // width: 100%;
  // flex-wrap: wrap;
}

.form-input {
  width: calc(50% - 20px);

  /* Adjust width for two inputs in one row */
}

/* Make the third input take 100% width on a new row */


.name-field {
  // flex: 1;
  // width: calc(50% - 10px);
  /* Adjust width for two inputs in one row */

}

// .name-field:nth-child(n+3) {
//   width: 100%;
// }

.phone-input-container {
  display: flex;
  direction: inherit;
  /* gap: 10px; */
}

.area-code-select {
  width: 120px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;
  background-color: white;
}

.phone-number-input {
  direction: inherit;
  flex: 1;
}

.card-details {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

label {
  color: #000;
  font-family: Assistant;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  // margin-bottom: 10px;
}

.form-input,
.form-control {
  padding: 12px;
  border-radius: 5px;
  border: 1px solid #E4E8EB;
  background: #FFF;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;
  width: 100%;
  box-sizing: border-box;

}

.form-input:focus,
.area-code-select:focus,
.seperator-container:focus,
.form-control:focus {
  outline: none;
  border-color: #2196f3;

}

.phone-input-container:focus-within .area-code-select,
.phone-input-container:focus-within .phone-number-input,
.phone-input-container:focus-within .seperator-container,
.phone-input-container:focus-within .seperator {
  border-color: #2196f3;
  /* background-color: #2196f3; */
}


.form-input.has-error,
.area-code-select.has-error,
.seperator-container.has-error,
.form-control.has-error,
.custom-select.has-error {
  border-color: #ff5252;
}

.seperator.has-error {
  border-color: #ff5252;
}

.error-message {
  color: #ff5252;
  font-size: 12px;
  /* min-height: 16px; */
}

.error {
  margin-bottom: 5px
}

.submit-button {
  padding: 12px 24px;
  background-color: #2196f3;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #1976d2;
}

.submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

@media only screen and (min-width: $screen-tablet) {
  // .body {
  //   height: calc(100vh - 320px);

  // }

  .btn {
  margin-top: 35px;
}


  form {
    max-height: 63vh !important;
  }

  .custom-select {
    height: 60px;
  }

  .form-control {
    height: 60px;
    color: black;
  }

  .form-input {
    height: 60px;
  }

  .body {

    height: 64vh;
    // height: calc(100vh - 200px);
  }


  ::v-deep .custom-phone {
    .input-tel.lg .input-tel__input {
      height: 60px !important;

    }

    .select-country-container {
      .country-selector__input {
        height: 60px !important;
      }

      .input-tel {
        height: 60px !important;
      }

      .input-tel__input {
        height: 60px !important;

      }

      .country-selector.lg.has-value .country-selector__input {
        padding-top: 5px !important;
      }


    }
  }
}
</style>

<template>
  <div class="form-container">
    <div class="title">
      Add new beneficiary
    </div>
    <form @submit.prevent="handleSubmit" class="payment-form">
      <!-- Name Inputs -->
      <div class="name-row">
        <div class="form-group name-field">
          <label for="firstName">First Name</label>
          <input id="firstName" v-model="formData.firstName" type="text" :class="['form-input', {
            'has-error': errors.firstName
          }]" placeholder="Type" @blur="validateField('firstName')" />
          <span class="error-message">{{ errors.firstName }}</span>
        </div>

        <div class="form-group name-field">
          <label for="lastName">Last Name</label>
          <input id="lastName" v-model="formData.lastName" type="text" :class="['form-input', {
            'has-error': errors.lastName
          }]" placeholder="Type" @blur="validateField('lastName')" />
          <span class="error-message">{{ errors.lastName }}</span>
        </div>
      </div>

      <!-- Phone Input with Area Code -->
      <div class="form-group">
        <label for="phone">Phone Number</label>
        <div class="phone-input-container">
          <select class="area-code" v-model="formData.areaCode" :class="['area-code-select', {
            'has-error': errors.phone
          }]" @blur="validateField('phone')">
            <option value="">Area Code</option>
            <option v-for="code in areaCodes" :key="code" :value="code">
              {{ code }}
            </option>
          </select>
          <div class="seperator-container" :class="['seperator-container', {
            'has-error': errors.phone
          }]">
            <div class="seperator" :class="['seperator', {
              'has-error': errors.phone
            }]"></div>
          </div>
          <input id="phone" v-model="formData.phoneNumber" class="phone-number" type="tel" :class="['form-input phone-number-input', {
            'has-error': errors.phone
          }]" placeholder="1234567" @blur="validateField('phone')" />
        </div>
        <span class="error-message">{{ errors.phone }}</span>
      </div>
      <div class="tool-tip flex align-center">
        <img src="../../../../assets/mygmt_v2_images/info.svg" alt="">
        Please provide the number linked to the wallet.
      </div>
      <div class="black"> What’s your relationship with the beneficiary?</div>

      <div ref="selectRelationship" class="custom-select relative" @click="openSelectMenu()">
        <span class="selected-value">{{ selectedOption || 'Choose an option' }}</span>
      </div>

      <DesktopMenu ref="selectRelationshipMenu" name="selectRelationship" :showCloseButton="false" :width="menuWidth"
        :position="'custom'" :customPosition="menuPosition">
        <div>
          <div class="search-box-container" ref="searchBox">
            <div class="round-box search-box">
              <img src="../../../../assets/mygmt_v2_images/Search.svg" />
              <input v-model="searchTerm" :placeholder="'Search relationship'" type="text" ref="searchBoxInput" />
              <img @click.stop="searchTerm = ''; handleBlur" v-if="searchTerm" class="pointer"
                style="height: 11px; width: 11px;;" src="../../../../assets/mygmt_v2_images/close-black.svg" alt="">
            </div>
          </div>
          <div class="menu-content">
            <div v-for="(item, index) of filteredRelationships" :key="index" @click="selectOption(item)"
              class="menu-item black">{{ item }}</div>
          </div>
        </div>
      </DesktopMenu>

      <div class="black">Reason of transaction</div>
      <div class="custom-select disabled" @click="">
        <span style="color: grey;" class="selected-value">{{ 'Personal Use' }}</span>
      </div>

      <!-- Credit Card Input -->
      <!-- <div class="form-group">
        <label for="creditCard">Credit Card Number</label>
        <input
          id="creditCard"
          v-model="formData.creditCard"
          type="text"
          :class="['form-input', { 
            'has-error': errors.creditCard 
          }]"
          placeholder="4111 1111 1111 1111"
          @input="formatCreditCard"
          @blur="validateField('creditCard')"
        />
        <span class="error-message">{{ errors.creditCard }}</span>
      </div> -->

      <!-- Card Details -->
      <!-- <div class="card-details">
        <div class="form-group">
          <label for="expiration">Expiration Date</label>
          <input
            id="expiration"
            v-model="formData.expiration"
            type="text"
            :class="['form-input', { 
              'has-error': errors.expiration 
            }]"
            placeholder="MM/YY"
            @input="formatExpiration"
            maxlength="5"
            @blur="validateField('expiration')"
          />
          <span class="error-message">{{ errors.expiration }}</span>
        </div>

        <div class="form-group">
          <label for="cvv">CVV</label>
          <input
            id="cvv"
            v-model="formData.cvv"
            type="text"
            :class="['form-input', { 
              'has-error': errors.cvv 
            }]"
            placeholder="123"
            maxlength="3"
            @blur="validateField('cvv')"
          />
          <span class="error-message">{{ errors.cvv }}</span>
        </div>
      </div> -->

      <button type="submit" class="submit-button" :disabled="!isFormValid">
        Next
      </button>
    </form>
  </div>
</template>

<script>
import { event } from 'jquery';
import DesktopMenu from '../../../../components/DesktopMenu.vue';
import Drawer from '../../../../components/Drawer.vue';
import { EventBus } from '../../../../event-bus';
export default {
  name: 'PaymentForm',
  components: { DesktopMenu, Drawer },
  data() {
    return {
      formData: {
        firstName: '',
        lastName: '',
        areaCode: '',
        phoneNumber: '',
        creditCard: '',
        expiration: '',
        cvv: ''
      },
      menuPosition: { x: 0, y: 0 },
      menuWidth: 0,
      selectedOption: '',
      errors: {
        firstName: '',
        lastName: '',
        phone: '',
        creditCard: '',
        expiration: '',
        cvv: ''
      },
      touched: {
        firstName: false,
        lastName: false,
        phone: false,
        creditCard: false,
        expiration: false,
        cvv: false
      },
      areaCodes: ['201', '202', '203', '205', '206', '207', '208', '209', '210', '212', '213', '214', '215', '216', '217', '218', '219', '220', '224', '225'],
      relationships: [
        "Father",
        "Mother",
        "Brother",
        "Sister",
        "Grandfather",
        "Grandmother",
        "Uncle",
        "Aunt",
        "Cousin",
        "Nephew",
        "Niece",
        "Son",
        "Daughter",
        "Stepfather",
        "Stepmother",
        "Stepson",
        "Stepdaughter",
        "Husband",
        "Wife",
        "Partner",
        "Friend",
        "Neighbor",
        "Colleague",
        "Mentor",
        "Guardian"
      ],
      searchTerm: '',



    };
  },
  computed: {
    isFormValid() {
      const touchedFields = Object.entries(this.touched)
        .filter(([_, isTouched]) => isTouched)
        .map(([field]) => field);

      const noErrors = !touchedFields
        .some(field => this.errors[field]);

      const allFieldsFilled =
        this.formData.firstName &&
        this.formData.lastName &&
        this.formData.areaCode &&
        this.formData.phoneNumber
      // &&
      // this.formData.creditCard &&
      // this.formData.expiration &&
      // this.formData.cvv;

      return noErrors && allFieldsFilled;
    },

    fullPhoneNumber() {
      return this.formData.areaCode && this.formData.phoneNumber
        ? `${this.formData.areaCode}${this.formData.phoneNumber}`
        : '';
    },
    filteredRelationships() {
      console.log("🚀 ~ filteredRelationships ~  this.relationships:", this.relationships)
      return this.relationships.filter(item =>
        item.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    }
  },
  methods: {


    openSelectMenu(event) {
      const rect = this.$refs.selectRelationship.getBoundingClientRect();
      this.menuWidth = rect.width
      this.menuPosition = {
        x: rect.left + rect.width,
        y: rect.bottom + window.scrollY
      };
      EventBus.$emit('toggleMenu', event, 'selectRelationship');
    },
    selectOption(option) {
      this.selectedOption = option;
      EventBus.$emit('toggleMenu', null, 'selectRelationship'); // Close menu after selection
      this.searchTerm = ''
    },

    validateField(field) {
      this.touched[field] = true;
      this.errors[field] = '';



      switch (field) {
        case 'firstName':
        case 'lastName':
          if (!this.formData[field]) {
            this.errors[field] = `${field === 'firstName' ? 'First' : 'Last'} name is required`;
          } else if (!/^[A-Za-z\s]*$/.test(this.formData[field])) {
            this.errors[field] = 'Name can only contain letters and spaces';
          }
          break;

        case 'phone':
          if (this.touched.phone) {
            if (!this.formData.areaCode || !this.formData.phoneNumber) {
              this.errors.phone = 'Complete phone number is required';
            } else if (!/^\d{7}$/.test(this.formData.phoneNumber)) {
              this.errors.phone = 'Phone number must be 7 digits after area code';
            }
          }
          break;

        case 'creditCard':
          if (this.touched.creditCard) {
            const numberOnly = this.formData.creditCard.replace(/\D/g, '');
            if (!this.formData.creditCard) {
              this.errors.creditCard = 'Credit card number is required';
            } else if (numberOnly.length !== 16) {
              this.errors.creditCard = 'Credit card must be 16 digits';
            }
          }
          break;

        case 'expiration':
          if (!this.formData.expiration) {
            this.errors.expiration = 'Expiration date is required';
          } else if (!/^(0[1-9]|1[0-2])\/\d{2}$/.test(this.formData.expiration)) {
            this.errors.expiration = 'Expiration date must be in MM/YY format';
          } else {
            const [month, year] = this.formData.expiration.split('/');
            const expirationDate = new Date(`20${year}`, month - 1); // Month is zero-based in JS Date
            const currentDate = new Date();
            currentDate.setDate(1); // Set to the first day of the month to check only month and year

            if (expirationDate < currentDate) {
              this.errors.expiration = 'Expiration date has already passed';
            }
          }
          break;

        case 'cvv':
          if (this.touched.cvv) {
            if (!this.formData.cvv) {
              this.errors.cvv = 'CVV is required';
            } else if (!/^\d{3}$/.test(this.formData.cvv)) {
              this.errors.cvv = 'CVV must be 3 digits';
            }
          }
          break;
      }
    },


    formatCreditCard(e) {
      let value = e.target.value.replace(/\D/g, '');
      if (value.length > 16) value = value.slice(0, 16);
      value = value.replace(/(\d{4})/g, '$1 ').trim();
      this.formData.creditCard = value;
    },

    formatExpiration(e) {
      let value = e.target.value.replace(/\D/g, '');
      if (value.length > 4) value = value.slice(0, 4);
      if (value.length > 2) {
        value = value.slice(0, 2) + '/' + value.slice(2);
      }
      this.formData.expiration = value;
      this.validateField('expiration');
    },

    validateForm() {
      Object.keys(this.touched).forEach(field => {
        this.touched[field] = true;
        this.validateField(field);
      });
      return !Object.values(this.errors).some(error => error);
    },

    handleSubmit() {
      if (this.validateForm()) {
        console.log('Form submitted:', {
          firstName: this.formData.firstName,
          lastName: this.formData.lastName,
          phone: this.fullPhoneNumber,
          creditCard: this.formData.creditCard,
          expiration: this.formData.expiration,
          cvv: this.formData.cvv
        });
      }
    }
  }
};
</script>

<style scoped>
.tool-tip {
  color: black;
  background-color: #E3F3FF;
  padding: 10px;

  color: var(--Main-Blue, #0E1F81);
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Assistant;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  border-radius: 4px;

  img {
    margin-right: 5px;
  }
}

.title {
  color: #000;
  font-family: Assistant;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;
}

.selected-value {
  font-weight: 600;
}


.search-box-container {
  padding-top: 10px;
  padding-left: 20px;
  background: white;
  padding-bottom: 1px;
  padding-right: 20px;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  filter: drop-shadow(0px 9px 5px rgba(0, 0, 0, 0.05));

  .search-box {
    display: flex;
    align-items: center;
    cursor: text;
    padding-bottom: 17px;
    padding-top: 17px;

    img {
      width: 23px;
      height: 23px;
    }

    input {
      border: none;
      font-size: 16px;
      width: 100%;
      margin-left: 10px;
    }
  }
}


.menu-content {
  max-height: 220px;
  overflow-y: auto;
  padding-left: 0;
  padding-right: 0;
}



::v-deep .menu-content {
  padding: 0px 0px 0px 0px !important;
}

.menu-item {
  cursor: pointer;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 15px;

  &:hover {
    border-bottom: 1px solid #EEE;
    border-top: 1px solid #EEE;
    background: #F1F6F9;
  }
}

.form-container {
  max-width: 600px;
  /* margin: 0 auto; */
  padding-top: 20px;
}

.custom-select {
  color: black;
  display: flex;
  align-items: center;
  padding: 12px;
  border: 2px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;
  background-color: white;
  cursor: pointer;
  position: relative;
}

.area-code {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: none !important;


}

.disabled {
  background-color: #cccccc61;
}

.seperator-container {
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
  padding: 5px 0px 5px 0px;
}

.seperator {
  border: 1px solid #ddd;
  width: 1px;
  height: 100%;

}

.phone-number {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: none !important;

}

.payment-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.name-row {
  display: flex;
  gap: 20px;
  width: 100%;
}

.name-field {
  flex: 1;
}

.phone-input-container {
  display: flex;
  /* gap: 10px; */
}

.area-code-select {
  width: 120px;
  padding: 12px;
  border: 2px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;
  background-color: white;
}

.phone-number-input {
  flex: 1;
}

.card-details {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

label {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.form-input {
  padding: 12px;
  border: 2px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;
  width: 100%;
  box-sizing: border-box;
}

.form-input:focus,
.area-code-select:focus,
.seperator-container:focus {
  outline: none;
  border-color: #2196f3;

}

.phone-input-container:focus-within .area-code-select,
.phone-input-container:focus-within .phone-number-input,
.phone-input-container:focus-within .seperator-container,
.phone-input-container:focus-within .seperator {
  border-color: #2196f3;
  /* background-color: #2196f3; */
}


.form-input.has-error,
.area-code-select.has-error,
.seperator-container.has-error {
  border-color: #ff5252;
}

.seperator.has-error {
  border-color: #ff5252;
}

.error-message {
  color: #ff5252;
  font-size: 12px;
  /* min-height: 16px; */
}

.submit-button {
  padding: 12px 24px;
  background-color: #2196f3;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #1976d2;
}

.submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
</style>

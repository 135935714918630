<template>
    <div class="search-dropdown" :class="{[mainClass]: true}">
      <!-- If there are many possible values, show a search box -->
      <div v-if="possibleValues.length > 5" class="search-box-container">
        <div class="round-box search-box">
          <img src="../assets/mygmt_v2_images/Search.svg" alt="search" />
          <input
            v-model="localSearchTerm"
            :placeholder="placeholder"
            type="text"
          />
          <!-- Clear search -->
          <img
            v-if="localSearchTerm"
            @click.stop="clearSearch"
            class="pointer"
            style="height: 11px; width: 11px;"
            src="../assets/mygmt_v2_images/close-black.svg"
            alt="clear"
          />
        </div>
      </div>
  
      <!-- Filtered list of items. We use a named slot "menu-item" to let callers override the markup. -->
      <div v-if="possibleValues.length" class="menu-content">
        <template v-for="(item, index) in filteredValues" >
          <slot name="menu-item" :item="item" :selectOption="handleSelectOption">
            <!-- Fallback content if no slot is provided: -->
            <div
              @click="handleSelectOption(item)"
              class="menu-item black"
            >
              {{ item.text }}
            </div>
          </slot>
        </template>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "SearchDropdown",
    props: {
      // Array of items, each with at least { text: string, ... }
      possibleValues: {
        type: Array,
        default: () => []
      },
      // Placeholder text for the search input
      placeholder: {
        type: String,
        default: "Search..."
      },
      mainClass:{
        type: String,
        default: 'style1'
      }
    },
    data() {
      return {
        // Local search term
        localSearchTerm: ""
      };
    },
    computed: {
      getLang() {
      return this.$store.getters.getLang;
    },
      filteredValues() {
        console.log("🚀 ~ filteredValues ~ this.localSearchTerm:", this.localSearchTerm)
        if (!this.localSearchTerm) {
          return this.possibleValues;
        }
        const term = this.localSearchTerm.toLowerCase();
        return this.possibleValues.filter((item) =>
          item.code.toLowerCase().includes(term) || item[this.getLang].toLowerCase().includes(term)
        );
      }
    },
    methods: {
      /**
       * User clicked on an item; emit an event to the parent.
       */
      handleSelectOption(item) {
        this.$emit("select-option", item);
      },
      /**
       * Clear the search term.
       */
      clearSearch() {
        this.localSearchTerm = "";
      }
    }
  };
  </script>
  
  <style scoped>

.menu-content {
  max-height: 220px;
  overflow-y: auto;
  padding-left: 0;
  padding-right: 0;
}

 .style1 {
  .search-box-container {
  padding-top: 10px;
  padding-left: 20px;
  background: white;
  padding-bottom: 1px;
  padding-right: 20px;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  filter: drop-shadow(0px 9px 5px rgba(0, 0, 0, 0.05));

  .search-box {
    display: flex;
    align-items: center;
    cursor: text;
    padding-bottom: 17px;
    padding-top: 17px;

    img {
      width: 23px;
      height: 23px;
    }

    input {
      border: none;
      font-size: 16px;
      width: 100%;
      margin-left: 10px;

    }
  }

}
 }

 .style2 {
  .search-box-container {
  padding-top: 10px;
  padding-left: 20px;
  background: white;
  /* padding-bottom: 1px; */
  padding-right: 20px;
  /* border-bottom-left-radius: 40px; */
  /* border-bottom-right-radius: 40px; */
  /* filter: drop-shadow(0px 9px 5px rgba(0, 0, 0, 0.05)); */

  .search-box {
    display: flex;
    align-items: center;
    cursor: text;
    padding-bottom: 17px;
    padding-top: 17px;

    img {
      width: 23px;
      height: 23px;
    }

    input {
      border: none;
      font-size: 16px;
      width: 100%;
      margin-left: 10px;

    }
  }
 

}
 }


  </style>
  
<template>
  <div class="transaction-details-drawer">
    <div v-if="isMaxTransaction">
      <div style="font-size: 19px;">
        {{ merchantName(transaction) }}
      </div>
      <div class="line">

        <h3 class="full-width" v-if="transaction.TotalAmount < 0 || transaction.ChargeAmount < 0">
          {{ $ml.get('crediting') }}</h3>
        <h3 class="full-width" v-else-if="transaction.TransStatus == 1">{{ $ml.get('inProcess') }}</h3>
      </div>
      <div>
        <h3 v-if="transaction.TotalAmount">{{
      getCurrencySymbol(transaction.Currency == '004' ? 'EUR' : transaction.Currency == '003' ? 'USD' :
        'ILS')
    }}{{ Math.abs(transaction.TotalAmount) | formatDecimal }}</h3>
        <h3 v-else>
          {{ getCurrencySymbol(transaction.OrigCurrency) }}{{ Math.abs(transaction.OrigAmount) | formatDecimal
          }}</h3>
      </div>
      <div class="line">
        <span>{{ $ml.get('sentOn') }}: </span>
        <span v-if="transaction.DateTimeBuy && transaction.DateTimeBuy.length > 0">{{
      format_date(transaction.DateTimeBuy) }}</span>
        <span v-else-if="transaction.ApproveDateTime && transaction.ApproveDateTime.length > 0">{{
      format_date(transaction.ApproveDateTime) }}</span>
        <span v-else style="opacity:0;">date</span>
      </div>
      <div class="line">
        <span>{{ $ml.with('VueJS').get('transactionAmount') }}:</span>
        <span>{{ getCurrencySymbol(transaction.OrigCurrency) }}{{
      transaction.OrigAmount | formatDecimal
    }}</span>
      </div>
      <div class="line" v-show="transaction.ExchangeRate > 0">
        <span>{{ $ml.with('VueJS').get('exchangeRate') }}: {{ transaction.ExchangeRate }}</span>
      </div>
      <div class="line" v-show="transaction.ExchangeRate > 0">
        <span>{{ $ml.with('VueJS').get('cardPresented') }}:</span>
        <span>{{ $ml.with('VueJS').get(transaction.TranModeCode != 1 ? 'no' : 'yes') }}</span>
      </div>
      <div class="line" v-show="transaction.MerchantAddress">
        <span>{{ $ml.with('VueJS').get('cardMerchantAddress') }}:</span>
        <span>{{ transaction.MerchantAddress }}, {{ transaction.City }}</span>
      </div>
    </div>
    <div v-else="transaction">
      <div style="font-size: 19px;">{{ (transaction.otherSide || $ml.get(transaction.descCode) ||
      transaction.settings) | lowerCaseCapitalise }}</div>

      <div class="line currency-line">
        <h3 class="full-width">
          {{ transaction.amount | formatDecimal }} {{ transaction.currency }}
        </h3>
      </div>
      <div class="lines-container">
        <div class="line" v-if="isTransactionClassBy('CASH_TRANSFER')">
          <span v-text="$ml.with('VueJS').get('system')"></span>
          {{ transaction.correspondent }}
        </div>
        <div class="line">
          <span>
            {{ $ml.get('numberOfTransaction') }}
          </span>
          <span>
            {{ transaction.gmtId }}
          </span>
        </div>
        <div class="line">
          <span>{{ $ml.get('sentOn') }} </span>
          <span>{{ moment(transaction.date, 'DD/MM/YYYY HH:mm').format('HH:mm DD/MM/YYYY') }}</span>
        </div>
        <div class="line" v-if="transaction.destination">
          <span>{{ $ml.get('destination') }} </span>
          <span>{{ getCountryNameByIso(transaction.countryIso) }}</span>
        </div>
        <div class="line" v-if="transaction.country && isTransactionClassBy('PAYMENT')">
          <span>{{ $ml.get('country') }} </span>
          <span>{{ getCountryNameByIso(transaction.countryIso) }}</span>
        </div>
        <div v-if="transaction.otherSide" class="line">
          <span>{{ $ml.get('transferType') }} </span>
          <span>{{ $ml.get('transactionClasses.' +transaction.transactionClass)}}
          </span>
          <!--  the if condition here is not meant to support transactionClass-less transactions but to prevent translations before transaction loaded (which prints error in console and i hate errors) -->
        </div>
        <div>
        </div>
        <div class="line" v-if="isBankAndNotEmpty('beneficiaryBankName')">
          <span v-text="$ml.with('VueJS').get('bank')"></span>
          {{ transaction.beneficiaryBankName }}
        </div>
        <div class="line" v-if="isUpiAdNotEmpty('upiIdentifier')">
          <span v-text="$ml.with('VueJS').get('beneficiery.upi')"></span>
          {{ transaction.upiIdentifier }}
        </div>
        <div class="line" v-if="isBankAndNotEmpty('beneficiaryBankBranch')">
          <span v-text="$ml.with('VueJS').get('branchNumber')"></span>
          {{ transaction.beneficiaryBankBranch }}
        </div>
        <div class="line" v-if="isBankAndNotEmpty('beneficiaryBankAccount')">
          <span v-text="$ml.with('VueJS').get('bankAccount')"></span>
          {{ transaction.beneficiaryBankAccount }}

        </div>

        <!-- NEW -->
        <div class="line" v-if="isBankAndNotEmpty('beneficiaryBankIban')">
          <span v-text="$ml.with('VueJS').get('beneficiary.iban')"></span>
          {{ transaction.beneficiaryBankIban }}
        </div>
        <div class="line" v-if="isCashAndNotEmpty('beneficiaryCashBank')">
          <span v-text="$ml.with('VueJS').get('beneficiary.cashBankName')"></span>
          {{ transaction.beneficiaryCashBank }}
        </div>
        <div class="line" v-if="isWalletAndNotEmpty('beneficiaryWalletBank')">
          <span v-text="$ml.with('VueJS').get('beneficiary.walletBankName')"></span>
          {{ transaction.beneficiaryWalletBank }}
        </div>
        <div class="line" v-if="isWalletAndNotEmpty('beneficiaryWalletIdentifier')">
          <span v-text="$ml.with('VueJS').get('beneficiary.walletIdentifier')"></span>
          {{ transaction.beneficiaryWalletIdentifier }}
        </div>





        <div style="margin-top: 5px;margin-bottom: 10px;" class="line"
          v-if="transaction.canCheckStatus && transaction.transactionStatus != 'CANCELED'">
          <span class="checkStatusText">{{ $ml.get('status') }} </span>
          <div>
            <div v-show="checkingStatus" class="mygmt2-loader">
              <img src="../assets/mygmt_v2_images/mygmt2-loader.gif" alt="loading" />
            </div>
            <span id="checkStatusText" v-show="!checkingStatus" />
          </div>
        </div> 
         <div style="margin-top: 5px;margin-bottom: 10px;" class="line"
          v-if="transaction.transactionStatus === 'CANCELED'">
          <span class="checkStatusText">{{ $ml.get('status') }} </span>
          <div>
            <span  class="canceled" > {{ $ml.get('canceled') }} </span>
          </div>
        </div>
      </div>
      <hr />

      <div v-if="!isTransactionClassBy('CONVERSION')" class="payments-container">
        <div v-if="transaction.customerCommission > 0">
          <div class="line">
            <span>{{ $ml.with('VueJS').get('transactionAmount') }}:</span>
            <span>
              {{ transaction.amount | formatDecimal }} {{ transaction.currency }}</span>
          </div>
          <div class="line">
            <span>{{ $ml.get('customerCommission') }}:</span>
            <span> {{ transaction.customerCommission | formatDecimal }} {{ transaction.currency
              }}</span>
          </div>
        </div>
        <div v-if="transaction.receiveAmount && transaction.currency != transaction.payoutCurrency" class="line">
          <span>{{ $ml.get('amountReceived') }}</span>
          <span>{{ transaction.receiveAmount | formatComma }} {{ transaction.payoutCurrency }}</span>
        </div>
        <div class="line">
          <span style="font-weight: 600;font-size: 15px;"> {{ $ml.get('STATUS_PAID') }} </span>
          <span style="font-weight: 600;font-size: 15px;">{{ transaction.totalSum | formatDecimal }}
            {{ transaction.currency }}</span>
        </div>
      </div>
      <div v-if="isTransactionClassBy('CONVERSION')">
        <div class="payments-container">
          <h4>{{ $ml.get('outgoing') }}</h4>
          <div v-if="getPaymentDetailsBy('IN').length">
            <div v-for="(payment, index) in getPaymentDetailsBy('IN')" :key="payment.id">
              <div class="line">
                
                <span> {{ $ml.get(`paymentTypes.${payment.paymentType.name}`) | lowerCaseCapitalise }}</span>
                <span>{{ payment.paidSum | formatDecimal }} {{ payment.paidCurrency }}</span>
              </div>
              <div v-if="payment.cashBankName" class="line">
                <span>{{ $ml.get(payment.cashBankName) || payment.cashBankName | lowerCaseCapitalise }} </span>
                <span>{{ payment.cashBankName }}</span>
              </div>

              <div class="extra-info">
                <div v-if="payment.bankCode && payment.paymentType.name !== 'CHECK'" class="line">
                  <span>{{ $ml.get('bankCode') }}</span>
                  <span> {{ payment.bankCode }}</span>
                </div>
                <div v-if="payment.branchCode && payment.paymentType.name !== 'CHECK'" class="line">
                  <span>{{ $ml.get('beneficiary.branch') }}</span>
                  <span> {{ payment.branchCode }}</span>
                </div>
                <div v-if="payment.accountNumber && payment.paymentType.name !== 'CHECK'" class="line">
                  <span>{{ $ml.get('bankAccount') }}</span>
                  <span> {{ payment.accountNumber }}</span>
                </div>
                <div v-if="payment.checkNumber" class="line">
                  <span>{{ $ml.get('checkNumber') }}</span>
                  <span> {{ payment.checkNumber }}</span>
                </div>
                <div v-if="payment.referenceNumber" class="line">
                  <span>{{ $ml.get('referenceNumber') }}</span>
                  <span> {{ payment.referenceNumber }}</span>
                </div>

                <hr :class="{ 'd-none': index === getPaymentDetailsBy('IN').length - 1 }" />

              </div>
            </div>
          </div>
        </div>
        <div class="payments-container">
          <h4>{{ $ml.get('incoming') }}</h4>
          <div v-if="getPaymentDetailsBy('OUT').length">
            <div v-for="(payment, index) in getPaymentDetailsBy('OUT')" :key="payment.id">
              <div class="line">
                <span> {{ $ml.get(`paymentTypes.${payment.paymentType.name}`) | lowerCaseCapitalise }}
                </span>

                <span>{{ payment.paidSum | formatDecimal }} {{ payment.paidCurrency }}</span>

              </div>

              <div class="extra-info">
                <div v-if="payment.bankCode" class="line">
                  <span> {{ $ml.get('bankCode') }}</span>
                  <span> {{ payment.bankCode }}</span>
                </div>
                <div v-if="payment.branchCode" class="line">
                  <span>{{ $ml.get('beneficiary.branch') }}</span>
                  <span> {{ payment.branchCode }}</span>
                </div>
                <div v-if="payment.accountNumber" class="line">
                  <span>{{ $ml.get('bankAccount') }}</span>
                  <span> {{ payment.accountNumber }}</span>
                </div>
                <div v-if="payment.flightDate" class="line">
                  <span> {{ $ml.get('flightDate') }} </span>
                  <span> {{ moment(payment.flightDate).format('DD/MM/YYYY') }}</span>
                </div>


                <hr :class="{ 'd-none': index === getPaymentDetailsBy('OUT').length - 1 }" />

              </div>
            </div>
          </div>
        </div>

      </div>
      <div v-else-if="getPaymentDetailsBy('ALL').length" class="payment-details payments-container">
        <div>
          <span class="line">{{ $ml.get('paidBy') }}</span>
          <div v-for="(payment, index) in getPaymentDetailsBy('ALL')" :key="payment.id">
            <div class="line">
              <span> {{ $ml.get(`paymentTypes.${payment.paymentType.name}`) | lowerCaseCapitalise }}</span>
              <span>{{ payment.paidSum | formatDecimal }} {{ payment.paidCurrency }}</span>
            </div>
            <div class="extra-info">
              <div v-if="payment.bankCode && payment.paymentType.name !== 'CHECK'" class="line">
                <span>{{ $ml.get('bankCode') }}</span>
                <span> {{ payment.bankCode }}</span>
              </div>
              <div v-if="payment.branchCode && payment.paymentType.name !== 'CHECK'" class="line">
                <span>{{ $ml.get('beneficiary.branch') }}</span>

                <span> {{ payment.branchCode }}</span>
              </div>
              <div v-if="payment.accountNumber && payment.paymentType.name !== 'CHECK'" class="line">
                <span>{{ $ml.get('bankAccount') }}</span>
                <span> {{ payment.accountNumber }}</span>
              </div>
              <div v-if="payment.checkNumber" class="line">
                <span>{{ $ml.get('checkNumber') }}</span>
                <span> {{ payment.checkNumber }}</span>
              </div>
              <div v-if="payment.referenceNumber" class="line">
                <span>{{ $ml.get('referenceNumber') }}</span>
                <span> {{ payment.referenceNumber }}</span>
              </div>


              <hr :class="{ 'd-none': index === transaction.paymentDetails.length - 1 }" />

            </div>

          </div>
        </div>
      </div>

    </div>
    <div class="buttons-container">
      <button v-if="transaction.canClone" class="btn btn-primary" :class="{ 'app-btns': isApp }" @click="sendAgain()">{{
      $ml.get('clone') }}
      </button>
      <button class="btn btn-border" :class="{ 'app-btns': isApp }" v-if="false" @click="shareTransaction()">
        {{ $ml.get('wallet.invite.shareActions.general') }}
      </button>
    </div>
  </div>
</template>
<script>

import { getCountryNameByIso, getCurrencySymbol, isApp } from "../services/utils";
import { getBeneficiary } from "../services/api";

import moment from "moment";
import { checkStatus } from "@/services/api";

export default {
  name: 'TransactionInfo',
  components: {},
  props: {
    isMaxTransaction: {
      type: Boolean,
      required: false
    },
    transaction: {
      type: Object,
    },
  },
  data() {
    return {
      isApp: isApp(),
      checkingStatus: false,
    }
  },
  created() {
  },
  computed: {
    moment() {
      return moment
    },
    getLang() {
      return this.$store.getters.getLang;
    },
  },
  mounted() {

  },
  methods: {

    placeLast(sortBy, sortArr) {
      return sortArr.reduce((acc, item) => {
        if (item.paymentType.name === sortBy) {
          acc[1].push(item);
        } else {
          acc[0].push(item);
        }
        return acc;
      }, [[], []])
        .flat();
    },

    getPaymentDetailsBy(direction) {
      const sorted = this.placeLast('CHANGE', this.transaction.paymentDetails)
      if (direction === 'ALL') {
        return sorted
      }
      return sorted.filter((el) => { return el.direction.name === direction })

    },

    getCountryNameByIso,
    getCurrencySymbol,
    checkStatus(transactionId) {
      this.checkingStatus = true;
      checkStatus({ "transactionId": transactionId }, response => {
        this.checkingStatus = false;
        let checkStatusEl = $("#checkStatusText");
        checkStatusEl.text(this.$ml.get(response ? response : 'STATUS_NOT_FOUND'))
        if (this.completedStatuses.indexOf(response) > -1) {
          checkStatusEl.css({ 'backgroundColor': '#E3FFE6' })
          checkStatusEl.css({ 'color': '#06A947' })
        } else if (this.failedStatuses.indexOf(response) > -1) {
          checkStatusEl.css({ 'backgroundColor': '#FFD8D8' })
          checkStatusEl.css({ 'color': '#FF0B0B' })
        } else {
          checkStatusEl.css({ 'backgroundColor': '#FFF0CB' })
          checkStatusEl.css({ 'color': '#BF7E1D' })
        }
        checkStatusEl.css('display', 'block')
      });
    },

    isTransactionClassBy(transactionClass) {
      return this.transaction.transactionClass === transactionClass
    },
    isBankAndNotEmpty(key) {
      if (this.transaction.transactionClass !== 'BANK_TRANSFER') return false
      return this.transaction[key]
    }, 
    isUpiAdNotEmpty(key) {
      if (this.transaction.transactionClass !== 'UPI_BANK_TRANSFER') return false
      return this.transaction[key]
    },
    isWalletAndNotEmpty(key) {
      return this.transaction.transactionClass === 'WALLET_TRANSFER' && this.transaction[key]
    },
    isCashAndNotEmpty(key) {
      if (this.transaction.transactionClass !== 'CASH_TRANSFER') return false
      return this.transaction[key]
    },

   async sendAgain() {
      this.$store.commit('setTransferStep1', {
        amount: this.transaction.amount,
        currency: this.transaction.currency,
        destinationCountry: this.transaction.countryIso,
        cloneInfo: {
          transactionClass: this.transaction.transactionClass,
          correspondentName: this.transaction.correspondent
        }
      
      });
      this.$store.commit('setTransferDestinationCountry', this.transaction.countryIso);
      this.$store.commit('setCurrentBeneficiary', this.transaction.beneficiaryId);
      const beneficiary = await getBeneficiary(this.transaction.beneficiaryId)
      if (beneficiary) {
        this.$store.commit('setCurrentBeneficiaryDetails', beneficiary); // after reload is undefined
        if (this.transaction.transactionClass === 'WALLET_TO_WALLET') {
        this.$router.push("/wallet/transfer/friend");
      } else if (this.transaction.transactionClass === 'WALLET_DEPOSIT') {
        this.$router.push("/wallet/transfer");
      } else {
        this.$router.push("transfernew");
      }
      } else {
        console.error('No beneficiary found')
      }
     
    },
    shareTransaction() {
      gonative.share.downloadFile({
        url: "https://images.uncyc.org/en/thumb/8/8b/Stupid_cat_3.jpg/350px-Stupid_cat_3.jpg"
        , open: true
      });
    },
    merchantName(action) {
      return (this.$store.state.locale.lang != 'il' && action.MerchantNameEng) ? action.MerchantNameEng : action.MerchantName
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY')
      }
    }
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/partials/variables";

.line {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  width: 100%;
  padding: 5px 0;

  #checkStatusText {
    padding: 8px 12px;
    border-radius: 27px;
  }
}

.extra-info {
  margin-top: -8px;
  margin-bottom: 5px;

  .line {
    margin: -7px 0;
  }




}


.canceled {
  background-color: #FFD8D8;
  color: #FF0B0B;
  padding: 5px;
  border-radius: 27px; 
}
.transaction-details-drawer {
  color: black;
  text-align: center;
  width: 100%;
  position: relative;
  bottom: 15px;

  h3 {
    font-size: 16px;
    //font-weight: normal;
  }

  h2 {
    font-weight: bold;
  }

  .currency-line {
    padding: 2px 0 20px 0;

    h3 {
      font-size: 18px;
    }
  }

  .buttons-container {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;

    .btn {
      width: 100%;
      height: 45px;
    }

    .app-btns {
      width: 48%;
    }

    .btn-border {
      color: #092086;
      border: 1px solid #092086;
    }
  }

  .lines-container {
    display: flex;
    flex-direction: column;
    padding-top: 10px;

  }

  .payments-container {
    background: #F8F8F8;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 13px;

    h4 {
      text-align: start;
    }

    .line {
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      width: 100%;
      padding: 5px 0;
    }

  }
}

@media only screen and (min-width: $screen-tablet) {

  .no-transactions-container {
    height: 90%;
  }

  .transaction-list-card-container {
    height: 100%;
    margin-top: 0;
    padding: 15px;
  }
}
</style>

<template>
  <div class="container">
    <div class="main-title">Transfer Summary</div>
    <div v-if="beneficiary" class="main-summary">
      <!-- {{ beneficiary }} -->
      <!-- {{ selectedTransferOption }} -->
      <div class="line">
        <div class="title"> Beneficiary Name</div>
        <div class="value"> {{ beneficiary.firstName | capitalize}} {{ beneficiary.lastName | capitalize}}</div>
      </div>
      <div class="line">
        <div class="title">Destination</div>
        <div class="value"> <img :src="imgSrc(selectedTransferOption.destinationCountry)" alt="" srcset=""> <span>{{ destinationCountry}}</span> </div>
      </div>
      <div class="line">
        <div class="title">Transfer Method</div><div class="value"> {{ $ml.get('transactionClasses.' + selectedTransferOption.transactionClass)  }}</div>
      </div>
      <div v-if="beneficiary && beneficiary.cashBankName && cashBank" class="line">
        <div class="title">Pickup info</div>
        <div class="value">{{ beneficiary.cashBankName }}</div>
      </div>
      <div v-if="isTransactionClassBy('CASH_TRANSFER') || ((isTransactionClassBy('WALLET_TRANSFER') || isTransactionClassBy('CARD_DEPOSIT')) && !beneficiary.walletBankName )  " class="line">
        <div class="title">Corrspondent</div>
        <div class="value">{{ selectedTransferOption.correspondentName }}</div>
      </div>
      <div v-if="isTransactionClassBy('WALLET_TRANSFER') && beneficiary.walletBankName" class="line">
        <div class="title">Wallet</div>
        <div class="value">{{ beneficiary.walletBankName }}</div>
      </div>
       <div v-if="cardDeposit && beneficiary.maskedCardNumber" class="line">
        <div class="title">{{ $ml.with('VueJS').get('toCreditCard') }}</div>
        <div class="value"> {{ beneficiary.maskedCardNumber | formatCardNumber }}</div>
      </div>
      <div v-if="bankTransfer && (beneficiary.bankName)" class="line">
        <div class="title"> {{ $ml.with('VueJS').get('bank') }} </div>
        <div class="value">{{ beneficiary.bankName }}</div>
      </div>
       <div v-if="bankTransfer && (beneficiary.branch)" class="line">
        <div class="title"> Branch </div>
        <div class="value">{{ beneficiary.branch }}</div>
      </div>
      <div v-if="bankTransfer && (beneficiary.accountIdentifier || beneficiary.iban)" class="line">
        <div class="title"> {{ $ml.with('VueJS').get('toBankAccount') }} </div>
        <div class="value">{{ beneficiary.accountIdentifier ? beneficiary.accountIdentifier : beneficiary.iban }}</div>
      </div>
      <div class="line" v-if="(walletTransfer && beneficiary.walletIdentifier) && !isWalletPhone">
        <div class="title">{{ $ml.with('VueJS').get('toWalletAccount') }} </div>
        <div class="value">{{ beneficiary.walletIdentifier }}</div>
      </div>
      <div class="line" v-if="isWalletPhone && beneficiary.phoneNumber">
        <div class="title">{{ $ml.with('VueJS').get('beneficiary.phoneNumber') }} </div>
        <div class="value"> {{ beneficiary.phoneNumber }}</div>
      </div> 
      <div class="line" v-if="isUpi">
        <div class="title">{{ $ml.get('beneficiary.upi')}} </div>
        <div class="value"> {{  beneficiary.upiIdentifier }}</div>
      </div>
        <!-- <div class="line" v-if="isBankAndNotEmpty('beneficiaryBankName')">
          <span v-text="$ml.with('VueJS').get('bank')"></span>
          {{ transaction.beneficiaryBankName }}
        </div>
        <div class="line" v-if="isUpiAndNotEmpty('upiIdentifier')">
          <span v-text="$ml.with('VueJS').get('beneficiery.upi')"></span>
          {{ transaction.upiIdentifier }}
        </div>
        <div class="line" v-if="isBankAndNotEmpty('beneficiaryBankBranch')">
          <span v-text="$ml.with('VueJS').get('branchNumber')"></span>
          {{ transaction.beneficiaryBankBranch }}
        </div>
        <div class="line" v-if="isBankAndNotEmpty('beneficiaryBankAccount')">
          <span v-text="$ml.with('VueJS').get('bankAccount')"></span>
          {{ transaction.beneficiaryBankAccount }}
        </div> -->
      <!-- <div class="line">
        <div class="title">Aviable in</div><div class="value"></div>
      </div> -->
      <div class="seperator"></div>
      <div class="line">
        <div class="title">Amount</div><div class="value">{{ this.selectedTransferOption.amount }} {{ selectedTransferOption.currency  }}</div>
      </div>
      <div class="line">
        <div class="title">Commision</div><div class="value">{{ selectedTransferOption.commission }} {{ selectedTransferOption.currency }}</div>
      </div>
      <div v-if="(selectedTransferOption.conversionRate || selectedTransferOption.estimatedExchangeRate) && selectedTransferOption.currency !== selectedTransferOption.estimatedReceiveCurrency"  class="line">
        <div class="title">Destination exchange rate</div><div class="value"> {{ selectedTransferOption.conversionRate || selectedTransferOption.estimatedExchangeRate }} {{ selectedTransferOption.payoutCurrency}} </div>
      </div>
      <div class="line">
        <div class="title">They recieve</div><div class="value">{{ selectedTransferOption.totalReceiveAmount || 'will be calculated abroad' }} {{ selectedTransferOption.totalReceiveAmount ?  selectedTransferOption.payoutCurrency : '' }}</div>
      </div>
      <div class="seperator"></div>
      <div  v-if="amountToPay !== calculatedTotal " class="line">
        <div class="title ">Total Payment</div><div class="value">{{ calculatedTotal.toFixed(2) }}  {{ selectedTransferOption.currency }}</div>
      </div>
      <div v-if="usedCashBack && cashBackCurrency === selectedTransferOption.currency" class="line">
        <div class="title">Cashback discount</div><div class="value" style="color: red;"> -{{ cashBackCredit }} {{ cashBackCurrency }}</div>
      </div>
      <div v-if="rate > 1" class="line">
        <div class="title ">Exchange rate</div><div class="value">{{ rate }} ILS</div>
      </div>
      <div v-if="usedCashBack && cashBackCurrency !== selectedTransferOption.currency" class="line">
        <div class="title">Cashback discount</div><div class="value" style="color: red;"> -{{ cashBackCredit }} {{ cashBackCurrency }}</div>
      </div>
      <div v-if="amountToPay"  class="line">
        <div class="title bold">I pay</div><div class="value">{{ amountToPay }} ILS </div>
      </div>
<!--       
      <div v-if="amountToPay !== calculatedTotal" class="line">
        <div class="title bold">Total Payment</div><div class="value">{{ amountToPay }} ILS </div>
      </div> -->
    </div>



    <!-- For the future when coupons will be active -->
    <!-- <div v-if="selectedCoupon" class="coupon">
      <div class="flex"> <img src="../../../../assets/mygmt_v2_images/Coupon.svg" alt="" srcset=""> <span>Coupon <span
            class="coupon-name ">"{{selectedCoupon.name}}"</span> applied!</span> </div> <img class="pointer" @click="removeCoupon"
        src="../../../../assets/mygmt_v2_images/Garbage.svg" alt="">
    </div>
    <div v-else class="coupon pointer" @click="addCoupon()">
      <div class="flex"> <img src="../../../../assets/mygmt_v2_images/Coupon.svg" alt="" srcset=""> <span>I have a coupon</span> </div> <img
        src="../../../../assets/mygmt_v2_images/add.svg" alt="">
    </div> -->
    <div class="flex full-center"> <button @click="$emit('changeStep', 4)" class="btn btn-main next-btn"> Next</button></div>
    <Drawer ref="cashbackDrawer" :title="''" @closed="handleDrawerClose($event)"
      :drawerId="'cashbackDrawer'" class="drawer-wrapper-card flex flex-column justify-between" :class="{'custom-closer': ''}">
      <template v-slot:content>
        <div v-if="getValidCashBackBalances && getValidCashBackBalances[0]" class="flex flex-column align-center h-100 justify-center cashback-drawer-container ">
          <img src="../../../../assets/mygmt_v2_images/Present-Cashback-Sparkling.svg" alt="">
          <h2>Just a reminder...</h2>
          <h2>You have {{ getSymbole(cashBackCurrency) }}{{  cashBackCredit }} cashback!</h2>
          <p>You can either use them now or keep collecting
            for future transactions.</p>
          <div class="btns-container">
            <button class="btn btn-main" @click="cashBackChoice(true);closeDrawerOrMenu()">I want to use it</button>
            <div @click="cashBackChoice(false);closeDrawerOrMenu()">No thanks, I'll keep collecting.</div>
          </div>
        </div>
      </template>
    </Drawer>

    <DesktopMenu width="530px" height="440px" @menuClosedOuter="handleDrawerClose('outer')" backgroundColor="rgba(111, 111, 111, 0.30)" backgroundFilter="blur(7.5px)" :showCloseButton="true"
      position="center" name="useCashback" :isSideMenuVisible="false">
      <div v-if="getValidCashBackBalances && getValidCashBackBalances[0]" class="flex flex-column align-center h-100 justify-center cashback-drawer-container modal-cashback">
          <img src="../../../../assets/mygmt_v2_images/Present-Cashback-Sparkling.svg" alt="">
          <h2>Just a reminder...</h2>
          <h2>You have {{ getSymbole(cashBackCurrency) }}{{  cashBackCredit }} cashback!</h2>
          <p>You can either use them now or keep collecting
            for future transactions.</p>
          <div class="btns-container">
            <button class="btn btn-main" @click="cashBackChoice(true);closeDrawerOrMenu()">I want to use it</button>
            <div @click="cashBackChoice(false);closeDrawerOrMenu()">No thanks, I'll keep collecting.</div>
          </div>
        </div>
    </DesktopMenu>
  </div>
</template>
<script>
import _ from 'lodash';
import { getCountryByIso,getCountryNameByIso ,isApp, isMobile, getCurrencySymbol } from '../../../../services/utils';
import Drawer from '../../../../components/Drawer.vue';
import DesktopMenu from '../../../../components/DesktopMenu.vue';
import { EventBus } from '../../../../event-bus';
import {getCashBackCreditAvailable, paymentAmountInfo} from '../../../../services/api'


export default {
  components: {DesktopMenu, Drawer},
  name: 'Summary',
  props: { previousStep: {
      type: String,
      required: true,
    }},
  created() {
    this.selectedCountry = getCountryByIso(this.$store.state.transfer.destinationCountry)
    this.destinationCountry = getCountryNameByIso(this.selectedTransferOption.destinationCountry)
    this.beneficiary = this.$store.getters.getSelectedBeneficiaryDetails
    console.log('blaa', this.$store.getters.getSelectedTransferOption);
    console.log('blaa2', this.$store.getters.getSelectedBeneficiary);
    console.log('blaa3', this.previousStep);

   
  },
  data() {
    return {
      selectedCoupon: null,
      destinationCountry: '',
      name:'Summary',
      beneficiary: null,
      isMobileDevice: isMobile(),
    };
  },
  computed: {
    selectedTransferOption() {
      return this.$store.getters.getSelectedTransferOption || {}
    },
    calculatedTotal() {
    return this.selectedTransferOption.amount + 
           this.selectedTransferOption.commission ;
    },
    bankTransfer() {
      return this.$store.getters.getSelectedTransferOption && this.$store.getters.getSelectedTransferOption.transactionClass == 'BANK_TRANSFER';
    },
    cashBank() {
      return this.transactionClass == 'CASH_TRANSFER' && this.$store.getters.getSelectedTransferOption.correspondentType == 'TRANGLO';
    },
    isUpi() {
      return this.$store.getters.getSelectedTransferOption && this.$store.getters.getSelectedTransferOption.transactionClass ==  'UPI_BANK_TRANSFER'
    },
    isWalletPhone() {
      return this.$store.getters.getSelectedTransferOption && (this.$store.getters.getSelectedTransferOption.correspondentIdentifier == 'THUNES_WECHATPAY'|| this.$store.getters.getSelectedTransferOption.correspondentIdentifier == 'THUNES');
    },
    walletTransfer() {
      return this.$store.getters.getSelectedTransferOption && this.$store.getters.getSelectedTransferOption.transactionClass == 'WALLET_TRANSFER';
    },
    cardDeposit() {
      return this.$store.getters.getSelectedTransferOption && this.$store.getters.getSelectedTransferOption.transactionClass == 'CARD_DEPOSIT';
    },
    exchangeRate() {
      return this.$store.getters.getSelectedExchangeRate;
    },
    cashBackCredit() {
      return this.$store.getters.getCashBackCredit;
    }, 
    cashBackCurrency() {
      if (this.$store && this.$store.getters) {
        return this.$store.getters.getCashBackCurrency
      
      }
    },
    getCashBackBalances() {
      return this.$store.getters.getCashBackBalances || []
    },
    getValidCashBackBalances() {
      const balances = this.getCashBackBalances
      console.log("🚀 ~ getValidCashBackBalances ~ balances:", balances)
      const filterRes = balances.filter((balance) => balance.amount > 0)
      return filterRes
    },
    usedCashBack() {
      return this.$store.getters.getUseCashBack && this.cashBackCredit;
    },
    amountToPay(){
      return this.$store.getters.getPaymentAmountInfo.amountToPay
    },
    rate(){
      return this.$store.getters.getPaymentAmountInfo.rate
    }

  },
  watch: {
  },
  async mounted() {
    if (this.previousStep === 'BeneficiarySelection') {
      this.getCashbackDetails()
    }
  },
  beforeDestroy() { 
    // this.$store.commit('setPaymentAmountInfo', {})
  },
  methods: {
    addCoupon(){
      this.selectedCoupon = {name: 'GMT2024'}
    },

    getSymbole(currency) {
      return getCurrencySymbol(currency);
    },

    getPaymentAmountInfo(){
      let lookupDto = this.$store.getters.getSelectedTransferOption.lookupDto;
    console.log("🚀 ~ created ~ this.currency:",  this.$store.getters.getSelectedTransferOption.currency)
    console.log("🚀 ~ created ~ this.currency:", this.$store.getters.getSelectedTransferOption.currency)
    console.log("🚀 ~ created ~ this.currency:", this.currency)
    EventBus.$emit('load');
    paymentAmountInfo({
      "amount": this.$store.getters.getSelectedTransferOption.amount,
      "commission": this.$store.getters.getSelectedTransferOption.commission,
      "currency": this.$store.getters.getSelectedTransferOption.currency,
      "useCashBack": this.$store.getters.getUseCashBack,
      "cashBackCurrency": this.$store.getters.getCashBackCurrency,
      "transactionRate": this.$store.getters.getSelectedExchangeRate,
      "lookupAmount": lookupDto && lookupDto.lookupAmount,
      "lookupRate": lookupDto && lookupDto.rate,
      "lookupBaseRate": lookupDto && lookupDto.baseRate
    }, (response) => {
      // this.amountToPay = response.amountILS;
      // this.rate = response.rate;
      EventBus.$emit('unload');
      this.$store.commit('setPaymentAmountInfo', { amountToPay: response.amountILS, rate: response.rate })
    });
    },
    isTransactionClassBy(transactionClass) {
      return this.selectedTransferOption.transactionClass === transactionClass
    },

    imgSrc(countryIso) {
      try {
        return require(`../../../../assets/mygmt_v2_images/countries/${countryIso}.svg`);
        
      } catch (error) {
        
      }

    },
    getCashbackDetails(retry = false){
      const attemptedCurrency = retry ? 'ILS' : this.selectedTransferOption.currency
      console.log("🚀 ~ getCashbackDetails ~ attemptedCurrency:", attemptedCurrency)
      getCashBackCreditAvailable({ amount: this.selectedTransferOption.amount, currency: attemptedCurrency }, (data) => {
              if (data) {
                this.$store.commit('setCashBackCredit', data);
                this.$store.commit('setCashBackCurrency', attemptedCurrency);
                this.isMobileDevice ?  this.$refs.cashbackDrawer.openDrawer() : EventBus.$emit('toggleMenu', null, 'useCashback');
              } else if (!data && attemptedCurrency !=='ILS' && !retry ){
                this.getCashbackDetails(true)
              } else {
                this.$store.commit('setCashBackCredit', 0);
                this.$store.commit('setUseCashBack', false);
                this.getPaymentAmountInfo()
              };
            });
    },
    removeCoupon(){
      this.selectedCoupon = null
    },
    handleDrawerClose(howClosed){
    console.log("🚀 ~ handleDrawerClose ~ howClosed:", howClosed)
    if (howClosed === 'outer') {
      this.cashBackChoice(false)
    }
    },
    cashBackChoice(choice) {
      this.$store.commit('setUseCashBack', choice);
      this.getPaymentAmountInfo()
    },
    closeDrawerOrMenu(){
      this.isMobileDevice ?  this.$refs.cashbackDrawer.closeDrawer() : EventBus.$emit('toggleMenu', null, 'useCashback');
    }
  },

};
</script>

<style lang="scss" scoped>
@import "../../../../assets/styles/partials/variables";

.main-summary {
  border-radius: 8px;
  border: 1px solid #EBEBEB;
  background: var(--Neutral-White, #FFF);
  box-shadow: 0px 4px 24px 0px rgba(177, 177, 177, 0.13);
  padding: 14px;
}

.main-title { 
  color: #000;
  font-family: Assistant;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;
}

.drawer-wrapper-card {
  left: 0px;
  bottom: 0px;
  border-radius: 20px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  width: 100vw;
  padding-bottom: 0px;
  min-height: 30%;
  // position: absolute;
  // inset-block-start: 500px;
}

::v-deep .go-back-wrapper {
    position: absolute;
    top: 3%;
    right: 5%;

  }

  ::v-deep .head-line {
    height: 0;
    padding: 0;
  }



.title {
  display: flex;
  justify-content: space-between;
}

.seperator {
  height: 1px;
  border: 1px #EBEBEB solid;
  margin: 14px 0px;
}

.cashback-drawer-container {
  h2,p {
    color: black;
    text-align: center;
  }
  p {
    margin-top: 20px;
  }

  img {
    width: 100px;
    height: 100px;
  }

}
.modal-cashback {
  // width: 50px;
  padding: 0px 60px;
}

.line {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  color: #000;
font-family: Assistant;
font-size: 16px;
font-style: normal;
font-weight: 400;

.value {
 display: flex;
 justify-content: center;
 align-items: center;
  img{
    margin-right: 5px;
    height: 16px;
    width: 16px;

  }

}
}

.bold {
font-size: 18px;
font-weight: 700;
}

.coupon {
  border-radius: 8px;
  border: 1px solid #EBEBEB;
  background: var(--Neutral-White, #FFF);
  box-shadow: 0px 4px 24px 0px rgba(177, 177, 177, 0.13);
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 14px;
  margin: 24px 0px 40px 0px;

  img:first-child {
    margin-right: 8px;
  }

  .coupon-name {
    color: var(--GMT--Blue, #0E1F81);
    font-family: Assistant;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
  }
}

.next-btn {
  margin-top: 100%;
}

.btns-container { 
  margin-top: 20px;
  margin-bottom: 20px;
  div {
    margin-top: 20px;
    text-align: center;
    cursor: pointer;
  }
}

.btn-main {
  width: 335px;
  height: 45px;
}

@media only screen and (min-width: $screen-tablet) {
  .next-btn {
  margin-top: 100px;
}
  
}
</style>

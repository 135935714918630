<template>
  <div class="mygmt-new-customer-card">
    <span v-if="isMobileDevice">
      <strong>{{ $ml.get('welcome') }}</strong>
      <span>{{ getUserFullName(getLang) ? ", " + getUserFullName(getLang) : '' }}</span>
    </span>
    <h3 :dir="getDir"><strong>{{ $ml.get('newCustomer_title') }}</strong></h3>
    <section class="new-customer-card-section">
      <span>{{ $ml.get('newCustomer_section') }}</span>
      <br />
      <div class="kite-area">
        <button @click="goToTransfer()" class="btn">{{ $ml.get('btnTransfer') }}</button>
      </div>
    </section>
  </div>
</template>
<script>
import { getUserFullName, isApp, isMobile } from "@/services/utils";
import { EventBus } from '@/event-bus';

export default {
  name: 'NewCustomerCard',
  components: {},
  props: {},
  data() {
    return {
      isApp: isApp(),
      isMobileDevice: isMobile()
    }
  },
  created() {
    this.registrationData = JSON.parse(localStorage.getItem('registrationData'));

  },
  computed: {
    getLang() {
      return this.$store.getters.getLang;
    },
    getDir() {
      return this.$store.getters.getDir
    }
  },
  mounted() {

  },
  methods: {
    goToTransfer() {
          // EventBus.$emit('clearTransferState');
          this.$router.push('/transfernew')
      },
    getUserFullName
  },
  watch: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/styles/partials/variables";

.mygmt-new-customer-card {
  border-radius: 20px;
  //box-shadow: 0px 3px 18px 0px #BEBEBE40;
  white-space: pre-wrap;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding-top: 25px;

  img {
    height: 25px;
    width: 25px;
  }

  span {
    font-size: 16px;
    font-weight: 400;
    margin: 10px 0;
    align-self: start;
    color: $mainColor;
  }

  h3 {
    font-size: 32px;
    width: 100%;
    line-height: 32px;

    strong {
      font-weight: 900;
    }
  }

  .new-customer-card-section {
    margin: 20px 0;
    width: 100%;
    color: black;

    .btn {
      height: 48px;
      font-size: 14px;
      font-weight: 700;
      margin: 10px 0;
    }

    .kite-area {
      background-image: url("../assets/mygmt_v2_images/kite.svg");
      background-repeat: no-repeat;
      height: 120px;
      background-position: 89%;
      //width: 100vw;
      position: relative;
    }
  }
}

@media only screen and (min-width: $screen-tablet) {
  .mygmt-new-customer-card {
    margin: inherit;
    display: flex;

    padding: 15px;

    .kite-area {
      width: auto !important;
      background-image: none !important;
      height: auto !important;

      .btn {
        height: 48px !important;
        width: 111px;
      }
    }

    .new-customer-card-section {
      margin: 10px;
    }
  }
}
</style>

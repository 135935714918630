<template>
  <div class="main-container">
    <Drawer :class="{ 'max-h-70': isMobileDevice }" id="transactionDetailsDrawer" ref="transactionDetailsDrawer" drawerId="transactionDetailsDrawer">
      <template v-slot:content>
        <TransactionInfo :isMaxTransaction="isCardTransactions" :transaction="selectedTransaction"
          ref="transactionInfo" />
      </template>
    </Drawer>
    <div class="transaction-list-card-container">
      <div class="transaction-list-card-header">
        <h6> <strong style="font-size: 14px;">{{ $ml.get('cardHystory') }}</strong></h6>
        <h5 v-if="maxFetch" @click="$router.push({ name: 'TransactionsHistory' })" class="pointer">
          {{ $ml.get('seeAll') }}</h5>
      </div>
      <div v-if="loading" class="mygmt2-loader-wrapper">
        <img src="../assets/mygmt_v2_images/mygmt2-loader.gif" />
      </div>
      <div class="transaction-list-container" v-for="(transaction, index) in transactions" :key="index">
        <div :class="{ 'canceled-transaction': transaction.transactionStatus === 'CANCELED' }" class="line" v-if="isMobileDevice" @click="openTransactionDetailsDrawer(transaction)">
          <div class="transaction-details-wrapper transaction-details-left">
            <h6>{{ getTransactionLabel('Title-left', transaction) }}</h6>
            <span class="date">{{ getTransactionLabel('Subtitle-left', transaction) }}</span>
          </div>
          <div  class="transaction-details-wrapper transaction-details-right">
            <span v-if="transaction.transactionStatus === 'CANCELED'" class="red">
              {{ $ml.get('canceled')
              }}
            </span> 
              
            <h6 >{{ getTransactionLabel('Title-right',
      transaction) }} </h6>
            <h6 class="amount">
              <span :class="{'green': isCardTransactions && isCrediting(transaction)}">
                {{ getTransactionLabel('Subtitle-right', transaction) }} <span v-if="isCardTransactions && !getTransactionLabel('Subtitle-right', transaction)" class="spacer">&nbsp;</span> 
              </span>
              <!-- <span class="subtitle-span" v-if="getTransactionLabel('Subtitle-right-continuation', transaction)">
                , {{ getTransactionLabel('Subtitle-right-continuation', transaction) }}
              </span> -->
            </h6>
          </div>
        </div>
        <div :class="{ 'canceled-transaction': transaction.transactionStatus === 'CANCELED' }" class="line mygmt2-transactions-desktop-line" v-else @click="openTransactionDetailsDrawer(transaction)">
          <h6>{{ getTransactionLabel('Title-left', transaction) | lowerCaseCapitalise }}</h6>
          <span>{{ getTransactionLabel('Subtitle-right', transaction) }}</span>
          <span>{{ getTransactionLabel('Subtitle-right-continuation', transaction) }}</span>
          <span class="canceled-box">{{ getTransactionLabel('Subtitle-left', transaction) }}<strong
              class="canceled-transaction red" v-show="transaction.transactionStatus === 'CANCELED'">{{ $ml.get('canceled')
              }}</strong></span>
          <h6 class="last-inline" >{{
      getTransactionLabel('Title-right', transaction) }}</h6>
        </div>
        <!-- <div v-show="busy" class="mygmt2-loader-wrapper">
          <img class="loading" src="../assets/mygmt_v2_images/mygmt2-loader.gif" />
        </div> -->
      </div>
      <div v-if="noTransactions" class="mygmt2-line no-transactions-container">
        <img src="../assets/mygmt_v2_images/Not-Found.png" alt="content not found" />
        <h6>{{ $ml.get('nothing_found') }}</h6>
        <sub>{{ $ml.get('nothing_found_text') }}</sub>
      </div>

    </div>
  </div>
</template>
<script>
import { getCountryNameByIso, getCurrencySymbol, isApp, isMobile } from "@/services/utils";
import { getTransactions } from "@/services/api";
import Wallet from "@/components/Wallet.vue";
import Card from "@/components/Card.vue";
import Drawer from "@/components/Drawer.vue";
import moment from "moment";
import TransactionInfo from "@/components/TransactionInfo.vue";
import { formatDecimalAndStyle } from '../directives/AppDirectives'
import { formatComma } from '../filters/AppFilters'

export default {
  name: 'TransactionList',
  components: { TransactionInfo, Drawer, Card, Wallet },
  props: {
    maxFetch: {
      type: String,
      required: false
    },
    transactionsList: {
      required: false
    },
    isCardTransactions: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      isApp: isApp(),
      busy: false,
      loading: true,
      page: 0,
      transactions: [],
      noTransactions: false,
      newTransactions: [],
      selectedMonth: {},
      isMobileDevice: isMobile(),
      selectedTransaction: {},
    }
  },
  directives: {
    'formatDecimal': formatDecimalAndStyle
  },
  created() {
    window.scrollTo(0, 0);
    this.loading = true;
    if (this.maxFetch) {
      this.loadTransactions(this.page);
    } else {
      this.transactions = JSON.parse(this.transactionsList)
      this.busy = false;
      this.loading = false;
    }
  },
  computed: {
    moment() {
      return moment
    },
    getLang() {
      return this.$store.getters.getLang;
    },
  },
  mounted() {
  },
  methods: {
    getCurrencySymbol,
    loadTransactions(page) {
      getTransactions(page, (data) => {
        this.newTransactions = _.orderBy(data, [function (o) {
          return new Date(moment(o.date, 'DD/MM/YYYY'));
        }], ['desc']);
        this.transactions = [...this.transactions || [], ...this.newTransactions];
        if (this.maxFetch) {
          this.$store.commit('setCachedTransactions', this.transactions)
          this.transactions = _.take(this.transactions, parseInt(this.maxFetch))
        }
        this.noTransactions = this.transactions.length === 0
        this.busy = false;
        this.loading = false;
      });
    },
    getTransactionLabel(elementLocation, transaction) {
      let isToday = moment().isSame(moment(transaction.date, 'DD/MM/YYYY'), 'day');
      switch (elementLocation) {
        case ('Title-left'):
          return this.isCardTransactions ? this.getMaxDescriptionLabel(transaction) : transaction.otherSide || (transaction.descCode ? this.extractTranslationWithoutErrors(transaction.descCode)  : '')
        case ('Subtitle-right'):
          return this.isCardTransactions ? (this.isCrediting(transaction) ? this.$ml.get('crediting') : transaction.TransStatus == 1 ? this.$ml.get('inProcess') : '') : transaction.otherSide ? this.$ml.get( 'transactionClasses.' + transaction.transactionClass) : '';
        case ('Subtitle-right-continuation'):
          return this.isCardTransactions ? null : (transaction.destination ? getCountryNameByIso(transaction.countryIso) : '')
        case ('Title-right'):
          if (this.isCardTransactions) {
            return this.getMaxCreditLabel(transaction)
          } else {
            return `${getCurrencySymbol(transaction.currency)}${formatComma(transaction.amount)}`
          }
        case ('Subtitle-left'):
          return this.isCardTransactions ? this.getMaxFormattedDate(transaction.DateTimeBuy) + ', ' + transaction.CountryName : (isToday ? this.$ml.get('today') : (moment(transaction.date, 'DD/MM/YYYY').format('DD/MM/YYYY'))) + this.getLocationLabel(transaction)
        default:
          return ''
      }
    },

    extractTranslationWithoutErrors(key) {
      try {
        return this.$ml.get(key)
      } catch (error) {

      }
    },

    getLocationLabel(transaction) {
      return transaction.destination && this.isMobileDevice ? `, ${getCountryNameByIso(transaction.countryIso)}` : ''
    },
    getMaxDescriptionLabel(transaction) {
      return (this.$store.state.locale.lang != 'il' && transaction.MerchantNameEng) ? transaction.MerchantNameEng : transaction.MerchantName
    },
    getMaxFormattedDate(value) {
      if (value) {
        const date = moment(value);
        const isToday = moment().isSame(date, 'day');
        return isToday ? this.$ml.get('today') : date.format('DD/MM/YYYY');
      }
    },
    getMaxCreditLabel(transaction) {
      if (transaction.TotalAmount && transaction.TotalAmount > 0) {
        return `${getCurrencySymbol(transaction.Currency == '004' ? 'EUR' : transaction.Currency == '003' ? 'USD' : 'ILS')}${Math.abs(transaction.TotalAmount)}`
      } else if ( this.isCrediting(transaction)) {
        return `${getCurrencySymbol(transaction.Currency == '004' ? 'EUR' : transaction.Currency == '003' ? 'USD' : 'ILS')}${Math.abs(transaction.TotalAmount)} `
      } else {
        return `${getCurrencySymbol(transaction.OrigCurrency)}${Math.abs(transaction.OrigAmount)}`
      }
    },

    isCrediting(transaction){
    return transaction.TotalAmount < 0 || transaction.ChargeAmount < 0
    },

    openTransactionDetailsDrawer(transaction) {
      $("#checkStatusText").hide()
      this.selectedTransaction = transaction;
      this.$refs.transactionDetailsDrawer.openDrawer()
      if (transaction.canCheckStatus) {
        this.$refs.transactionInfo.checkStatus(transaction.gmtId)
      }
    },
  },
  watch: {
    transactionsList: {
      immediate: true,
      handler(val) {
        if (val) {
          this.transactions = JSON.parse(val)
          this.noTransactions = this.transactions.length === 0
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/partials/variables";

.main-container {
  border-radius: 20px;
  background-color: white;
}

#checkStatusText {
  border-radius: 16px;
  padding: 5px 10px;
  margin: 5px 0;
  color: white;
}

.mygmt2-loader {
  height: auto;
  width: auto;
  display: inline-flex;

  img {
    width: 30px;
  }
}

.mygmt2-loader-wrapper {
  display: flex;
  width: 100%;
  margin: auto;
  justify-content: center;
}

.transaction-list-card-container {
  padding: 0 5%;
  border-radius: 20px;
  height: auto;
  background-color: white;
  margin-top: 10px;
  box-shadow: 0px -3px 18px 0px #D2D2D240;

  .transaction-list-card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 0;
  }

  h6,
  h5,
  h4 {
    color: black;
    font-weight: 590;
    white-space: nowrap;
    overflow: hidden;
    font-size: 14px;
  }

  h4 {
    font-weight: 700;
  }

  h6 {
    font-size: 16px;
  }

  .red {
    color: #FF0B0B !important;
    font-family: Assistant;
    font-size: 14px;
    font-style: normal;
    font-weight: 600; 
    opacity: 1 !important;
  } 
   .green {
    color: rgb(57, 181, 74) !important;
    font-family: Assistant;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 600 !important; 
    opacity: 1 !important;
  }


  .transaction-list-container {
    margin-top: 10px;

    .line {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0;
      font-size: 14px;
      min-height: 40px;
      cursor: pointer;

      .transaction-details-wrapper {
        min-width: 100px;
      }

      .transaction-details-left {
        text-align: start;
      }

      .transaction-details-right {
        text-align: end;
      }

      span {
        color: black;
        opacity: .6;
        font-size: 12px;
      }

      .subtitle-span {
        opacity: 1;
      }
    }

    .mygmt2-transactions-desktop-line {
      padding: 0 5px;
      border-radius: 8px;

      &:hover {
        background-color: #F0FAFF;
      }

      h6 {
        width: 200px;
      }

      span {
        width: 200px;
      }

      .last-inline {
        text-align: end;
      }
    }

    .canceled-box {
      display: flex;
      justify-content: space-between;
      align-items: end;
      white-space: nowrap;
    }

    .canceled-transaction {
      background: #FAFAFA;
      box-sizing: content-box;
      border-radius: 0;
      margin-left: -20px;
      margin-right: -15px;
      padding-left: 25px;
      padding-right: 19px; 
      h6.last-inline {
       padding-right: 10px;
       padding-left: 9px;
       }
     
    } 
    [dir="rtl"] .canceled-transaction {
     padding-right: 19px;
     h6.last-inline {
       padding-left: 8px;
       }
}
  }


  .no-transactions-container {
    flex-direction: column;
    color: grey;
    padding: 16px;

    h6 {
      margin: 10px;
      font-size: 20px;
      font-weight: bold;
    }
  }


}


@media only screen and (min-width: $screen-tablet) {

  #transactionDetailsDrawer {
    z-index: 99999 !important;
  }

  .no-transactions-container {
    height: 90%;
  }

  .transaction-list-card-container {
    background-color: white;
    height: 100%;
    margin-top: 0;
    padding: 15px 15px 15px 24px;
    box-shadow: none;
  }

  .transaction-list-card-header {
    h4 {
      font-size: 16px;
      font-weight: 700;
    }

  }
}
</style>

// DesktopMenu.vue
<template>
  <transition name="fade">
    <div v-if="isOpen" :ref="name+'MenuRef'" class="desktop-menu" :style="menuStyle" @click.self="closeMenu">
      <div class="menu-content"  :style="contentStyle">
        <button v-if="showCloseButton" @click="closeMenu" class="close-button"><img style="width: 14px;" src="../assets/mygmt_v2_images/close-black.svg" alt="" srcset=""></button>
        <slot :dynamicProp="dynamicProp"></slot>
      </div>
    </div>
  </transition>
</template>

<script>
import Vue from 'vue';
import { EventBus } from '../event-bus';
import { isMobile } from '../services/utils';

export default {
  name: 'DesktopMenu',
  props: {
    backgroundColor: {
      type: String,
      default: 'transparent'
    },
    backgroundFilter: {
      type: String,
      default: 'none'
    }, 
    name: {
      type: String
    },
    width: {
    },
    height: {
    },
    parentRef: {
    },
    showCloseButton: {
      type: Boolean,
      default: false
    },
    position: {
      type: String,
      default: 'center',
      validator: (value) => ['center', 'cursor', 'custom'].includes(value)
    },
    customPosition: {
      type: Object,
      default: () => ({ x: 0, y: 0 })
    },
    openDirection: {
      type: String,
      default: 'left',
      validator: (value) => ['left', 'right'].includes(value)
    },
    isSideMenuVisible: {
      type: Boolean
    }
    
  },
  data() {
    return {
      isOpen: false,
      cursorPosition: { x: 0, y: 0 },
      dynamicProp: null
    };
  },
  computed: {
    menuStyle() {
      return {
        backgroundColor: this.backgroundColor,
        backdropFilter: this.backgroundFilter,
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: this.openDirection === 'left' ? 'flex-start' : 'center',
        alignItems: 'center',
        zIndex: 9999,
      };
    },
    contentStyle() {
      let style = {
        position: 'relative',
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '4px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        width: this.width  ||  'auto',
        height: this.height || 'auto'

      };
      console.log("🚀 ~ contentStyle ~ this.parentRef:", this.parentRef)
      if (this.parentRef) {
        console.log("🚀 ~ contentStyle ~ this.parentRef:", this.parentRef)
        style.position = 'absolute';
        const rect = this.parentRef[0].getBoundingClientRect();
        console.log("🚀 ~ openSelectMenu ~ rect:", rect)
        const rectWitdh = rect.width
        style.width = `${rectWitdh}px`
        style.top = `${rect.bottom + window.scrollY}`
        style.left = `${rect.left + rectWitdh}`,
        style.height = this.height ||

      console.log('nefore open');
      // setTimeout(() => {
      //   EventBus.$emit('toggleMenu', event, field.name);
      // }, 0)
      } else if (this.position === 'cursor') {
        style.position = 'absolute';
        style.top = `${this.cursorPosition.y}px`;
        if (this.openDirection === 'left') {
          style.right = `${window.innerWidth - this.cursorPosition.x}px`;
        } else {
          style.left = `${this.cursorPosition.x}px`;
        }
      } else if (this.position === 'custom') {
        style.position = 'absolute';
        style.top = `${this.customPosition.y}px`;
        if (this.openDirection === 'left') {
          style.right = `${window.innerWidth - this.customPosition.x}px`;
        } else {
          style.left = `${this.customPosition.x}px`;
        }
      } else if (this.position === 'center') {
          style.margin = 'auto';
          if (!isMobile() && this.isSideMenuVisible) {
            style.insetInlineStart = '5.6vw';

          }
        }
        else if (this.position === 'fullscreen') {
          style.height = `100vh`;
          style.width = `100vw`;

        }
      //   setTimeout(() => {
      //   EventBus.$emit('toggleMenu', event, field.name);
      // }, 0)
      console.log("🚀 ~ contentStyle ~ style:", style)
      return style;
    }
  },
  methods: {
    toggleMenu(event, name, value) {
      if (name !== this.name) return
      if (this.position === 'cursor' && event) {
        this.cursorPosition = { x: event.clientX, y: event.clientY };
      }
      this.dynamicProp = value;
      this.isOpen = !this.isOpen;
      !this.isOpen ? document.body.style.overflow = '' : document.body.style.overflow = 'hidden';
      this.$emit('menuToggled')


    },
    closeMenu() {

      
      this.isOpen = !this.isOpen;
      document.body.style.overflow = '';
      this.$emit('menuClosedOuter')
    },
    openSelectMenu() {
      console.log("🚀 ~ openSelectMenu ~ rect:", this.parentRef)
      const rect = this.parentRef[0].getBoundingClientRect();
      console.log("🚀 ~ openSelectMenu ~ rect:", rect)
      const rectWitdh = rect.width
      this.menuWidth = `${rectWitdh}px`
      this.menuPosition = {
        x: `${rect.left + rectWitdh}`,
        y: `${rect.bottom + window.scrollY}`
      };
      console.log('nefore open');
      setTimeout(() => {
        // Need to fake delay so Vue can registerthe name prop before opening
        EventBus.$emit('toggleMenu', event, field.name);
      }, 0)
    },

  },
  created() {
    EventBus.$on('toggleMenu', this.toggleMenu);
    // this.openSelectMenu()
  },
  beforeDestroy() {
    EventBus.$off('openDesktopMenu', this.openMenu);
    document.body.style.overflow = '';
  }
};
</script>

<style scoped>
.desktop-menu {
  transition: opacity 0.3s ease;
}

.close-button {
  position: absolute;
  top: 10px;
  left: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
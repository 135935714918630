<template>
  <div>
    <div v-show="!$store.getters.getTransferStatus && $store.getters.getTransferStatus !== 'NO_DELIVERY_OPTIONS'">

    <div  v-if="!isCalculatorRoute" class="text-container mobile-margin">
      <h4>{{ $ml.get('transfer.titles.chooseDestination') }} </h4>
    </div>
    <div class="scrollable" :class="{'is-searching': isFocused}" ref="scrollable">
      <transition  name="slide-fade-in" appear>
      <div :key="transitionKey" class="mobile-margin" >
        <!-- <div v-if="(isMobileDevice && !isFocused && !searchInputTxt) || (!isMobileDevice && !searchInputTxt)"> -->
        <div  v-if="!isFocused && !searchInputTxt">
          <div v-if="recentDestinations.length">
            <h5>{{ $ml.get('transfer.titles.recentDestinations') }}</h5>
              <div v-for="country in recentDestinations" :key="country.iso" @click="selectCountry(country.iso)">
                <Country :countryName="country[getLang]" :countryIso="country.iso"
                  :allowedCurrencies="country.allowedCurrencies" :key="country.iso" class="country">
                </Country>
              </div>
          </div>
          <div v-else-if="isIsraeli">
            <h5>{{ $ml.get('transfer.titles.popularDestinations') }}</h5>
            <div v-for="country in popularDestinations" @click="selectCountry(country.iso)">
              <Country :key="country.iso" :countryName="country[getLang]" :countryIso="country.iso"
                :allowedCurrencies="country.allowedCurrencies" class="country"></Country>
            </div>
          </div>
          <div v-else-if="originCountry">
            <h5>{{ $ml.get('send_money_home') }}</h5>
            <div @click="selectCountry(originCountry.iso)">
              <Country :key="originCountry.iso" :countryName="originCountry[getLang]" :countryIso="originCountry.iso"
                :allowedCurrencies="originCountry.allowedCurrencies" class="country"></Country>

            </div>
          </div>
        </div>
       
      </div>
    </transition>

      <div v-if="!isCalculatorRoute" class="text-container  mobile-margin" :class="{'all-dest': !isFocused && !searchInputTxt}">
        <h5 > {{$ml.get('transfer.titles.allDestinations')}}</h5>
      </div>
      <div @click="focusSearch()" class="search-box-container" ref="searchBox">
        <div class="round-box search-box">
          <img src="../../../../assets/mygmt_v2_images/Search.svg" />
          <input :value="searchInputTxt" @focus="handleFocus" @blur="handleBlur"
            :placeholder="$ml.get('transfer.countrySearchPlaceholder')" type="text" ref="searchBoxInput"
            @input="handleDebouncedInput" />
          <img @click.stop="searchInputTxt = ''; handleBlur" v-if="searchInputTxt" class="pointer"
            style="height: 11px; width: 11px;;" src="../../../../assets/mygmt_v2_images/close-black.svg" alt="">
        </div>
      </div>
      <div v-if="searchResult.length || !searchInputTxt" class="countries-container mobile-margin">
        <transition-group name="fade" tag="div" class="countries-container">
          <div @click="selectCountry(country.iso)" v-for="country in searchInputTxt ? searchResult : visibleCountries"
            :key="country.iso" class="flex justify-between pointer country  round-box">
            <div class="left-side full-center">
              <img :src="imgSrc(country)" />
              <div>{{ country[getLang] }}</div>
            </div>
            <div class="right-side full-center">
              {{ country.allowedCurrencies.join(' - ') }}
            </div>
          </div>
        </transition-group>
      </div>
      <div v-else class="not-found-section">
        <div>
          <img src="../../../../assets/mygmt_v2_images/Not-Found.png" />
          <p>{{$ml.get('transfer.titles.nothingFound')  }}</p>
          <p>{{$ml.get('transfer.titles.typedCorrectly')  }}</p>
        </div>
      </div>
    </div>
  </div>
    <div class="flex flex-column align-center unaviable-section" v-show="$store.getters.getTransferStatus === 'NO_DELIVERY_OPTIONS'">
    <img src="../../../../assets/mygmt_v2_images/not-aviable-sign.svg" alt="" srcset="">
    <h2>Service Is Unavailable</h2>
    <p>Our service to <span> {{countryName}} </span> is currently not available. <br>
      We apologize for the inconvenience.
    </p>
   <button class="btn btn-main" @click="$store.commit('setTransferStatus', null )">I'll choose another country</button>
      <a class="pointer" @click="openContactUs">Contact us</a>
  </div>
</div>

</template>
<script>
import Country from './Country.vue';
import countries from '../../../../data/countries.json';
import _ from 'lodash';
import { RecycleScroller } from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import { getTransactions } from '../../../../services/api'
import { isApp, isMobile , getCountryNameByIso} from '../../../../services/utils';
import { EventBus } from '@/event-bus';



export default {
  name: 'CountrySelect',
  components: { Country, RecycleScroller },
  props: {
    isCalculatorRoute: {
      type: Boolean
    }
  },
  data() {
    return {
      searchInputTxt: '',
      recentCountries: [],
      originCountry: null,
      popularDestinations: ['UKR', 'GEO', 'RUS'],
      recentDestinations: [],
      pageSize: 20,
      currentPage: 1,
      maxPage: 0,
      countriesFull: [],
      visibleCountries: [],
      // searchResult:[],
      segregatedCountries: [],
      loading: true,
      loadingMore: false,
      isFocused: false,
      isMobileDevice: isMobile(),
      debouncedInputTxt: ''
    };
  },
  created() {
    this.setCountriesList()
    this.setRecentDestinations()
    this.originCountry = this.getCountryByIso(this.country)
    this.popularDestinations = this.popularDestinations.map((iso) => this.getCountryByIso(iso))
    this.visibleCountries = this.countriesFull.slice(0, this.pageSize);
    this.segregatedCountries = _.chunk(this.countriesFull, 5)
    console.log("🚀 ~ countryName32323 ~ this.$store.state.transfer.destinationCountry:", this.$store.state.transfer.destinationCountry)



  },
  computed: {
    searchResult() {
      if (this.searchInputTxt === '') {
      }
      this.resetVisibleCountries()
      const search = this.debouncedInputTxt.toLowerCase();
      const bla = this.countriesFull.filter((country) => country.ezTxt.includes(search))
      return bla

      return _.filter(this.countriesFull, (country) => {
        const countryName = country[this.getLang].toLowerCase();
        const countryIso = country.iso.toLowerCase();
        const allowedCurrencies = country.allowedCurrencies.map((currency) =>
          currency.toLowerCase()
        );

        return (
          countryName.includes(search) ||
          countryIso.includes(search) ||
          _.some(allowedCurrencies, (currency) => currency.includes(search))
        );
      });
    },

    transitionKey() {
      // Generate a stable key based on the state you want to track
      return `${this.isFocused}-${this.searchInputTxt}`;
    },

    countryName(){
     return getCountryNameByIso(this.$store.state.transfer.destinationCountry)
    },

    paginatedCountries() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = start + this.pageSize;
      return this.searchResult.slice(start, end);
    },
    getLang() {
      return this.$store.getters.getLang;
    },
    country() {
      try {
        const rd = JSON.parse(localStorage.getItem('registrationData'));
        console.log("🚀 ~ country ~ rd:", rd)
        return rd.country;
      } catch (error) {
        
      }
    },
    isIsraeli() {
      return this.country == 'ISR';
    },
  },

  mounted() {
    document.body.style['overscroll-behavior'] = 'none';
    this.$refs.scrollable.addEventListener('scroll', this.handleScroll);
    this.$refs.scrollable.addEventListener('touchmove', function (e) {
      e.stopPropagation();
    }, { passive: false });

    this.adjustBodyForAndroid()


    // this.loadMoreCountries(); // Load initial set of countries
  },

  beforeDestroy() {
    this.$refs.scrollable.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('scroll', this.scrollToTop)
  },

  methods: {

    debouncedSearch: _.debounce(function () {
      this.debouncedInputTxt = this.searchInputTxt.toLowerCase(); 
    }, 300), 

    openContactUs(){
      EventBus.$emit('openContactUs')
      console.log('emittttttt',EventBus);
    },

    handleDebouncedInput(event) {
      this.searchInputTxt = event.target.value; // Immediate input
      this.debouncedSearch(); // Call the debounced function
    },


    handleBlur() {
      setTimeout(() => {
        this.isFocused = false
      }, 50)
    },

    handleFocus() {
      setTimeout(() => {
        this.isFocused = true
        setTimeout(() => {
          window.scrollTo(0, 1)
          const scrollable = this.$refs.scrollable;
          scrollable.scrollTo(0, 0)
        }, 100)
        // window.scrollTo(0, document.body.scrollHeight);
      }, 300)
    },

    adjustBodyForAndroid() {
      const isAndroid = /Android/i.test(navigator.userAgent);

      if (isAndroid && isApp()) {
        document.body.style.height = '101vh';
        window.scrollTo(0, document.body.scrollHeight);
        window.addEventListener('scroll', this.scrollToTop);
      }
    },
    scrollToTop(){
      if (window.scrollY === 0) {
            window.scrollTo(0, document.body.scrollHeight); 
          }
    },


    resetVisibleCountries() {
      this.currentPage = 1
      this.visibleCountries = this.countriesFull.slice(0, this.pageSize)
      if (this.$refs.scrollable) {
        this.$refs.scrollable.scrollTop = 0;
      }
    },

    selectCountry(countryIso) {
      this.$store.commit('setTransferDestinationCountry', countryIso);
      // this.$store.commit('setDestinationCountry', countryIso);

      this.$emit('changeStep', 1)
    },

    handleScroll() {
      const scrollable = this.$refs.scrollable;
      const searchBox = this.$refs.searchBox;
      const searchBoxRect = searchBox.getBoundingClientRect();
      const scrollableRect = scrollable.getBoundingClientRect();

      if (scrollable.scrollTop + scrollable.clientHeight >= scrollable.scrollHeight - 100) {
        this.loadMoreCountries();
      }
      console.log("🚀 ~ 1 ~ 1:", scrollableRect.top)
      console.log("🚀 ~ 2 ~ 2:", searchBoxRect.top)

      if (searchBoxRect.top < scrollableRect.top) {
        console.log('lalalal111');
        
        searchBox.style.position = 'sticky';
        searchBox.style.top = '-10px';
        searchBox.style.zIndex = '1';
        searchBox.classList.add('drop-shadow'); // Add class
      } else {
        console.log('22222');

        searchBox.style.position = 'relative';
        searchBox.classList.remove('drop-shadow'); // Remove class
      }
    },
    loadMoreCountries() {

      // if (this.loadingMore || this.searchResult.length >= this.countriesFull.length) return;

      // this.loadingMore = true;

      // setTimeout(() => {
      const nextPage = this.currentPage + 1;
      const start = this.currentPage * this.pageSize;
      const end = nextPage * this.pageSize;
      // this.visibleCountries = [...this.visibleCountries, _chunk(this.countriesFull)]
      this.visibleCountries.push(...this.countriesFull.slice(start, end));
      // this.searchResult.push(...this.countriesFull.slice(start, end));
      this.currentPage = nextPage;
      // this.loadingMore = false;
      // }, 100); 
    },

    focusSearch() {
      if (this.isFocused) return
      const searchBox = this.$refs.searchBoxInput;
      this.isFocused = true
      searchBox.focus()
    },
    imgSrc(country) {
      try {
        return require(`../../../../assets/mygmt_v2_images/countries/${country.iso}.svg`);
      } catch (error) {
      }
    },
    getCountryByIso(iso) {
      return this.countriesFull.find((country) => iso === country.iso)
    },
    setCountriesList() {
      this.countriesFull = _.sortBy(
        countries.filter(item => {return item.moneygram !== false}).map((country) => {
          country['ezTxt'] = `${country['allowedCurrencies'].map(c => c.toLowerCase()).join(' ')}${country.en.toLowerCase()}${country.iso.toLowerCase()}${country.cn.toLowerCase()}${country.ru.toLowerCase()}${country.il.toLowerCase()}${country.th.toLowerCase()}`;
          country['allowedCurrencies'] = country['allowedCurrencies'].filter((currency)=> currency !== 'ILS')
          return country;
        }),
        (country) => country[this.getLang].toLowerCase()
      );
    },

    getCountriesFromTransactions(transactions) {
      const countriesIso = Array.from(transactions.reduce((acc, item) => {
        if (item.countryIso && acc.size < 3) {
          acc.add(item.countryIso)
        }
        return acc;
      }, new Set([])))
      return countriesIso.map((iso) => this.getCountryByIso(iso))
    },

    setRecentDestinations() {
      if (this.isCalculatorRoute) {
        this.loading = false;
        return;
      }
      const transactions = this.$store.getters.getCachedTransactions
      if (transactions) {
        this.recentDestinations = this.getCountriesFromTransactions(transactions)
      } else {
        getTransactions(0, (data) => {
          if (data && data.length) {
            this.recentDestinations = this.getCountriesFromTransactions(data)
            this.$store.commit('setCachedTransactions', data)
          }
        })
      }
      this.loading = false
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/styles/partials/variables";


.mobile-margin {
  margin-left: 20px;
    margin-right: 20px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to
  {
  opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: opacity 0.2s ease-out, transform 0.2s ease-out;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateY(50px);
}

.slide-fade-in-enter-active {
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.slide-fade-in-enter {
  opacity: 0;
  transform: translateY(50px);
}

input::placeholder {
  font-size: 16px;
  color: #7F818D;
  font-weight: 400;
  font-style: normal;
  line-height: normal;
  font-family: Assistant;
}

.all-dest {
  margin-top: 24px;
}
.is-searching {
  height: 90vh !important;
}
input {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  font-family: Assistant;
}

.left-side {
  div {
    margin-left: 12.5px;
    margin-right: 12.5px;
    color: #161932;
    font-size: 16px;
    font-weight: 600;
  }

  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    box-shadow: 0 2px 30px rgba(0, 0, 0, 0.2);
  }
}

.right-side {
  font-size: 13px;
  margin-left: 10px;
  margin-right: 10px;
  color: rgba(22, 25, 50, 0.69);
}

.country:hover {
  background-color: #F0FAFF;
}

.transfer-container {

}

.round-box {
  padding: 12.5px 14px;
}

h4,
h5 {
  color: #000;

}

h4 {
  font-size: 18px;
  margin-top: 16px;
  font-weight: 600;
  margin-bottom: 8px;
}

h5 {
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 12px;
}

.drop-shadow {
  filter: drop-shadow(0px 9px 5px rgba(0, 0, 0, 0.05));
}

.search-box-container {
  padding-top: 10px;
  padding-left: 20px;
  background: white;
  padding-bottom: 1px;
  padding-right: 20px;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  // filter: drop-shadow(0px 9px 5px rgba(0, 0, 0, 0.05));

  .search-box {
    display: flex;
    align-items: center;
    cursor: text;
    padding-bottom: 17px;
    padding-top: 17px;

    img {
      width: 23px;
      height: 23px;
    }

    input {
      border: none;
      font-size: 16px;
      width: 100%;
      margin-left: 10px;
    }
  }
}

.scrollable {
  height: 76vh;
  overflow-y: auto;
  overscroll-behavior: contain !important;
  -webkit-user-select: none !important;
  -webkit-touch-callout: none !important;
  touch-action: pan-y !important;
  -webkit-overflow-scrolling: touch !important;
  scrollbar-width: none !important;
  overscroll-behavior-y: contain !important;
  scroll-behavior: smooth !important;
}

body {
  overscroll-behavior-y: none !important;
}

::-webkit-scrollbar {
  display: none;
}

.unaviable-section {
  color: black;
   h2 {
    font-size: 20px;
    margin-bottom: 15px;
   }
   p {
    color: black;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 32px;
    text-align: center;
    span {
      font-weight: 700;
    }
   }

   .btn {
    height: 45px;
    width: 100%;
    margin-bottom: 20px;
   }
}

@media only screen and (min-width: $screen-tablet) {

  .search-box-container {
    padding-left: 0px;
    padding-top: 10px;
    padding-right: 0px;
    padding-bottom: 1px;
    background: white;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    // filter: drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.05));
}

  .mobile-margin {
    margin-left: 0px;
    margin-right: 0px;
}

  .left-side {
    div {
      font-size: 16px;
    }
  }
  .transfer-container {
    // padding: 20px !important;
}
  .all-dest {
    margin-top: 40px;
  }

  .right-side {
    font-size: 16px
  }

  .round-box {

    padding: 15px 14px;
  }

  .scrollable {
    height: 65vh;

    overflow-y: auto;
    overscroll-behavior: none;


  }

  h4 {
    font-size: 20px;
    margin-top: 20px;

  }

  h5 {
    font-size: 18px;
    margin-bottom: 20px;
  }
}




.not-found-section {
  font-size: 12px;
  color: #9D9D9D;
  margin-top: 120px;

  p:first-of-type {
    font-size: 16px;
    font-weight: 800;
    margin-bottom: 5px;
  }

  div {
    width: 70%;
    margin: auto;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
  }

  img {
    margin: auto;
    max-width: 300px;
  }
}

.unaviable-section {
   .btn {
    height: 45px;
    width: 335px;
    margin-bottom: 20px;
   }
}
</style>

<template>
  <div :dir="$store.getters.getDir" class="flex flex-column justify-between h-100">
    <div v-if="!isCreatingBeneficiary" class="beneficiary-list flex flex-column justify-between">
      <div  style="overflow: auto;padding: 0 10px; scrollbar-width: none; 
  -ms-overflow-style: none;" ref="scrollableContainer">
        <div class="title">Choose beneficiary</div>
        <div class="item-beneficiary pointer" v-for="(beneficiary, index) in beneficiaries"
          @click="selectBeneficiary(beneficiary)">
          <!-- <div>{{ beneficiary }}</div> -->
          <div class="item-row flex">
            <div class="flex full-center justify-between beneficiary-container">
              <div class="flex full-center">
                <div class="circle pointer" @click=""><strong>{{ beneficiary.firstName[0].toUpperCase()
                    }}</strong></div>
                <div class="flex flex-column">
                  <div class="name flex">{{ beneficiary.firstName | lowerCaseCapitalise }} {{ beneficiary.lastName  | lowerCaseCapitalise}}</div>
                  <div class="info">
                    <span v-if="cardDeposit && beneficiary.maskedCardNumber">
                      {{ $ml.with('VueJS').get('toCreditCard') }} {{ beneficiary.maskedCardNumber | formatCardNumber }}
                    </span>
                    <span v-else-if="bankTransfer && (beneficiary.accountIdentifier || beneficiary.iban)">
                      {{ $ml.with('VueJS').get('toBankAccount') }} {{ beneficiary.accountIdentifier ?
                        beneficiary.accountIdentifier : beneficiary.iban }}
                    </span>
                    <span v-else-if="isUpi && beneficiary.upiIdentifier">
                      {{ $ml.with('VueJS').get('beneficiary.upi') }} {{ beneficiary.upiIdentifier }}
                    </span>
                    <span v-else-if="(walletTransfer && beneficiary.walletIdentifier) && !isWalletPhone">
                      to  {{ beneficiary.walletBankName || $store.getters.getSelectedTransferOption.correspondentName}} {{ beneficiary.walletIdentifier }}
                    </span>
                    <span v-else-if="cashBank && beneficiary.cashBankName">
                      {{ $ml.with('VueJS').get('pickupPoint') }} {{ beneficiary.cashBankName }}
                    </span>
                    <span v-else-if="isWalletPhone && (beneficiary.phoneNumber || beneficiary.walletBankName)">
                      to  {{  $store.getters.getSelectedTransferOption.correspondentIdentifier === 'THUNES_WECHATPAY' ?
                  $store.getters.getSelectedTransferOption.correspondentName : beneficiary.walletBankName }} {{$store.getters.getSelectedTransferOption.correspondentIdentifier === 'THUNES_WECHATPAY' ?
                  beneficiary.walletIdentifier  : beneficiary.phoneNumber }}
                    </span>
                    <!-- <span v-else></span> -->
                  </div>
                </div>
              </div>
              <div> <img :class="{'rotate': isRtl}" style="height: 15px;margin-top: 4px;" src="../../../../assets/mygmt_v2_images/Right-Chevron-blue.svg" alt=""></div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-center creation-btn" :class="{ shadow: isOverflowing }" style="padding-top: 20px;"> <button @click="toggleCreateBeneficiary()" class="btn btn-primary">+ Add New Beneficiary</button> </div>
    </div>
    <!-- <BeneficiaryCreation v-else></BeneficiaryCreation> -->
    <Beneficiary @createdBeneficiary="createdBeneficiary" @update="createdBeneficiary"  :action="action" :id="beneficiaryId" v-else></Beneficiary>
  </div>
</template>
<script>
import _ from 'lodash';
import { getCountryByIso, isApp, isMobile } from '../../../../services/utils';
import BeneficiaryCreation from './BeneficiaryCreation.vue';
import Beneficiary from '../../../Beneficiary.vue';
import { getBeneficiary, validateBeneficiary } from '../../../../services/api';
import { lowerCaseCapitalise } from '../../../../filters/AppFilters';



export default {
  components: { BeneficiaryCreation, Beneficiary },
  created() {
    this.selectedCountry = getCountryByIso(this.$store.state.transfer.destinationCountry)
    if (this.$store.state.transfer.cloneInfo) {
      this.$emit('changeStep', 3)
    }
  
    // console.log('blaa', this.transactionClassSelected);

  },
  data() {
    return {
      selectedCountry: {},
      selectedBeneficiary: {},
      isMobileDevice: isMobile(),
      isCreatingBeneficiary: false,
      transactionClassSelected: this.$store.getters.getSelectedTransferOption.transactionClass,
      name: 'BeneficiarySelection',
      beneficiaryId: '',
      action: 'create',
      isOverflowing: false
    };
  },
  computed: {
    cardDeposit() {
      return this.transactionClassSelected == 'CARD_DEPOSIT';
    },
    isRtl(){
      return this.$store.getters.getDir === 'rtl'
      },
    bankTransfer() {
      return this.transactionClassSelected == 'BANK_TRANSFER';
    },
    walletTransfer() {
      return this.transactionClassSelected == 'WALLET_TRANSFER';
    },
    cashBank() {
      return this.transactionClassSelected == 'CASH_TRANSFER' && this.$store.getters.getSelectedTransferOption.correspondentType == 'TRANGLO';
    },
    isUpi() {
      return this.$store.getters.getSelectedTransferOption && this.$store.getters.getSelectedTransferOption.transactionClass ==  'UPI_BANK_TRANSFER'
    },
    isWalletPhone() {
      return this.$store.getters.getSelectedTransferOption && (this.$store.getters.getSelectedTransferOption.correspondentIdentifier == 'THUNES_WECHATPAY'|| this.$store.getters.getSelectedTransferOption.correspondentIdentifier == 'THUNES');
    },
    beneficiaries() {
      return Object.filter(this.$store.getters.getListBeneficiary, item => {
        return (item.countryIso3.indexOf(this.selectedCountry.iso) > -1);
      });
    },
  },
  watch: {
  },
  mounted() {
    this.checkOverflow();
    window.addEventListener("resize", this.checkOverflow);
    this.$refs.scrollableContainer.addEventListener("scroll", this.checkOverflow);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkOverflow);
    this.$refs.scrollableContainer.removeEventListener(
      "scroll",
      this.checkOverflow
    );
  },
  methods: {
    async selectBeneficiary(beneficiary) {
      this.$store.commit('setCurrentBeneficiary', beneficiary.id); // after reload is undefined
      this.$store.commit('setCurrentBeneficiaryDetails', beneficiary); // after reload is undefined
      sessionStorage.setItem('currentBeneficiary', beneficiary.id); // for reload .
      await this.updateBeneficiary();
      // this.$emit('changeStep', 3)
      // this.$analyticsService.transmitSwitchCase('transaction', 'transaction_s_3', { error_count: 0, beneficiary: 'existing_beneficiary' })
      // await this.updateBeneficiary();
      // modalHandler('.modal-mask', 'hide', 'ffffff', 'dark')
    },
    checkOverflow() {
      const container = this.$refs.scrollableContainer;
      // Check if the content overflows the container
      this.isOverflowing = container.scrollHeight > container.clientHeight;
    },
    createdBeneficiary(){
      this.$emit('changeStep', 3)
    },
    toggleCreateBeneficiary(){
      this.$store.commit('setCurrentBeneficiary', null); 
      this.$store.commit('setCurrentBeneficiaryDetails', {}); 
      sessionStorage.setItem('currentBeneficiary', null);
      this.action = 'create'
      this.selectedBeneficiary = {}
      this.isCreatingBeneficiary = true
    },
    async updateBeneficiary() {
      console.log("🚀 ~ updateBeneficiary ~ this.$store.getters.getSelectedBeneficiary:", this.$store.getters.getSelectedBeneficiary)
      const beneficiary = await getBeneficiary(this.$store.getters.getSelectedBeneficiary);
      // this.$store.commit('set')
      // console.log("🚀 ~ updateBeneficiary ~ beneficiary22222:", beneficiary)
      let constraints = JSON.parse(sessionStorage.getItem('beneficiaryFieldsConstraints'));
      if (beneficiary.maskedCardNumber) {
        constraints = Object.assign({}, constraints, { 'maskedCardNumber': {} })
      }
      // app.$Progress.finish();
      const res = await this.validateBeneficiary(beneficiary, constraints)
      if (res.error) {
        sessionStorage.setItem('currentBeneficiary', null);
        this.$store.commit('setCurrentBeneficiary', null);
        return;
      }
      if (res.valid) {
        this.$store.commit('setBeneficiaryValid', true);
        this.$emit('changeStep', 3)

      } else {
        this.isCreatingBeneficiary = true
        this.action = 'update'
        this.beneficiaryId = beneficiary.id
        // await this.$router.push({ name: 'transfer-beneficiary', params: { id: beneficiary.id, action: 'update' } });
      }
    },
    
    async validateBeneficiary(beneficiary, constraints) {
      let resVal = { valid: true }



      for (const key in beneficiary) {
        // console.log("🚀 ~ validateBeneficiary ~ key:", key)
        if (beneficiary && beneficiary.hasOwnProperty(key) && constraints.hasOwnProperty(key)) {
          resVal.valid = (await this.$validator.verify(beneficiary[key], constraints[key])).valid
          if (!resVal.valid) {
            // console.log('NOT VALID:1',key);
            // console.log('NOT VALID:2',beneficiary[key]);
            // console.log('NOT VALID:3',constraints[key] );
            
            break;
          }
        }
      }
      // console.log("🚀 ~ validateBeneficiary ~ resVal:", resVal)
      if (resVal.valid) {
        let res = await validateBeneficiary({
          beneficiary: beneficiary,
          correspondentId: this.$store.getters.getSelectedTransferOption.correspondentId,
          transaction: {
            amount: this.$store.getters.getSelectedTransferOption.amount,
            transactionClass: this.$store.getters.getSelectedTransferOption.transactionClass,
            payoutCurrency: this.$store.getters.getSelectedTransferOption.payoutCurrency
          }
        })
        resVal.valid = res.result.valid;
        if (res.errorCode)
          resVal.error = true;
      }
      this.$validator.reset();
      return resVal
    },
    
  },
  
};
</script>

<style lang="scss" scoped >
@import "../../../../assets/styles/partials/variables";
.beneficiary-list {
  max-height: 83vh;
}



::v-deep .transfer-container {
  // styles here
  background-color: red;
}

.item-beneficiary {
  margin-bottom: 20px;
  
}

.title {
  color: #000;
font-family: Assistant;
font-size: 20px;
font-style: normal;
font-weight: 600;
line-height: normal;
margin-bottom: 12px;
margin-top: 16px;
}
.item-row {
  & :hover {
   background-color: #F0FAFF;;
  }
}
.beneficiary-container {
  padding: 0px 14px 0px 12px;
border-radius: 20px;
border-radius: 29px;
border: 1px solid #EBEBEB;
background: #FFF;
/* Light dark shadow */
box-shadow: 0px 4px 24px 0px rgba(177, 177, 177, 0.13);
width: 100%;
height: 62px;  

    .name {
      color: var(--Main-Blue, #0E1F81);
font-family: Assistant;
font-size: 14px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-bottom: 5px;
    }

  

    .info {
      color: #000;
font-family: Assistant;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
   span {
    margin-top: 5px;
    display: block;
   }
    }
}
.btn-primary {
      width: 60%;
      height: 45px;
      // padding: ;
    }
.circle {
  background: #0E1F81;
  border-radius: 35px;
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  margin-left: 0px;

  strong {
    color: white;
    font-weight: 600;
    height: 25px;
    font-size: 18px;
  }
}

.rotate {
  transform: rotate(180deg);
}

.creation-btn {
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 0 -20px;
}

.shadow {
  box-shadow: 0px 5px 9px 0px rgba(0, 0, 0, 0.75);

}

[dir='rtl'] .beneficiary-container .circle {
  margin-right: 0px;
  margin-left: 12px;
}

@media only screen and (min-width: $screen-tablet) {
  .title {
   font-size: 20px;
}
.beneficiary-container { 
  height: 65px; 
}
.shadow {
  box-shadow: 0px -4px 20px -16px rgba(0, 0, 0, 0.75);;

}


.beneficiary-list {
  max-height: 62vh;
}
.creation-btn {
    padding-top: 20px;
    padding-bottom: 0px;
    // margin: 0 -20px;
    // box-shadow: -1px 0px 7px 0px rgba(0,0,0,0.75);

}
}
</style>

<template>
  <div class="mygmt2-page-wrapper">
    <div class="mygmt2-page-wrapper-container">
      <div class="mygmt2-page-wrapper-container-center">
        <div class="mygmt2-page-wrapper-container-desktop">
          <Header class="header-grid-box grid-fix" />
          <div class="payment-balances-card-grid-box" v-if="this.registrationData.isCustomerHaveCards">
            <h1 class="my-card-title payment-balances-card-grid-box" v-if="!isMobileDevice">
              {{ $ml.get('myCard') }}
            </h1>
            <div>
              <div :class="{ 'mygmt2-line between': !isMobileDevice }">
                <div class="cards-page-desktop-flex">
                  <PaymentBalancesCard :asList="!isMobileDevice" :filterBy="[{status:'ACTIVATED'}]" hideWallets class="payment-balances-card-desktop"
                    @loadTransactions="loadTransactions" />
                  <div v-if="!isMobileDevice" class="custom-select month-select">
                    <select class="form-control mygmt2-select" id="selectedMonth" v-model="selectedMonth"
                      @change="loadTransactions">
                      <option v-for="(month) in this.months" :key="month.text" :value="month">
                        {{ month.textShort }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="transaction-list-filter-container">
                  <div class="transaction-list-flex transaction-list-filter-wrapper">
                    <div class="transaction-list-flex">
                      <img src="../assets/mygmt_v2_images/Search.svg" alt="search icon" />
                      <input class="transaction-list-placeholder" :placeholder="$ml.get('searchTransactions')"
                        v-model="searchTransactions" />
                    </div>
                  </div>
                  <div class="transaction-list-carousel" v-if="isMobileDevice">
                    <section class="splide" aria-label="month picker" id="monthPicker">
                      <div class="splide__track">
                        <ul class="splide__list">
                          <li class="splide__slide slide-month" v-for="(month) in this.monthsReversed"
                            :key="month.text">
                            <span><strong>{{ month.textShort }}</strong></span>
                          </li>
                        </ul>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
              <div class="flex-actions" v-if="!isMobileDevice">
                <div class="balance-block">
                  <h4 class="card-balance-title">{{ $ml.get('cardBalance') }}</h4>
                  <h2 class="card-balance" v-if="getSelectedCard">
                    <span v-formatDecimal="getSelectedCardBalance()"></span> {{
            iso3ToSymbol(getSelectedCard.currency) }}
                  </h2>
                </div>
                <CardActions :card="getSelectedCard" />
              </div>
              <div>
                <div v-show="this.loading" class="mygmt2-loader">
                  <img src="../assets/mygmt_v2_images/mygmt2-loader.gif" />
                </div>
                <TransactionList class="transaction-list-grid-box grid-fix"
                  :transactionsList="JSON.stringify(filteredTransactions)" isCardTransactions v-show="!this.loading" />
              </div>
            </div>
          </div>
          <div class="payment-balances-card-grid-box " v-else>
            <CardMarketing />
          </div>
        </div>
        <Footer v-if="!isMobileDevice" />
        <!-- modals -->
        <!-- modal BLOCK CARD -->
        <div v-if="getSelectedCard">
          <div class="modal-mask modal-mask-card" id="blockCardModal" @click="closeModal()">
            <div class="modal-container modal-message" id="modal-mask-block" @click.stop>
              <span @click="closeModal" class="close-modal" :class="{ 'close-modal-rtl': getDir === 'rtl' }">✕</span>
              <div class="modal-body">
                <div v-if="!dialogueDone" class="title body-title">
                  <i class="icon icon-card-block-fab"></i>
                  <strong>
                    <p class="header">{{
            $ml.with('VueJS').get('cardBlock') + ' ' + getSelectedCard ? getSelectedCard.last4digits : ''
          }}</p>
                    <p v-text="$ml.with('VueJS').get('cardBlockModalMessage.1')"></p>
                    <p v-text="$ml.with('VueJS').get('cardBlockModalMessage.2')"></p>
                  </strong>
                  <p v-text="$ml.with('VueJS').get('cardBlockModalMessage.3')"></p>
                </div>
                <div v-else class="title body-title">
                  <strong>
                    <p><img src="../assets/images/gifs/card_lock.gif" alt=""></p>
                    <p>{{ $ml.with('VueJS').get('cardBlockConfirm.1') }} </p>
                  </strong>
                  <p>{{ $ml.with('VueJS').get('cardBlockConfirm.2') }}
                    <a :href="hrefSrc()" target="_blank" style="color:#d4c641;text-decoration: underline;">{{
            $ml.with('VueJS').get('cardBlockConfirm.3')
          }}</a>
                  </p>
                </div>
                <div class="form-group group-btn">
                  <div v-if="!data_loaded" class="no-actions">
                    <p><img src="../assets/images/loading_w.gif" /></p>
                  </div>
                  <div v-else>
                    <button v-if="!dialogueDone" class="btn btn-small btn-border"
                      v-text="$ml.with('VueJS').get('btnReturn')" @click="closeModal()"></button>
                    <button type="submit" class="btn btn-small btn-yellow"
                      v-text="$ml.with('VueJS').get(dialogueDone ? 'btnOk' : 'cardBlockBtn')"
                      @click=" dialogueDone ? closeModal() : blockUnblock()">
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end modal BLOCK CARD -->

          <!-- modal UNBLOCK CARD -->
          <div class="modal-mask modal-mask-card" id="unBlockCardModal" @click="closeModal()">
            <div class="modal-container modal-message" id="modal-mask-block" @click.stop>
              <span @click="closeModal" class="close-modal" :class="{ 'close-modal-rtl': getDir === 'rtl' }">✕</span>
              <div class="modal-body">
                <div v-if="!dialogueDone" class="title body-title">
                  <i class="icon icon-card-block-fab"></i>
                  <strong>
                    <p class="header">{{
            $ml.with('VueJS').get('cardUnBlock') + ' ' + getSelectedCard ? getSelectedCard.last4digits : ''
          }}</p>
                    <p v-text="$ml.with('VueJS').get('cardUnBlockModalMessage.1')"></p>
                  </strong>
                  <p v-text="$ml.with('VueJS').get('cardUnBlockModalMessage.2')"></p>
                </div>
                <div v-else class="title body-title">
                  <strong>
                    <p><img src="../assets/images/gifs/card_unlock.gif" alt=""></p>
                    <p>{{ $ml.with('VueJS').get('cardUnBlockConfirm') }} </p>
                  </strong>
                </div>
                <div class="form-group group-btn">
                  <div v-if="!data_loaded" class="no-actions">
                    <p><img src="../assets/images/loading_w.gif" /></p>
                  </div>
                  <div v-else>
                    <button v-if="!dialogueDone" class="btn btn-small btn-border"
                      v-text="$ml.with('VueJS').get('btnReturn')" @click="closeModal()"></button>
                    <button type="submit" class="btn btn-small btn-yellow"
                      v-text="$ml.with('VueJS').get(dialogueDone ? 'btnOk' : 'cardUnBlockBtn')"
                      @click=" dialogueDone ? closeModal() : blockUnblock()">
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end modal UNBLOCK CARD -->

          <!-- modal PIN CARD -->
          <div class="modal-mask modal-mask-card" id="pinModal" @click="closeModal()">
            <div class="modal-container modal-message" id="modal-mask-pin" @click.stop>
              <span @click="closeModal" class="close-modal" :class="{ 'close-modal-rtl': getDir === 'rtl' }">✕</span>
              <div class="modal-body">
                <div v-if="!dialogueDone" class="title">
                  <i class="icon icon-card-pass-fab"></i>
                  <span
                    v-text="$ml.with('digits', getSelectedCard ? getSelectedCard.last4digits : '').get('pinCodeModalMessage')"></span>
                  ?
                </div>
                <div v-else class="title">
                  <p><img src="../assets/images/sms_once2.gif" alt=""></p>
                  <span v-text="$ml.with('VueJS').get('pinCodeModalMessage2')"></span>
                </div>
                <div class="form-group group-btn">
                  <div v-if="!data_loaded" class="no-actions">
                    <p><img src="../assets/images/loading_w.gif" /></p>
                  </div>
                  <div v-else>
                    <button v-if="!dialogueDone" class="btn btn-small btn-border"
                      v-text="$ml.with('VueJS').get('btnReturn')" @click="closeModal()"></button>
                    <button type="submit" class="btn btn-small btn-yellow" v-text="$ml.with('VueJS').get('btnOk')"
                      @click="dialogueDone ? closeModal() : getPinCode()">
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end modal PIN CARD -->
          <!-- end modals -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>


import Header from "../components/Header.vue";
import Footer from "@/components/Footer.vue";
import TransactionList from "@/components/TransactionsList.vue";
import PaymentBalancesCard from "@/components/PaymentBalancesCard.vue";
import { getCurrencySymbol, getPreviousMonths, isApp, isMobile, modalHandler } from "@/services/utils";
import { cardBlockUnblock, getCardsAsync, getCardTransactions, getPinCode } from "@/services/api";
import CardActions from "@/components/CardActions.vue";
import moment from "moment";
import CardMarketing from "@/components/CardMarketing.vue";
import { iso3ToSymbol } from "../filters/AppFilters";
import { formatDecimalAndStyle } from "../directives/AppDirectives"

export default {
  name: 'Cards',
  components: {
    CardMarketing,
    CardActions,
    PaymentBalancesCard,
    Header,
    TransactionList,
    Footer,
  },
  data() {
    return {
      isMobileDevice: isMobile(),
      months: getPreviousMonths(),
      monthsReversed: getPreviousMonths().reverse(),
      isApp: isApp(),
      loading: false,
      page: 0,
      transactions: [],
      noTransactions: false,
      newTransactions: [],
      selectedMonth: {},
      selectedCard: this.getSelectedCard,
      cards: this.getCardsList,
      registrationData: JSON.parse(localStorage.getItem('registrationData')),
      searchTransactions: '',
      status: '',
      balance: '',
      page_loaded: false,
      data_loaded: true,
      dialogueDone: false
    }
  },
  directives: {
    'formatDecimal': formatDecimalAndStyle
  },
  computed: {
    getDir() {
      return this.$store.getters.getDir;
    },
    getSelectedCard() {
      return this.$store.getters.getSelectedCard
    },
    getCardsList() {
      return this.$store.getters.getCardsList
    },

    getLang() {
      return this.$store.getters.getLang;
    },
    getSearchTerm(){
     return this.searchTransactions.toLowerCase() 
    },
    filteredTransactions() {
      return this.transactions
        .filter(item => {
         const valuesToSearch = Object.values(_.pick(item, ['TranModeDesc','TranTypeDesc', 'MerchantName', 'MerchantNameEng','City','ChargeAmount','CountryName'])).map(str => {
         return  str ? str.toLowerCase() : ''});
         return valuesToSearch.some((value)=> value.includes(this.getSearchTerm))})
    },
    getSymbole() {
      return getCurrencySymbol(this.getSelectedCard ? this.getSelectedCard.currency : '');
    },
    blockedPermanently() {
      return this.getSelectedCard.blockCode && this.getSelectedCard.blockCode === 'BLOCKED_PERMANENTLY'
    },
    blocked(){
    return this.getSelectedCard && this.getSelectedCard.blockCode === 'BLOCKED'
    },
    showCardLoad() {
      return localStorage.getItem('showCardLoad') === 'true'
    },
    hasWallet() {
      return this.$store.getters.hasWallet('ILS');
    },

  },
  created() {
    window.scrollTo(0, 0);
    this.$store.commit('setTransferStep1', {});
    this.selectedMonth = this.months[0];
  },
  async mounted() {
    document.body.setAttribute('dir', this.getDir);
    let currentMonthIndex = 0;
    // if (this.isMobileDevice && !this.getSelectedCard == undefined) {
    if (this.isMobileDevice) {
      currentMonthIndex = this.monthsReversed.length - 1;
      let splideOptions = {
        isNavigation: true,
        direction: this.getLang === 'il' ? 'rtl' : 'ltr',
        arrows: false,
        pagination: false,
        type: 'slide',
        focus: 'center',
        perPage: 4,
        trimSpace: false,
        start: currentMonthIndex,
        classes: {
          pagination: 'splide__pagination carousel-slide-pagination',
          page: 'splide__pagination__page carousel-slide-pagination-page',
        },
      };
      var splide = new Splide('#monthPicker', splideOptions);
      let self = this;
      splide.on('moved', function (e) {
        self.selectedMonth = self.monthsReversed[e]
        self.loadTransactions()
      });
      this.selectedMonth = this.monthsReversed[currentMonthIndex]
      splide.mount();
    } else {
      this.selectedMonth = this.months[currentMonthIndex]
    }
    this.loadTransactions()
  },
  methods: {
    getSelectedCardBalance() {
      let selectedCard = this.getSelectedCard
      if (selectedCard !== undefined && selectedCard.status === 'ACTIVATED' && selectedCard.balance) {
        return selectedCard.balance
      } else {
        return 0
      }
    },
    loadTransactions() {
      this.loading = true
      if (!this.getSelectedCard || !this.getSelectedCard.cid) {
      this.loading = false
      return
      }
      let requestData = {
        month: this.selectedMonth.month,
        year: this.selectedMonth.year,
        cid: this.getSelectedCard.cid,
      }
      this.transactions = [];
      getCardTransactions(requestData, (data) => {
        if (data) {
          this.transactions = data.filter(action => {
            return this.isApprovedOrSameMonth(action)
          });
        }
        this.noTransactions = (this.transactions.length == 0)
        this.loading = false
      });
    },
    iso3ToSymbol,
    isApprovedOrSameMonth(action) {
      if (action.TransStatus == 1)
        return moment(action.ApproveDateTime, 'YYYY-MM-DD').isSame(moment().set({
          'year': this.selectedMonth.year,
          'month': this.selectedMonth.month - 1
        }), 'month');
      return true;
    },
    imgSrc(curr) {
      return require(`../assets/images/gmt-card_${curr}.png`)
    },
    hrefSrc() {
      return `https://gmtonline.co.il/${this.$store.state.locale.lang}/contact-us/`
    },
    closeModal() {
      modalHandler('.add_action', 'hide', 'ffffff', 'dark')
      modalHandler('.modal-mask', 'hide', 'ffffff', 'dark')
      this.dialogueDone = false;
    },
    goToCardDeposit() {
      this.$router.push('/wallet/transfer/card')
      localStorage.setItem('maxCardNumber', this.getSelectedCard.cid);
    },
    blockUnblock() {
      this.data_loaded = false;
      cardBlockUnblock({ "cid": this.getSelectedCard.cid, "blockCode": this.blocked ? '0' : 'T' }, (response) => {
        this.getSelectedCard.blockCode = response
        const cards = this.getCardsList
        const card = cards.find((card)=> card.cid === this.getSelectedCard.cid)
        card.blockCode = response
        this.data_loaded = true;
        this.dialogueDone = true;
        this.$analyticsService.transmitSwitchCase('card_block','card_block_stage_2',  { errorCode: response.errorCode}) 
      });
    },
    getPinCode() {
      this.data_loaded = false;
      getPinCode({ "cid": this.getSelectedCard.cid }, (response) => {
        this.dialogueDone = this.data_loaded = true;
        this.$analyticsService.transmitSwitchCase('card_pin_code', 'card_pincode_stage_2',  { errorCode: response.errorCode})
      });
    },
    cardBlocked(card) {
      if (card.blockCode.name == 'BLOCKED' || card.blockCode.name == 'BLOCKED_PERMANENTLY')
        return this.$ml.with('VueJS').get('blocked')
    }
  },
  watch: {
    transactions: {
      immediate: true,
      deep: true,
      handler: function (val) {
        this.transactions = val;
        this.noTransactions = this.transactions.length === 0
      }
    }
  }
}

</script>

<style lang="scss" scoped>
@import "../assets/styles/partials/variables";



li {
  width: fit-content !important;
  padding: 0 10px !important;
}

.slide-content {
  width: auto;
  height: auto;
  min-height: 100px;

}

.header-grid-box {
  padding-top: 25px;
}

.balance-block {
  display: flex;
  flex-direction: column;
  border-inline-end: 1px solid #D9D9D9;

  .card-balance-title {
    color: black;
    margin-inline-end: 10px;
  }

  .card-balance {
    color: black;
    margin-inline-end: 10px;
  }
}

.mygmt2-page-wrapper-container {
  background-image: none;
}

.transaction-list-carousel {
  padding-top: 25px;
}

.flex-actions {
  display: flex;
  height: 100px;
  padding: 10px;
  background-color: white;
  margin: 10px 0;
  border-radius: 20px;
  align-items: center;
}

.cards-page-desktop-flex {
  display: block;
}

.transaction-list-filter-container {
  padding-top: 15px;
  margin-top: 20px;

  h6 {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 20px;
  }

  .transaction-list-filter-wrapper {
    background-color: white;
    border-radius: 20px;
    width: 90%;
    margin: auto;
    padding: 5px;
    box-shadow: 0 4px 24px 0 #B1B1B121;
    border: 1px solid #EBEBEB;
    height: 55px;
  }

  .transaction-list-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px;

    img {
      margin-inline-end: 5px;
    }
  }

  .btn-flex {
    width: 50px;
    height: 38px;
    padding: 0 10px;
    justify-content: flex-end;
    background: linear-gradient(244.68deg, #137FE2 -2.13%, #2713A0 147.06%);

    span {
      font-size: 14px;
      padding-inline-end: 5px;
    }
  }

  .transaction-list-placeholder {
    border: none;
    color: black;
    font-weight: 400;
    font-size: 14px;
    opacity: 0.6;
    padding: 5px;
  }
}

.my-card-title {
  color: black;
}


@media only screen and (min-width: $screen-tablet) {
  .mygmt2-header-wrapper {
    padding: 0;
  }

  .cards-page-desktop-flex {
    display: flex;
    margin-block: 10px;
  }

  .payment-balances-card-grid-box {
    grid-area: 2 / 1 / 6 / 14;
    overflow-y: visible;
    overflow-x: clip;
    border-top: none;
  }

  .transaction-list-grid-box {
    grid-area: 6 / 1 / 13 / 14;
  }

  .transaction-list-filter-container {
    padding-top: 0;

    .transaction-list-filter-wrapper {
      width: 600px;
      padding: 0 15px;
      margin: 0;
      height: 55px;
    }
  }

  .month-select {
    margin-inline-start: 10px;
  }
}
</style>
<template>
  <transition name="custom-classes" :enter-active-class="getEntranceAnimation()"
    :leave-active-class="getLeaveAnimation()">
    <div class="menu-wrapper justify-between" :class="{ 'menu-right': getDir !== 'ltr' }" v-show="this.show" id="menu">
      <div class="flex flex-column">

        <div class="mygmt2-line menu-header">
          <div class="close-menu" @click="closeMenu()">
            <img src="../assets/mygmt_v2_images/Right-Chevron.svg" alt="Right Chevron" class="chevron-flaticon"
              :class="{ 'mirror': getDir === 'ltr' }" />
          </div>
          <div class="menu-header-actions">
            <span class="mygmt2-flex-center lang" @click="selectLangMenu()">
              <img src="../assets/mygmt_v2_images/Globus-Transparent.svg" alt="Globus" class="globe" />
              <span>{{ $store.getters.getLangTitle(getLang) }}</span>
            </span>
            <span><img class="icon acsbi" data-acsb="trigger" src="../assets/mygmt_v2_images/Acsbi.svg" /></span>
          </div>
        </div>
        <div class="mygmt2-line mygmt2-header-logo">
          <img class="pointer" src="../assets/mygmt_v2_images/myGMT-logo-V2.svg" alt="myGMT logo =)"
            @click="goHome()" />
        </div>
        <div class="mygmt2-flex-center profile-section">
          <img v-if="this.registrationData.gender === 'MALE'" src="../assets/mygmt_v2_images/profile_img_male.svg"
            alt="" class="im-user pointer" :class="{ 'mirror': getDir === 'rtl' }" @click="goUserProfile()" />
          <img v-else src="../assets/mygmt_v2_images/profile_img_female.svg" alt="" class="im-user pointer"
            @click="goUserProfile()" :class="{ 'mirror': getDir === 'rtl' }" />
          <sub class="greeting-line">
            <img :src="getTimeOfDaySvg()" alt="timeOfDayIcon" />
            {{ $ml.get(`greeting_${getTimeOfDayString()}`) }}
          </sub>
          <span class="user-name-line"><strong>{{ getUserFullName(getLang) }}</strong></span>
        </div>
        <div class="mygmt2-flex-center">
          <button class="btn btn-primary send-money-btn-menu" @click="goToTransfer()">
            {{ $ml.get('btnTransfer') }}
          </button>
        </div>
        <div id="defaultActions" :class="{ 'mobile-actions': isMobileDevice }" class="actions-container">
          <ActionLine :hideArrow="!isMobileDevice" :text="$ml.get('home')"
            :icon="isCurrent('Home') ? 'Home-Highlighted.svg' : 'Home.svg'"
            @lineAction="$router.push({ name: 'Home' })" />
          <ActionLine :hideArrow="!isMobileDevice" :text="$ml.get('activity')"
            :icon="isCurrent('TransactionsHistory') ? 'Menu-Document-Highlighted.svg' : 'Menu-Document.svg'"
            @lineAction="$router.push({ name: 'TransactionsHistory' })" />
          <ActionLine :hideArrow="!isMobileDevice" :text="$ml.get('cards')"
            :icon="isCurrent('Cards') ? 'Cards-Highlighted.svg' : 'Cards.svg'"
            @lineAction="$router.push({ name: 'Cards' })" />
          <ActionLine :hideArrow="!isMobileDevice" :text="$ml.get('itemNav4')"
            :icon="isCurrent('beneficiaries') ? 'Menu-Group-Highlighted.svg' : 'Menu-Group.svg'"
            @lineAction="$router.push({ name: 'beneficiaries' })" />
          <ActionLine :hideArrow="!isMobileDevice" :text="$ml.get('cashBackReceive')"
            :icon="isCurrent('Cashback') ? 'Menu-Present-Highlighted.svg' : 'Menu-Present.svg'"
            @lineAction="$router.push({ name: 'wallet-invite' })" />
          <ActionLine :hideArrow="!isMobileDevice" :text="$ml.get('editUserProfile')"
            :icon="isCurrent('user-profile') ? 'Edit-Highlighted.svg' : 'Edit.svg'"
            @lineAction="$router.push({ name: 'user-profile' })" />
          <ActionLine :hideArrow="!isMobileDevice" :text="$ml.get('itemNav9')" icon="Logout.svg" @lineAction="logOut()"
            v-if="!isMobileDevice" />
          <ActionLine :hideArrow="!isMobileDevice" :text="$ml.get('itemNav5')" icon="Locations.svg"
            @lineAction="goToGmtLocationsPage()" v-if="isMobileDevice">
          </ActionLine>
        </div>
        <div id="languageSelectActions" class="actions-container" style="display: none">
          <h2>{{ $ml.get('changeLang') }}</h2>
          <div v-for="(lang, index) in $store.state.locales" :key="index" class="language-rows">
            <ActionLine :hideArrow="isMobileDevice" :active="getLang === index"
              :text="$store.getters.getLangTitle(index)" @lineAction="$ml.change(index), changeLang(index)" />
          </div>
        </div>
      </div>
      <div class="social-container">
        <div class="mygmt2-line social-line">
          <div class="flex flex-wrap justify-center">
            <div class="flex just-center align-center">
              <img class="pointer" src="../assets/mygmt_v2_images/Facebook.svg" alt="Facebook Logo"
                @click="openFacebook()" />
              <img class="pointer" src="../assets/mygmt_v2_images/Instagram.svg" alt="Instagram Logo"
                @click="openInstagram()" />
            </div>
            <div class="flex just-center align-center">
              <img class="pointer" src="../assets/mygmt_v2_images/Twitter.svg" alt="Twitter Logo"
                @click="openTwitter()" />
              <img class="pointer" src="../assets/mygmt_v2_images/Youtube.svg" alt="Youtube Logo"
                @click="openYoutube()" />
            </div>

          </div>

          <img class="pointer" src="../assets/mygmt_v2_images/Linkedin.svg" alt="Linkedin Logo" v-if="isMobileDevice"
            @click="openLinkedIn()" />
          <img class="pointer" src="../assets/mygmt_v2_images/Globus.svg" alt="Globus" v-if="isMobileDevice"
            @click="openGmtonline()" />
        </div>
        <div class="mygmt2-line social-line" v-if="!isMobileDevice">
          <img class="pointer" src="../assets/mygmt_v2_images/Globus.svg" alt="Globus" @click="openGmtonline()" />
          <img class="pointer" src="../assets/mygmt_v2_images/Linkedin.svg" alt="Linkedin Logo"
            @click="openLinkedIn()" />
        </div>
        <div class="mygmt2-flex-center social-footer">
          <span v-if="isMobileDevice" @click="logOut()">{{ $ml.get('itemNav9') }}</span>
          <span>
            <a v-bind:href="'https://gmtonline.co.il/' + $ml.with('VueJS').get('protocolLink')" style="display:inline"
              target="_blank">{{ $ml.get('itemNav8') }}</a>
            &
            <a v-bind:href="'https://gmtonline.co.il/' + $ml.with('VueJS').get('privacyLink')" style="display:inline"
              target="_blank">{{ $ml.with('VueJS').get('privacyPolicy') }}</a>
          </span>
          <span>V{{ version() }}</span>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import { EventBus } from '@/event-bus';
import { getTimeOfDayString, getUserFullName, isApp, isMobile } from "@/services/utils";
import ActionLine from "@/components/ActionLine.vue";
import { logOut } from "@/services/auth";
import store from "@/store";
import { updateLang } from "@/services/api";

export default {
  name: 'Menu',
  components: { ActionLine },
  props: {},
  data() {
    return {
      isApp: isApp(),
      show: false,
      isMobileDevice: isMobile(),
      registrationData: JSON.parse(localStorage.getItem('registrationData')),
      dataLang: {
        'lang': this.$store.getters.getLang,
        'dir': this.$store.getters.getDir
      },
    }
  },
  created() {

  },
  computed: {
    getLang() {
      return this.$store.getters.getLang;
    },
    getDir() {
      return this.$store.getters.getDir;
    },
  },
  mounted() {
  },
  methods: {
    logOut,
    getUserFullName,
    getTimeOfDayString,
    isCurrent(icon) {
      if (this.$router.currentRoute.name === icon) {
        return true;
      }
    },
    getTimeOfDaySvg() {
      switch (this.getTimeOfDayString()) {
        case ("morning"):
          return require(`../assets/mygmt_v2_images/Sunset.svg`)
        case ("afternoon"):
          return require(`../assets/mygmt_v2_images/Sunny.svg`)
        case ("evening"):
          return require(`../assets/mygmt_v2_images/Cloudy.svg`)
        case ("night"):
          return require(`../assets/mygmt_v2_images/Night.svg`)
      }
    },
    openMenu() {
      this.show = true;
      $('body').addClass('stop-scrolling')
    },
    closeMenu() {
      $("#defaultActions").show();
      $("#languageSelectActions").hide();
      $(".im-user").css('visibility', 'visible');
      $(".greeting-line").css('visibility', 'visible');
      $(".user-name-line").css('visibility', 'visible');
      $('body').removeClass('stop-scrolling')
      this.show = false;
    },
    goUserProfile() {
      this.$router.push('user-profile');
    },

    getEntranceAnimation() {
      if (this.getDir === 'rtl') {
        return 'animate__animated animate__slideInRight'
      } else {
        return 'animate__animated animate__slideInLeft'
      }
    },
    getLeaveAnimation() {
      if (this.getDir === 'rtl') {
        return 'animate__animated animate__slideOutRight'
      } else {
        return 'animate__animated animate__slideOutLeft'
      }
    },
    version() {
      return process.env.VUE_APP_VERSION;
    },
    selectLangMenu() {
      $("#defaultActions").hide();
      $(".im-user").css('visibility', 'hidden');
      $(".greeting-line").css('visibility', 'hidden');
      $(".user-name-line").css('visibility', 'hidden');
      $("#languageSelectActions").show();
    },
    openFacebook() {
      window.open('https://www.facebook.com/GMT.Advanced.Financial.Services');
    },
    openInstagram() {
      window.open('https://instagram.com/gmtonline');
    },
    openTwitter() {
      window.open('https://twitter.com/GMT_afs');
    },
    openYoutube() {
      window.open('https://www.youtube.com/channel/UCXLm3ms-nANy1ixpYLiWbaw?view_as=subscriber');
    },
    openLinkedIn() {
      window.open('https://www.linkedin.com/company/gmt-advanced-financial-services/');
    },
    openGmtonline() {
      window.open('https://gmtonline.co.il/');
    },
    goToGmtLocationsPage() {
      window.open('https://gmtonline.co.il/' + this.$ml.get('locationLink'), '_blank')
    },
    goHome() {
      this.$router.push({ name: localStorage.getItem('homePage') || 'wallet-main' })
    }, 
     goToTransfer() {
        this.$router.push('/transfernew')
    },
    changeLang(lang) {
      $("#defaultActions").show();
      $("#languageSelectActions").hide();
      this.dataLang.lang = lang;
      store.commit('changeLang', this.dataLang);
      store.commit('setLangTitle', lang);
      if (store.getters.getLoggedIn)
        updateLang({ "lang": lang });
      EventBus.$emit('changeLang', lang);
      this.$forceUpdate();
      $(".im-user").css('visibility', 'visible');
      $(".greeting-line").css('visibility', 'visible');
      $(".user-name-line").css('visibility', 'visible');
    },
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/partials/variables";

.language-rows {
  border-bottom: 1px solid #F5F5F5;
}

.mobile-actions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.icon-location-fill {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

#languageSelectActions {
  h2 {
    color: black;
    padding: 15px 7.5px;
    font-weight: 600;
    font-size: 20px;
  }
}

.menu-wrapper {
  width: 100%;
  height: 100%;
  //padding: 15px;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1100;
  // z index should be highest
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.menu-right {
  right: 0;
  left: auto;
}

.menu-header {
  justify-content: space-between;
  flex-direction: row;
  padding: 20px 15px 0 15px;

  .close-menu {
    display: flex;
  }

  .menu-header-actions {
    display: flex;
    align-items: center;
    color: black;

    .globe {
      margin: 2px;
      margin-inline-end: 5px;
    }

    .acsbi {
      margin-inline-start: 20px;
    }
  }
}

.profile-section {
  img {
    width: 70px;
    margin: auto;
  }

  flex-direction: column;
  padding-top: 20px;
  align-items: center;
  color: black;

  .greeting-line {
    padding-top: 11px;
    font-weight: 300;
    font-size: 14px;
    opacity: .8;
    display: flex;

    img {
      margin-inline-end: 5px;
      width: 20px;
      height: 20px;
    }
  }

  .user-name-line {
    strong {
      font-weight: 600;
      font-size: 20px;
    }
  }

  &:before {
    background: url("../assets/mygmt_v2_images/Mygmt-Background-Menu.svg") no-repeat;
    background-size: contain;
    content: 'hidden';
    color: transparent;
    display: block;
    z-index: -2;
    width: 100vw;
    height: 300px;
    position: absolute;
    top: 0;
    max-height: 300px;
  }


}

.actions-container {
  padding: 16px;
  height: 450px;
}

.social-container {
  color: black;
  padding-top: 10px;
  position: relative;

  .social-line {
    display: flex;
    flex-wrap: wrap;

    img {
      margin: 10px 1.8vh;
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../assets/mygmt_v2_images/Menu-Footer-Background.svg") no-repeat;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    z-index: -1;
  }

  .social-footer {
    flex-direction: column;
    font-size: 14px;
    font-weight: 300;
    opacity: .6;
    align-items: center;
    max-height: 94px;
    position: relative;

    span {
      text-align: center;
      padding: 7.5px 0;

      a {
        color: black;
        text-decoration: underline;
      }
    }

  }
}

.mygmt2-header-logo {
  display: none;
}

.send-money-btn-menu {
  display: none;
}




@media only screen and (min-width: $screen-tablet) {
  .menu-header {
    display: none;
  }

  #menu {
    display: flex !important;
    flex-direction: column;
  }

  .menu-wrapper {
    border-inline-end: 3px solid #F1F1F1;
    width: 13.6vw;
    padding-top: 35px;
    padding-bottom: 15px;
    height: 100%;
    overflow-y: auto;


  }

  .profile-section {
    padding-top: 51px;

    &:before {
      width: 100%;
      visibility: hidden;
    }
  }



  .social-container {
    padding-top: 0px;
    margin-top: -15px;

    &:after {
      display: none;
    }

    .social-footer {
      &:after {
        display: none;
        width: 100%;
      }
    }
  }

  .mygmt2-header-logo {
    display: flex;
  }

  .send-money-btn-menu {
    display: block;
    margin-top: 24px;
    padding: 0 15px;
    font-size: 14px;
    font-weight: 700;
    height: 48px !important;
  }

  .actions-container {
    height: auto;
    max-height: none;
    //overflow-y: scroll;
    padding: 0px 12px;
    margin-bottom: 20px;
  }

  .social-line {

    img {
      width: 35px;
      margin: 10px 10px !important;

    }
  }

  .second-social-line {
    justify-content: center !important;

    img {
      margin: 0 15px;
    }
  }
}
</style>

import {
    auth,
    checkToken,
    feeLookup,
    jwtAuth,
    polledBalances,
    updateLang,
    getOrderDetails,
    registerBiometric,
    biometricLogin,
    unregisterBiometric,
    setExternalIdOnesignal
} from '../services/api'
import router from '../router'
import store from '../store';
import {isApp} from "@/services/utils";
import { AnalyticsService } from './AnalyticsService';

export function login(data, callback) {
    store.commit('resetAllState');
    if (data.jwt) {
        jwtAuth(data, (response) => {
            handleResponse(response, callback)
        });
    } else if (data.biometricSignature) {
        biometricLogin(data, (response) => {
            handleResponse(response, callback)
        });
    } else {
        auth(data, (response) => {
            handleResponse(response, callback)
        });
    }
}

function handleResponse(response, callback) {
    if (!response.data.errorCode) {
        let userData = response.data.result.user;
        localStorage.setItem('token', response.data.result['Auth-Token']);
        localStorage.setItem('timestamp', response.data.result['timestamp']);
        localStorage.setItem('signature', response.data.result['signature']);
        localStorage.setItem('userFirstName', userData.firstName);
        localStorage.setItem('country', userData.country);
        localStorage.setItem('userName', userData.firstName + ' ' + userData.lastName);
        if (userData.firstNameHebrew && userData.lastNameHebrew) {
            localStorage.setItem('userNameHebrew', userData.firstNameHebrew + ' ' + userData.lastNameHebrew);
        }
        localStorage.setItem('phoneNumber', userData.phoneNumber);
        localStorage.setItem('identifier', userData.identifier);
        localStorage.setItem('isApp', isApp())
        localStorage.setItem('registrationData', JSON.stringify(userData));
        localStorage.setItem('lookupWithFeeMin', userData.minFeeLookup);
        localStorage.setItem('showWallet', userData.showWallet);
        localStorage.setItem('showCardLoad', userData.showCardLoad);
        localStorage.setItem('isBeneficiariesListEmpty', userData.isBeneficiariesListEmpty);
        localStorage.setItem('hasWallet', userData.hasWallet);
        localStorage.setItem('isCustomerHaveCards', userData.isCustomerHaveCards);
        localStorage.removeItem('selectedTransferOption');
        localStorage.removeItem('selectedBeneficiary');
        localStorage.removeItem('customerFields');
        localStorage.removeItem('beneficiaryFields');
        localStorage.setItem('cashBackMin', response.data.result['cashBackMinimum']);
        AnalyticsService.setUserParams()
        if (localStorage.getItem('langChanged') == 1) {
            updateLang({"lang": store.getters.getLang});
            localStorage.removeItem('langChanged')
        }
        if (userData.status == 'REVIEWED_NOT_SIGNED' || userData.status == 'NEW_NOT_SIGNED') {
            completeRegistrationDetails(userData, false);
            return;
        }
        if (userData.status == 'EXPIRED_DOC' && userData.hasDocuments == 0) {
            completeRegistrationDetails(userData, true);
            return;
        }
        store.commit('setLoggedIn', true);
        store.commit('setNotSigned', false);
        if (isApp()) {
        handleOneSignal()
        }
     
        const fromUnregisteredCalc = (localStorage.getItem('fromUnregisteredCalc') && store.state.transfer.destinationCountry)
        console.log("🚀 ~ handleResponse ~ fromUnregisteredCalc:", localStorage.getItem('fromUnregisteredCalc'))
        console.log("🚀 ~ handleResponse ~ store:", store.state.transfer)
        localStorage.removeItem('fromUnregisteredCalc');
        if (fromUnregisteredCalc) {
            //another lookup for security (saving amounts in server in customer session)
            feeLookup({
                "amount": store.state.transfer.amount,
                "currency": store.state.transfer.currency,
                "destinationCountry": store.state.transfer.destinationCountry,
                'amountType': store.getters.getAmountType
            }, (response) => {
                if (!response.errorCode)
                    store.commit('setTransferStep1Response', response.result);
                router.push('/transfernew');
            });
        } else {
            if (store.getters.getWalletFriendLink) {
                router.push(store.getters.getWalletFriendLink);
                store.commit('setWalletFriendLink', null);
            } else {
                getOrderDetails(res => {
                    store.commit('setOrderData', res.result)
                    
                })
                if (router.history.current.name == 'biometricRegistration') {
                    let keyPair = rsa.pki.rsa.generateKeyPair()
                    gonative.auth.save({
                        'secret': [rsa.pki.privateKeyToPem(keyPair.privateKey), localStorage.getItem('identifier')],
                        'prompt': 'PROMPT',
                        'callbackFunction': () => {

                            let pubKeyStr = rsa.pki.publicKeyToPem(keyPair.publicKey)
                                .replaceAll('\r\n', "")
                                .replaceAll("-----BEGIN PUBLIC KEY-----", "")
                                .replaceAll("-----END PUBLIC KEY-----", "");

                            registerBiometric({'publicKey': pubKeyStr, 'preventLog': true}, (response) => {
                                if (response.success) {
                                    gonative.auth.status({
                                        'callbackFunction': (res) => {
                                            store.commit('setBiometricData', {...res})
                                            pollBalancesAndRedirect()
                                        }
                                    });
                                } else {
                                    pollBalancesAndRedirect()
                                }
                            })
                        }
                    })
                } else {
                    pollBalancesAndRedirect()
                }
            }
        }
    } else callback(response);

    //activate(code, data);
}

async function handleOneSignal() {

    try {
        const oneSignalInfo = await gonative.onesignal.onesignalInfo()
        if (oneSignalInfo && oneSignalInfo.oneSignalUserId) {
            store.commit('setSubscriptionId', oneSignalInfo.oneSignalUserId)
            localStorage.setItem('setSubscriptionId', oneSignalInfo.oneSignalUserId)
            setExternalIdOnesignal()
        }
    } catch (error) {
        console.log("error:", error)

    }
}

export function logOut() {
    localStorage.removeItem('token');
    localStorage.removeItem('userName');
    localStorage.removeItem('registrationData');
    localStorage.removeItem('selectedTransferOption');
    localStorage.removeItem('selectedBeneficiary');
    localStorage.removeItem('customerFields');
    localStorage.removeItem('beneficiaryFields');
    localStorage.removeItem('fromUnregisteredCalc');
    localStorage.removeItem('userNameHebrew');
    sessionStorage.removeItem('cashBackBalances');
    store.commit('resetAllState');
    store.commit('setLoggedIn', false);
    store.commit('setNotSigned', false);
    window.location.replace('/#/login')
    window.location.reload()
}


export function loginFailed() {
    store.commit('setLoggedIn', false);

    //user.error = 'Login failed!';
    localStorage.removeItem('token');
}


export function checkAuth(to, from, next) {
    checkToken((response) => {
        if (response == false) {
            store.commit('setLoggedIn', false);
        } else {
            store.commit('setLoggedIn', true);
        }
        next();
    });
}

export function requireAuth(to, from, next) {
    console.log('CHECK TOKEN',router.history.current.name);
    console.log('CHECK TOKEN',router.history.current.name);
    console.log('CHECK TOKEN',router.history.current.name);
    console.log('CHECK TOKEN',router.history.current.name);
    console.log('CHECK TOKEN',router.history.current.name);
    console.log('CHECK TOKEN',router.history.current.name);
    console.log('CHECK TOKEN',router.history.current.name);
    
    checkToken((response) => {
        // response = false
        
        if (response == false) {
            localStorage.removeItem('token');
            localStorage.removeItem('userName');
            localStorage.removeItem('registrationData');
            localStorage.removeItem('selectedTransferOption');
            localStorage.removeItem('selectedBeneficiary');
            localStorage.removeItem('customerFields');
            localStorage.removeItem('beneficiaryFields');
            localStorage.removeItem('fromUnregisteredCalc');

            store.commit('resetAllState');
            store.commit('setLoggedIn', false);
            store.commit('setNotSigned', false);
            store.commit('setErrorCode', 'SESSION_EXPIRED');
            store.commit('setErrorMessage', '');
            if (router.history.current.name === "transfernew") {
                store.commit('setTransferStatus', {main: 'ERROR', subType: 'DISCONNECTED'} );
                
            } else {
                store.commit('setShowModalError', true);
            }
            if (to.name === 'wallet-friends') {
                store.commit('setWalletFriendLink', to.path);
            }
            next({
                path: '/login',
            });
        } else {
            store.commit('setLoggedIn', true);
            next();
        }
    });
}

export function requireDefault(to, from, next) {
    console.log(from)
    if (!store.getters.getLoggedIn) {
        next({
            path: '/login',
        });
    } else {
        next({
            path: '/recent_actions',
        });
    }
}

function completeRegistrationDetails(userData, isOLdCustomer) {
    store.commit('setLoggedIn', false);
    store.commit('setNotSigned', true);
    store.commit('setOldCustomer', isOLdCustomer);
    store.commit('setRegistration', {
        'firstName': userData.firstName,
        'middleName': userData.mname,
        'lastName': userData.lastName,
        'anotherLastName': userData.anotherLName,
        'birthDate': userData.birthDate,
        'gender': userData.gender,
        'occupation': userData.occupation,
        'streetCode': userData.streetCode,
        'address': userData.address,
        'cityCode': userData.cityCode,
        'city': userData.city,
        'zipCode': userData.zipCode,
        'birthCountry': userData.birthCountry,
        'email': userData.email,
        'streetNumber': userData.streetNumber,
        'country': userData.user_country,
        'province': userData.user_province,
        'identification': userData.user_id,
        'mobileNumber': userData.user_phone,
        'language': store.state.locale.lang
    });
    router.push('/registration_steps');
}

function pollBalancesAndRedirect() {
    localStorage.setItem('homePage', 'Home')
    polledBalances(response => {
        store.commit('setCashBackBalances', response.CASHBACK);
        if (response.WALLET && response.WALLET.length > 0) {
            store.commit("setWalletBalancesAreUpdated", true)
            store.commit('setWalletBalances', response.WALLET);
        }
    }, false)
    router.push('/Home');
}
<template>
  <div v-if="show">
    <div class="modal-filter modal-mask-message" style="display: block" @click="customClose ? close() : closeModal()">
      <div class="modal-base " :style="{padding: padding}" id="" @click.stop>
        <div>
          <span class="close-modal" @click="customClose ? close() : closeModal()"
            :class="{ 'close-modal-rtl': getDir === 'rtl' }">✕</span>
          <div class="modal-body body-dialogue">
            <div class="title body-title">
              <slot />
              <!-- <slot /> -->
            </div>

            <!-- <div class="group-btn">
              <button v-if="!noCancelBtn" class="btn btn-small btn-border"
                v-text="cancel ? cancel : $ml.get('btnReturn')" @click="customClose ? close() : closeModal()"></button>
              <button type="submit" class="btn btn-small btn-yellow wide" v-text="this.confirm"
                @click="noAction ? closeModal() : submit()"></button>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { modalHandler } from "@/services/utils";

export default {
  props: {
    padding:{
      type: String,
      default: '10px'
    },
    customClose: {
      type: Boolean
    },
    icon: {
      type: String
    },
    image: {
      type: String
    },
    iconStyle: {
      type: String
    },
    title: {
      type: String
    },
    footer: {
      type: String
    },
    body: {
      type: String
    },
    confirm: {
      type: String
    },
    cancel: {
      type: String
    },
    noAction: {
      type: Boolean
    },
    noCancelBtn: {
      type: Boolean
    }
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    openModal() {
      modalHandler(null, 'show', '092086', 'light')
      this.show = true
    },
    closeModal() {
      modalHandler(null, 'hide', 'ffffff', 'dark')
      this.show = false
    },
    submit() {
      this.$emit('action');
    },
    close() {
      this.$emit('closeAction');
    }
  },
  computed: {
    getDir() {
      return this.$store.getters.getDir;
    },
  }
}

</script>

<style lang="scss" scoped>
.modal-base {
  position: absolute;
  top: 25%;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 9993;
  width: 100%;
  // height: 338px;
  font-family: "Assistant", "Source Sans Pro", sans-serif;
  background-color: white;
  border-radius: 8px;
background: #FFF;
box-shadow: 0px 3px 18px 0px rgba(0, 0, 0, 0.25);
}

.close-modal {
  color: black;
  display: block;
  font-size: 30px;
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
}

.modal-filter {
  background: rgba(111, 111, 111, 0.30);
  backdrop-filter: blur(7.5px);
  overflow-y: auto;
  display: none;
  position: fixed;
  z-index: 99992;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}
</style>
<template>
  <div class="quick-action-box pointer" @click="actionHandler()">
    <img v-if="this.icon" :src="imgSrc()" :alt="getDecsription()" class="icon">
    <div v-else style="visibility: hidden">place holder hackz</div>
    <span>{{ this.text }}</span>
  </div>
</template>
<script>
import { isApp } from "@/services/utils";

export default {
  name: 'QuickAction',
  components: {},
  props: {
    icon: {
      type: String,
      required: false
    },
    text: {
      type: String,
      required: true
    },
    action: {
      type: String,
      required: false
    },
    descripton: {
      type: String,
      required: false
    },
    additionalData: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      isApp: isApp(),
    }
  },
  created() {

  },
  computed: {
    getLang() {
      return this.$store.getters.getLang;
    },
  },
  mounted() {

  },
  methods: {
    imgSrc() {
      return require(`../assets/mygmt_v2_images/${this.icon}`);
    },
    getDecsription() {
      return this.description || 'general';
    },
    actionHandler() {
      switch (this.action) {
        case 'sendMoneyGlobal':
          this.$router.push('/transfernew')
          break;
        case 'sendMoneyHome':
          this.$store.commit('setTransferStep1', { amount: null, destinationCountry: this.additionalData.iso });
          this.$router.push('/transfernew')
          break;
        case 'sendMoneyLocal':
          this.$store.commit('setTransferStep1', { amount: null, currency: 'ILS', destinationCountry: 'ISR' });
          this.$router.push('/transfernew')
          break;
        case 'myGmtPrepaid':
          this.$router.push('/card_main')
          break;
        case 'inviteFriends':
          this.$router.push('/wallet/invite')
          break;
        case 'goToCashio':
          this.$router.push('/cashio')
          break;
        case 'sendToFriends':
          this.$router.push('/wallet/friends')
          break;
      }
    }
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/partials/variables";

.quick-action-box {
  //margin: 5px;
  width: 50%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  padding: 5px;
  transition: all 0.2s;

  img {
    margin-inline: 5px;
    width: 30px;
  }

  span {
    color: black;
    font-size: 12px;
    width: 100px;
  }

  &:hover {
    background: #F0FAFF;
    padding-left: 10px;
    padding-right: 0px;


    span {
      color: #0E1F81;
      font-family: Assistant;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }


}

@media only screen and (min-width: $screen-tablet) {
  .quick-actions-card-container {
    .quick-actions-container {
      .quick-actions-desktop-flex {
        .quick-action-box {
          height: auto;
        }
      }
    }
  }
}
</style>

<template>
    <div class="track" :style="{backgroundColor: trackColor }">
      <div class="fill" :class="{'clear': !progress} " :style="fillStyle"></div>
      <div class="ball" :class="{'clear': !progress, 'ball-rtl': isRtl} " :style="ballStyle"></div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'ProgressBar',
    data() {
        return {
        isShown: false
        }
    },
    methods: {

    },
    props: {
      trackColor: {
        type: String,
        required: true
      },
      fillColor: {
        type: String,
        required: true,
      },
      ballColor: {
        type: String,
        required: true
      },
      progress: {
        type: Number,
        required: true,
        default: 0
      }
    },
    computed: {
      fillStyle() {
        return {
            width: `${this.progress}%`,
            backgroundColor: this.fillColor

        };
      },
      isRtl(){
      return this.$store.getters.getDir === 'rtl'
      },

      ballStyle() {
       const direction = this.isRtl ? 'right' : "left"
        return {
          [direction]: `${this.progress - (this.isRtl ? 1.5 : 0)}%`,
          backgroundColor: this.ballColor
        };
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .track {
    position: relative;
    width: 100%;
    height: 20px;
    border-radius: 10px;
    height: 4px;
  }
  
  .fill {
    height: 100%;
    transition: width 0.3s  ease-in-out , opacity 0.3s ease-in-out
  }

  .clear {
    opacity: 0;
  }
  
  .ball {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px; 
    border-radius: 50%;
    transition: left 0.3s ease-in-out,  opacity 0.3s ease-in-out ;
  }

  .ball-rtl {
    transition: right 0.3s ease-in-out,  opacity 0.3s ease-in-out ;
  }
  </style>
  
<template>
  <div>
    <Menu ref="menu" />
    <div class="footer-container">
      <MenuAction icon="Hamburger.svg" :label="$ml.get('menu')" @onActionClick="openMenu()" />
      <MenuAction :icon="isCurrent('Home') ? 'Home-Highlighted.svg' : 'Home.svg'" :label="$ml.get('home')"
        @onActionClick="$router.push({ name: 'Home' })" />
      <span  class="circle" @click="goToTransfer()">
        <img :class="{'rotate-img': getDir === 'rtl'}" src="../assets/mygmt_v2_images/PaperPlane.svg" />
        <label>{{ $ml.get('btnTransferFooter') }}</label>
      </span>
      <MenuAction :icon="isCurrent('TransactionsHistory') ? 'Menu-Document-Highlighted.svg' : 'Menu-Document.svg'"
        :label="$ml.get('activity')" @onActionClick="$router.push({ name: 'TransactionsHistory' })" />
      <MenuAction :icon="isCurrent('Cards') ? 'Cards-Highlighted.svg' : 'Cards.svg'" :label="$ml.get('cards')"
        @onActionClick="$router.push({ name: 'Cards' })" />
    </div>
    <div v-if="isMobileDevice" class="spacer"></div>
  </div>
</template>
<script>
import { isApp, isMobile } from "@/services/utils";
import MenuAction from "@/components/MenuAction.vue";
import Menu from "@/components/Menu.vue";
import { EventBus } from '@/event-bus';

export default {
  name: 'Footer',
  components: { MenuAction, Menu },
  props: {},
  data() {
    return {
      isApp: isApp(),
      isMobileDevice: isMobile()
    }
  },
  created() {

  },
  computed: {
    getLang() {
      return this.$store.getters.getLang;
    },
    getDir() {
      return this.$store.getters.getDir;
    },
  },
  mounted() {

  },
  methods: {
    openMenu() {
      this.$refs.menu.openMenu();
    },
    isCurrent(icon) {
      if (this.$router.currentRoute.name === icon) {
        return true;
      }
    },
    goToTransfer() {
        // EventBus.$emit('clearTransferState');
        this.$router.push('/transfernew')
    },
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
@import "../assets/styles/partials/variables";


.spacer {
  position: fixed;
  height: 20px;
  width: 100%;
  background-color: white;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.footer-container {
  position: fixed;
  left: 0;
  bottom: 20px;
  width: 100%;
  max-width: 100vw;
  height: 85px;
  color: black;
  border-radius: 20px 20px 0 0;
  display: flex;
  justify-content: space-between;
  background: url("../assets/mygmt_v2_images/Subtract.svg") no-repeat center center;
  background-size: 102%;
  padding: 0 5% 0 5%;
  z-index: 1;
  filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.5));

  .circle {
    background: #0E1F81;
    border-radius: 35px;
    height: 55px;
    width: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 18px 0 #fcfcfc;
    flex-direction: column;
    bottom: 3px;
    position: relative;

    label {
      font-size: 12px;
      white-space: nowrap;
      position: relative;
      top: 20px;
    }

    img {
      margin-inline-end: 4px;
      direction: ltr;
      margin-top: 20px;
    
}
  }
}
.rotate-img {
       direction: rtl !important;
        transform: scaleX(-1);
    }
@media only screen and (min-width: $screen-tablet) {
  .footer-container {
    visibility: hidden;
  }
}
</style>

<template>
    <div draggable="false" class="grey-bgc  main-container relative">
        <img class="kite" src="../../../../assets/mygmt_v2_images/kite-transfer.svg" alt="">
        <Header2 :showCloser="false" @stepBack="handleBackClick"></Header2>
        <div class="transfer-container relative">
            <div class="upper flex flex-column">
            </div>
            <div class="flex flex-column ">
                <div class="first-part flex flex-column  align-center">
                    <img src="../../../../assets/mygmt_v2_images/welcome.gif" alt="">
                    <h2>Welcome to myGMT</h2>
                    <p>With myGMT, you can transfer money globally
                        in a smart, secure, easy, and fast way.</p>
                    <p>To start your journey with us, make a quick registration
                        to myGMT platform.</p>
                    <button @click="setUnregistered('/Registration');" class="btn btn-main w-100">Register to myGMT</button>
                    <button @click="setUnregistered('/login')" class="btn btn-border w-100">I have an account</button>

                </div>
            </div>
            <div v-if="isMobileDevice" class="mobile-part">
                <h3>
                    Get <span>myGMT</span> app from your store:
                </h3>

                <div class="flex justify-center mobile-stores">
                    <a id="iOS" href="https://apps.apple.com/app/mygmt-money-transfer-abroad/id1666510532">
                        <img  src="../../../../assets/mygmt_v2_images/appstore.svg" alt="">
                </a>
          <a id="Android" target="_blank" href="https://play.google.com/store/apps/details?id=il.co.gmtonline.mygmt">
          <img  src="../../../../assets/mygmt_v2_images/googleplay.svg" alt="">
          </a>
          </div>
            </div>
            <p style="font-size: 13px;">
                Our service in encrypted abs secured and we will never share your details. GMT operates under
                license of the Ministry of Finance Extended FInancial Service Provider - License Number 57479
            </p>
        </div>
    </div>
</template>
<script>
import Header2 from "@/components/Header2";
import Footer from "@/components/Footer.vue";
import { isMobile } from "../../../../services/utils";


export default {
    components: { Footer, Header2 },
    data() {
        return {
            isMobileDevice: isMobile(),


        }
    },
    created() {
    },
    mounted() {
        document.body.setAttribute('dir', this.dir);
    },
    methods: {
        handleBackClick() {

        },
        setUnregistered(url){
            localStorage.setItem('fromUnregisteredCalc', true);
            this.$router.push(url);
        }
    },
    computed: {
        dir() {
            return this.$store.getters.getDir;
        },
        isLoggedIn() {
            return this.$store.getters.getLoggedIn && !this.$store.getters.getNotSigned;
        },

        isCalculatorRoute() {

        },

    },

    beforeDestroy() {
    }
}
</script>
<style lang="scss" scoped>
@import "../../../../assets/styles/partials/variables";

.transfer-container {
    min-height: 90vh;
    padding: 0px 20px;
    background: #FFF;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: 100px;
    //box-shadow: 0px 4px 24px 0px rgba(177, 177, 177, 0.13);
    background-image: url('../../../../assets/mygmt_v2_images/mygmt2-background-grey.svg');

    h2 {
        margin-bottom: 12px;
    }

    h3 {

        span {
            font-size: 18px;
            font-weight: 700;
        }

        text-align: center;
        font-size: 18px;
        font-weight: 400;
    }

    p {
        margin-bottom: 20px;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
    }

    img {
        width: 180px;
        height: 180px;
    }

    .upper {
        align-items: center;

        // margin-bottom: 10px;
        .back {
            font-size: 16px;
            margin-top: 25px;
            margin-bottom: 8px;
            color: var(--GMT--Blue, #0E1F81);
        }

        .money-title {
            font-size: 20px;
            font-weight: 700;
            color: #0E1F81;
            font-style: normal;
            line-height: normal;
            font-family: Assistant;
        }
    }

    .progress-bar {
        width: 100vw;
        height: 4px;
        margin-left: -20px;
        background: #F6F6F6;

        // margin-bottom: 26px;
        // position: relative;
        .test-grey {
            // position: absolute;
            // background: #F6F6F6;
        }
    }

}

.btn-border {
    border-color: #0E1F81;
    color: #0E1F81;
    margin-bottom: 80px;
}

.btn-main {
    margin-bottom: 16px;
}

.btn {
    height: 45px;
}
.mobile-part{
    p {
        color: var(--Main-Blue, #0E1F81);
text-align: center;
font-family: Assistant;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
    }
}
.mobile-stores {
    padding-top: 20px;
    padding-bottom: 20px;
    img {
        width: 120px ;
        height: 40px ;
        margin-right: 20px;
    }
}

.main-container {
    min-height: none;
    overflow: hidden;
    background-image: none;
    // max-height: 100vh;
}

.kite {
    display: none;
}

.eclipse {
    margin: 0 8px
}

.transfer-info {
    margin-bottom: 12px;
    font-size: 14px;
}

.mobile-margin {
    margin-right: 20px;
    margin-left: 20px;
}

.zero-padding {
    padding: 0px !important;
}


.mygmt2-header-wrapper {
    background-color: #FFFF;
}

@media only screen and (min-width: $screen-tablet) {

    .btn-border {
    margin-bottom: 30px;
}


    .upper {
        margin-bottom: 10px;
    }

    .mobile-margin {
        margin-left: 0px;
        margin-right: 0px;
    }

    .main-container {
        min-height: 100vh;
        background-repeat: no-repeat;
        background-size: auto auto, auto auto;
        background-repeat: no-repeat, no-repeat;
        background-position: left bottom, right top;
        background-image: url('../../../../assets/mygmt_v2_images/mygmt2-backgground-white.svg'), url('../../../../assets/mygmt_v2_images/mygmt-backgground-white-small.svg');
    }

    .kite {
        right: 0px;
        top: 200px;
        z-index: 0;
        display: block;
        position: absolute;
    }


    .transfer-container {
        z-index: 2;
        width: 600px;
        margin: 0 auto;
        min-height: 70vh;
        margin-top: 40px;
        padding: 0px 24px;
        border-radius: 20px;
        background-image: none;

        .upper {
            align-items: flex-start;

            .money-title {
                font-size: 24px;
            }
        }

        .progress-bar {
            width: 100%;
            margin-left: 0px;
        }
    }
}
</style>
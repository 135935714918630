<template>
  <div :style="tagStyle" class="" :class="{'ribbon': type === 'RIBBON' && !isRtl, 'ribbon-rtl': type === 'RIBBON' && isRtl, 'tag-rtl': isRtl, 'tag': !isRtl}">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: "Tag",
  props: {
    backgroundColor: {
      type: String,
      default: "#f0f0f0"
    },
    textColor: {
      type: String,
      default: "#000000"
    },
    text: {
      type: String,
      default: "Tag Text"
    },
    type : {
      type: String
    }
  },
  computed: {
    tagStyle() {
      return {
        backgroundColor: this.backgroundColor,
        borderColor: this.backgroundColor,
        color: this.textColor,
        
      };
    },
    isRtl(){
      return this.$store.getters.getDir === 'rtl'
      },
  }
};
</script>

<style scoped>
.tag {
  font-weight: bold;
  position: absolute;
  /* right: -30px; */
  right: 20px;
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-block;
  font-size: 14px
}

.tag-rtl {
  font-weight: bold;
  position: absolute;
  /* right: -30px; */
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-block;
  font-size: 14px;
  left: 20px;
}



.ribbon {
  position: absolute;
  right:-10px;
  border-radius: 0;
}

.ribbon-rtl {
  position: absolute;
  left:-10px;
  border-radius: 0;
}

.ribbon::after {
  content: "";
    position: absolute;
    top: 28px;
    right: 0px;
    width: 10px;
    height: 10px;
    background-color: inherit;
    transform: rotate(180deg);
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    filter: brightness(95%);

}

.ribbon-rtl::after {
    content: "";
    position: absolute;
    top: 28px;
    left: 0px;
    width: 10px;
    height: 10px;
    background-color: inherit;
    transform: rotate(270deg);
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    filter: brightness(95%);

}
/* .ribbon::after {
  content: "";
    position: absolute;
    transform: rotate(-135deg);
    top: 24px;
    right: 4px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 6px 0 6px 6px;
    border-color: transparent transparent transparent inherit;
    /* background-color: inherit; */
    /* filter: brightness(95%); */
/* } */
/* .ribbon::after {
  content: "";
    position: absolute;
    top: 19px;
    right: 0;
    width: 10px;
    height: 17px;
    background-color: inherit;
    filter: brightness(95%);
    -webkit-clip-path: polygon(0 0, 100% 50%, 0 100%);
    clip-path: polygon(0 0, 100% 50%, 0 100%);
} */
</style>

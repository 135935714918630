<template>
  <div class="relative" :dir="$store.getters.getDir">
    <div class="amount-container">
      <h4>{{ $ml.get('transfer.titles.chooseAmount') }}</h4>
      <div @click.stop="$refs.numberInput.focus(); isCalculating = true; previousState.amount = amount"
        class="mock-input flex align-center justify-between">
        <input inputmode="numeric" ref="numberInput" type="text" v-model="formattedAmount" @input="formatAmount"
          @keyup.enter="calculate()" maxlength="35" max="6" min="0">
        <div class=" flex justify-between" style="width: 85px;height: 100%;">
          <div @click.stop="handleOpenCurrencySelector" class="relative flex full-center"
            :class="{ 'pointer': showDesktopMenu, 'justify-between': showDesktopMenu }" style="height: 100%;width: 100%;">
            <div class="currency-select-desktop" v-if="showCurrencySelector">
              <div class="">
                <div v-for="currency of allowedCurrencies" @click.stop="changeCurrency(currency)"
                  class="currency-options-container flex justify-between align-center">
                  <div class=" flex">
                    <img class="country-img" style="width: 20px;height: 20px;" :src="getFlagByCurrency(currency)" alt=""
                      srcset="">
                    <div class=""> {{ currency }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flag-container">
              <img  :src="getFlagByCurrency(selectedCurrency)" alt="">
              <div :style="{[$store.getters.getDir !== 'rtl' ? 'marginRight' : 'marginLeft']: '10px'}">{{ selectedCurrency }}</div> 
            </div>
            
            <img class="chevron" v-if="showDesktopMenu" src="../../../../assets/mygmt_v2_images/Right-Chevron.svg"
            style="transform: rotate(90deg);" alt="">
            <div v-if="showCurrencySelector" class="closer" @click.stop="showCurrencySelector = false"></div>
            <div v-else></div>
            
          </div>
        </div>
      </div>
    </div>

    <div class="overall-layout" v-if="!isCalculating && filterOptions && filterOptions.length">
      <h4> Choose transfer method</h4>
      <div v-if="filterOptions.length > 2" class="method-filter-container flex"
       >
        <div v-for="method of filterOptions" @click="setSelectedMethod(method)"
          class="method flex flex-column full-center pointer" :class="{ 'selected-method': selectedMethod == method }">
          <div class="img-container full-center">
            <img :src="getIconByName(method)" alt="">
          </div>
          <div class="text-container">
            {{ $ml.get('transfer.deliveryOptions.' + method) }}
          </div>
        </div>
      </div>


      <div style="padding: 0px 5px;" class="flex flex-column">
        <div v-for="(deliveryOption, index) of fiteredDeliveryOptions"
          class="delivery-options-container  flex flex-column justify-between"
          :class="{ 'grey-unaviable': deliveryOption.unAviable, 'recent-transaction': deliveryOption.isRecentTransaction }">
          <div class="option-header flex align-center relative">
            <img style="width: 24px; height: 24px;" :src="getIconByValue(deliveryOption)" alt="">
            <div> {{ $ml.get('transfer.deliveryOptions.' + deliveryOption.transactionClass) }} {{
              correspondentName(deliveryOption) }} </div>

            <Tag v-if="deliveryOption.isPopular" backgroundColor="#E3FFE6"
              textColor="#287115" text="Popular" type="RIBBON"></Tag>
            <Tag v-if="deliveryOption.unAviable" backgroundColor="#FFF0CB"
              textColor="#BF7E1D" text="Unaviable" type="RIBBON"></Tag>
            <Tag v-if="deliveryOption.isRecentTransaction" backgroundColor="#E3F3FF"
              textColor="#152F71" text="Recently used" type="RIBBON"></Tag>

          </div>
          <div v-if="!deliveryOption.unAviable" class="option-info">
            <div class="flex justify-between">
              <div>I send:</div>
              <div> {{ deliveryOption.amount | formatComma }} {{ deliveryOption.currency }}</div>
            </div>
            <div class="flex justify-between">
              <div>Comission:</div>
              <div> {{ deliveryOption.commission | formatComma }} {{ deliveryOption.currency }}</div>
            </div>
            <div v-if="deliveryOption.conversionRate !== 1 && deliveryOption.estimatedExchangeRate !== 1"
              class="flex justify-between">
              <div v-if="deliveryOption.conversionRate || deliveryOption.estimatedExchangeRate">Exchange rate:</div>
              <div> {{ deliveryOption.conversionRate || deliveryOption.estimatedExchangeRate | formatComma }}</div>
            </div>
            <div class="flex justify-between bold">
              <div>I pay:</div>
              <div> {{ deliveryOption.amount + deliveryOption.commission | formatComma }} {{ deliveryOption.currency }}
              </div>
            </div>

            <div class="flex justify-between">
              <div>They recieve:</div>
              <div v-if="deliveryOption.totalReceiveAmount" >  {{ deliveryOption.totalReceiveAmount | formatComma }} {{ deliveryOption.payoutCurrency }} </div>
              <div v-else>will be calculated abroad</div>
            </div>

            <div v-if="deliveryOption.message" @click="openMoreInfo(deliveryOption)"
              class="more-information flex align-center">
              <img src="../../../../assets/mygmt_v2_images/info.svg" alt="">

              <div>More Information</div>
            </div>
          </div>
          <div class="error-message" v-else>
            <div v-if="deliveryOption.amountLimited">
              The amount you wish to send exceeds the maximum transfer limit set by the provider.
            </div>
            <div v-else>
              Correspondent is locked.
            </div>
          </div>
          <div v-if="!deliveryOption.unAviable" class="option-button">
            <button @click="setTransactionClass(deliveryOption.index)" class="btn">{{ setSelectLabel(deliveryOption)
              }}</button>
          </div>
          <div v-else></div>
        </div>
      </div>
    </div>
    <div class="" style="height: 44vh;" v-if="isCalculating">
      <div class="flex flex-column full-center">
        <button @click="calculate();" class="calculate-btn btn">Calculate</button>
        <div v-if="getValidCashBackBalances.length" class="cash-back flex  align-center w-100">
          <div><img style="width: 50px;margin-left: 10px;"
              src="../../../../assets/mygmt_v2_images/Present-Cashback-Sparkling.svg" alt="" srcset=""></div>
          <div>
            <div>You have {{ getValidCashBackBalances[0].symbol + getValidCashBackBalances[0].amount }} cashback
              available for use.</div>
            <!-- <div>Minimum amount to use: ₪10</div> -->
          </div>
        </div>
      </div>
    </div>

    <lookup style="display: none;" @ready="lookupReady" ref="lookup" :logged-in="isLoggedIn" @execute="execute"
      :recaptchaToken="getRecaptchaToken"></lookup>

    <Drawer ref="currencySelectionDrawer" :title="'Select currency'" :drawerId="'currencySelectionDrawer'"
      class="drawer-wrapper-card">
      <template v-slot:content>
        <div class="currency-wrapper">
          <div v-for="currency of allowedCurrencies" @click="changeCurrency(currency)"
            class="currency-options-container flex justify-between align-center">
            <div class=" flex">
              <img class="country-img" style="width: 20px;height: 20px;" :src="getFlagByCurrency(currency)" alt=""
                srcset="">
              <div class=""> {{ currency }}</div>
            </div>
            <img src="../../../../assets/mygmt_v2_images/Right-Chevron-blue.svg" alt="">
          </div>
        </div>
      </template>
    </Drawer>

    <Drawer ref="moreInfoDrawer" :title="moreInfoDrawerInfo.title" :drawerId="'moreInfoDrawer'"
      class="drawer-wrapper-card">
      <template v-slot:content>
        <div class="flex flex-column justify-between" style="margin-top: 20px;flex: 1;">
          <div class="black" v-html="moreInfoDrawerInfo.content"></div>
          <div class="flex justify-center btn-thanks pointer" ><button @click="$refs.moreInfoDrawer.closeDrawer"  class="btn btn-main pointer">Thanks</button></div>
        </div>
      </template>
    </Drawer>

    <div v-if="$refs.currencySelectionDrawer && $refs.currencySelectionDrawer.show" class="closer backdrop line-flex"
      @click.stop="$refs.currencySelectionDrawer.closeDrawer = false"></div>
    <vue-recaptcha ref="recaptcha" @verify="onVerify" @expired="onExpired" size="invisible"
      :sitekey="sitekey"></vue-recaptcha>

  </div>
</template>
<script>
import _ from 'lodash';
import Lookup from '../../../../components/Lookup.vue';
import { getCountryByIso, isApp, isMobile, parseConstraints } from '../../../../services/utils';
import { getBeneficiary, validateBeneficiary, getTransactions, startTransaction } from '../../../../services/api'

import Drawer from '../../../../components/Drawer.vue';
import Tag from './Tag.vue';
import { EventBus } from '../../../../event-bus';
import VueRecaptcha from 'vue-recaptcha';

const icons = {
  CASH_BACK: 'fas fa-gift',
  MAX_LOAD: 'fa fa-cc-visa',
  CARD_DEPOSIT: 'fa fa-credit-card',
  PAYMENT: 'fas fa-hand-holding-usd',
  BANK_TRANSFER: 'fa fa-university bank',
  WALLET_TRANSFER: 'fas fa-wallet'
}

const defaultCurrencies = ['ILS', 'USD', 'EUR']

export default {
  name: 'MethodAndAmountSelection',
  components: { Lookup, Drawer, Tag, VueRecaptcha },
  props: {
    previousStep: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      amount: '',
      previousState: { amount: '', currency: '' },
      isCalculating: false,
      selectedMethod: 'ALL',
      selectedCurrency: '',
      bla: ['euro', 'shekelz'],
      showCurrencySelector: false,
      isMobileDevice: isMobile(),
      name: 'MethodAndAmountSelection',
      cloneInfo: null,
      selectedBeneficiaryId: null,
      recaptchaToken: null,
      sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      created: false,
      moreInfoDrawerInfo: {}

    };
  },

  beforeCreate() {
    EventBus.$emit('load');
    console.log('before created');

  },

  created() {
    this.selectedCountry = getCountryByIso(this.$store.state.transfer.destinationCountry)
    console.log("🚀 ~ created123 ~ this.$store.state.transfer:", this.$store.state.transfer)
    this.cloneInfo = this.$store.state.transfer.cloneInfo
    this.selectedBeneficiaryId = this.$store.getters.getSelectedBeneficiary
    this.$store.commit('setCurrentOption', null);
    this.selectedCurrency = this.selectedCountry.defaultCurrency
    this.previousState.currency = this.selectedCurrency

  },


  computed: {
    fiteredDeliveryOptions() {
      if (this.selectedMethod === 'ALL') return this.processedDeliveryOptions
      return this.processedDeliveryOptions.filter((i) => i.transactionClass.includes(this.selectedMethod.split('_')[0]))
    },

    getValidCashBackBalances() {
      const balances = this.getCashBackBalances
      console.log("🚀 ~ getValidCashBackBalances ~ balances:", balances)
      const seachTermCurrency = this.selectedCurrency !== 'USD' && this.selectedCurrency !== 'EUR' ? 'ILS' : this.selectedCurrency
      const filterRes = balances.filter((balance) => balance.amount > 0 && seachTermCurrency == balance.currency)
      return filterRes
    },

    getCashBackBalances() {
      return this.$store.getters.getCashBackBalances || []
    },

    getRecaptchaToken() {
      return this.recaptchaToken;
    },

    isLoggedIn() {
      return this.$store.getters.getLoggedIn && !this.$store.getters.getNotSigned;
    },


    allowedCurrencies() {
      return this.selectedCountry.allowedCurrencies.includes('ILS') ? this.selectedCountry.allowedCurrencies : ['ILS', ...this.selectedCountry.allowedCurrencies]
    },

    processedDeliveryOptions() {
      const deliveryOptions = _.cloneDeep(this.deliveryOptions);
      const recentTransactions = this.$store.getters.getCachedTransactions;
      if (!recentTransactions && this.isLoggedIn) {
        getTransactions(0, (data) => {
          if (data && data.length) {
            this.$store.commit('setCachedTransactions', data)
          }
        })
      }

      let recentDeliveryOption = null;
      let popularOption = null;

      deliveryOptions.forEach(option => {
        this.markUnAviable(option);
      });

      if (this.selectedBeneficiaryId) {
        recentDeliveryOption = this.findRecentByBeneficiary(recentTransactions, this.selectedBeneficiaryId, deliveryOptions);
      }

      if (!recentDeliveryOption) {
        recentDeliveryOption = this.findRecentRegularly(recentTransactions, deliveryOptions);
      }

      popularOption = this.findPopularOption(deliveryOptions);

      deliveryOptions.forEach(option => {
        if (this.cloneInfo && this.cloneInfo.transactionClass && this.cloneInfo.correspondentName && option.transactionClass === this.cloneInfo.transactionClass && option.correspondentName === this.cloneInfo.correspondentName) {
          option.isRecentTransaction = true;
          recentDeliveryOption = option;
        }

        if (recentDeliveryOption === option) {
          option.isRecentTransaction = true;
        }
      });

      if (popularOption && (!recentDeliveryOption || popularOption !== recentDeliveryOption)) {
        popularOption.isPopular = true;
      }

      return _.sortBy(deliveryOptions, option => {
        if (option.isRecentTransaction) return 0;
        if (option.isPopular) return 1;
        if (!option.unAviable) return 2;
        return 3;
      });
    },
    transferStatus() {
      return this.$store.getters.getTransferStatus
    },


    showDesktopMenu() {
      return this.selectedCountry.allowedCurrencies.length > 1
  },
    beneficiaries() {
      return Object.filter(this.$store.getters.getListBeneficiary, item => {
        return (item.countryIso3.indexOf(this.selectedCountry.iso) > -1);
      });
    },

    deliveryOptions() {
      return this.$store.getters.getTransferStep1Response.filter(element => element.transactionClass !== 'WALLET_DEPOSIT' && element.transactionClass !== 'WALLET_TO_WALLET' && element.transactionClass !== 'WALLET_FROM_WALLET' && (element.transactionClass !== 'CARD_DEPOSIT' || element.destinationCountry !== 'ISR')).map((item, index) => {
        item['index'] = index
        return item
      }) || [];
    },
    filterOptions() {
      const deliveryOptionsUnique = this.removeDuplicatesByFirstWord(this.deliveryOptions.map((i) => i.transactionClass))
      return deliveryOptionsUnique

    },
    getCurrencyIcon() {
      const currenciesIconMap = {}
    },
    formattedAmount: {
      get() {
        return this.formatNumber(this.amount);
      },
      set(newValue) {
        this.amount = newValue.replace(/[^0-9.]/g, '');
        this.$store.commit('setLookupAmount', this.amount);

      }
    }
  },
  watch: {
    // amount(newValue){
    // this.amount() = newValue + 'bla'
    // }
    selectedCurrency(newval, oldval) {
      if (oldval && newval) {
        this.previousState.currency = oldval
        if (this.isExoticCurrency(newval)) {
          this.$refs.lookup.changeAmountTypeByVar(false);
          this.$store.commit('setLookupCurrency', 'ILS');
          this.$refs.lookup.setCurency('ILS');
        } else {
          this.$store.commit('setLookupCurrency', newval);
          this.$refs.lookup.setCurency(newval);
          this.$refs.lookup.changeAmountTypeByVar(true);
        }
        this.$store.commit('setLookupAmount', this.amount);
        if (this.isCalculating) return
        console.log("🚀 ~ selectedCurrency ~ this.amount:", this.amount);
        this.$refs.lookup.setAmount(this.amount);
        this.attemptLookUp()
      }
    },
    transferStatus(newval, oldval) {
      if (newval === 'NO_DELIVERY_OPTIONS') {
        this.$emit('changeStep', 0)
      }
    }

  },
  mounted() {
    // const { defaultAmount, defaultCurrency } = this.selectedCountry

    // this.amount = this.previousStep === 'BeneficiarySelection' ? this.$store.getters.getLookupAmount : defaultAmount
    // console.log("🚀 ~ mounted ~ this.amount111111111111111111111111111:", this.amount)
    // this.$refs.lookup.setAmount(this.amount);
    // this.$refs.lookup.setCurency(defaultCurrency);
    // this.$refs.currencySelectionDrawer.openDrawer()
    // this.$store.commit('setLookupAmount', this.amount);
    // this.$store.commit('setLookupCurrency', defaultCurrency);

    // console.log('drawer', this.$refs.currencySelectionDrawer);


  },
  beforeDestroy() {
    // this.$refs.lookup.setAmount(null);
    // this.$refs.lookup.setCurency(null);
    // this.$store.commit('setLookupAmount',null);
    // this.$store.commit('setLookupCurrency', null);
  },
  methods: {
    attemptLookUp() {
      this.isLoggedIn ? this.$refs.lookup.doLookup() : this.execute()
    },

    isOverLimit(deliveryOption) {
      return this.amount > deliveryOption.amountLimit
    },

    iconClass(item) {
      return item.transactionClass === 'WALLET_TRANSFER' && item.destinationCountry === 'CHN' ? item.correspondentIdentifier === 'THUNES_WECHATPAY' ? 'fa fa-weixin' : 'fab fa-alipay' : (icons[item.transactionClass] || 'fa fa-money')
    },
    getIconByValue(deliveryOption, item) {
      if (!deliveryOption || !deliveryOption.transactionClass) return;
      let icon = deliveryOption.transactionClass;
      if ((icon === 'WALLET_TRANSFER' || icon === 'CARD_DEPOSIT') && deliveryOption.destinationCountry === 'CHN') {
        icon = deliveryOption.correspondentName || 'WALLET_TRANSFER'
      }
      return this.getIconByName(icon);
    },
    getIconByName(iconName) {
      if (iconName === 'WALLET_DEPOSIT' || iconName === 'WALLET_TO_WALLET') {
        iconName = 'WALLET_TRANSFER';
      }
      return require(`../../../../assets/mygmt_v2_images/transfer_methods/${iconName}.svg`);
    },
    correspondentName(deliveryOption) {
      if (deliveryOption.destinationCountry === 'CHN') {
        return deliveryOption.transactionClass === 'WALLET_TRANSFER' ? '- ' + (deliveryOption.correspondentIdentifier === 'THUNES_WECHATPAY' ? this.$ml.get('NAME_WECHATPAY') : this.$ml.get('NAME_ALIPAY')) : deliveryOption.transactionClass === 'CARD_DEPOSIT' ? '- ' + this.$ml.get('NAME_UNIONPAY') : '';
      } else if (deliveryOption.transactionClass === 'WALLET_TRANSFER' && deliveryOption.destinationCountry === 'IND') {
        return '- ' + this.$ml.get('NAME_UPI')
      }

      if (deliveryOption.transactionClass === 'CASH_TRANSFER') {
        return deliveryOption.correspondentName
      }
    },
    setSelectLabel(deliveryOption) {
      if (this.cloneInfo && this.cloneInfo.transactionClass && deliveryOption.isRecentTransaction) {
        return 'Send Again'
      } else {
        return 'Select'
      }
    },
    handleOpenCurrencySelector() {
      if (!this.showDesktopMenu) return
      this.isMobileDevice ? this.$refs.currencySelectionDrawer.openDrawer() : this.showCurrencySelector = true;
    },
    openMoreInfo(transferOption) {
      this.moreInfoDrawerInfo = {
        title: this.$ml.get(transferOption.transactionClass) ,
        content: transferOption.message
      }
      // this.isMobileDevice ? this.$refs.moreInfoDrawer.openDrawer() : this.showCurrencySelector = true;
      this.$refs.moreInfoDrawer.openDrawer()

    },
    async setTransactionClass(index) {
      if (this.isLoggedIn) {
        EventBus.$emit('load');
      this.$store.commit('setCurrentOption', index);
      sessionStorage.setItem('currentDeliveryOption', JSON.stringify(this.$store.getters.getSelectedTransferOption));
      await this.storeRequiredFields()
      EventBus.$emit('unload');
        this.$emit('changeStep', 2)
      } else {
        this.$router.push('/my-gmt-welcome')
      }
    },


    async storeRequiredFields() {
      try {
        let selectedOption = this.$store.getters.getSelectedTransferOption;
        let lookupDto = selectedOption.lookupDto;
        let response = await startTransaction({
          "amount": selectedOption.amount,
          "currency": selectedOption.currency,
          "destinationCountry": selectedOption.destinationCountry,
          "sessionId": selectedOption.sessionId,
          "payoutCurrency": selectedOption.payoutCurrency,
          "transactionClass": selectedOption.transactionClass,
          "correspondentId": selectedOption.correspondentId,
          "commission": selectedOption.commission,
          "amountLimit": selectedOption.amountLimit,
          "transactionRate": this.$store.getters.getSelectedExchangeRate,
          "lookupAmount": lookupDto && lookupDto.lookupAmount,
          "lookupRate": lookupDto && lookupDto.rate,
          "lookupBaseRate": lookupDto && lookupDto.baseRate
        });
        console.log("🚀 ~ storeRequiredFields ~ response:", response)
        // if (!response) throw 'Error'
        

        parseConstraints(response.fields)
        sessionStorage.setItem('requiredFields', JSON.stringify(response.fields));
        this.$store.commit('setCreditAvailabilityMap', response.creditAvailabilityMap);

      }

      catch (error) {
        // this.$emit('changeStatus', {main: 'ERROR', subType: 'DISCONNECTED'})
      console.log("🚀 ~ storeRequiredFields ~ error:", error)
      console.log("🚀 ~ storeRequiredFields ~ error:", error)
      console.log("🚀 ~ storeRequiredFields ~ error:", error)

      }
    },
    removeDuplicatesByFirstWord(arr) {
      const map = new Map();
      arr.forEach(item => {
        const firstWord = item.split('_')[0];
        if (!map.has(firstWord)) {
          map.set(firstWord, item);
        }
      });
      return ['ALL', ...Array.from(map.values())]
    },

    setDeliveryOptionsPriority(deliveryOptions) {

    },
    changeCurrency(currency) {
      this.isMobileDevice ? this.$refs.currencySelectionDrawer.closeDrawer() : this.showCurrencySelector = false;
      this.selectedCurrency = currency
    },

    calculate() {
      const cleanAmount = this.amount.replaceAll(',', '');
      this.$refs.lookup.setAmount(cleanAmount);
      this.attemptLookUp()
      this.isCalculating = false
    },
    onVerify: function (recaptchaToken) {
      this.recaptchaToken = recaptchaToken;
      setTimeout(() => this.$refs.lookup.doLookup(), 300);
    },
    onExpired: function () {
      this.$refs.recaptcha.reset();
    },
    execute() {
      this.$refs.recaptcha.execute();
    },

    lookupReady() {
      let { defaultAmount, defaultCurrency, iso, allowedCurrencies } = this.selectedCountry
      defaultAmount = this.$route.query.amount || defaultAmount
      defaultCurrency = this.$route.query.currency || defaultCurrency


      const isExoticCurrency = this.isExoticCurrency(defaultCurrency)
      const fallbackCurrency = this.$refs.lookup.currencies[0].currency
      console.log("🚀 ~ lookupReady ~ this.$refs.lookup.currencies:", this.$refs.lookup.currencies)
      console.log("🚀 ~ lookupReady ~ fallbackCurrency:", fallbackCurrency)
      console.log("🚀 ~ lookupReady ~ allowedCurrencies:", allowedCurrencies)
      allowedCurrencies = this.$refs.lookup.currencies.map((currency)=> currency.currency)
      console.log("🚀 ~ lookupReady ~ allowedCurrencies:", allowedCurrencies)

      const lookUpCurrency = isExoticCurrency ? fallbackCurrency : defaultCurrency
      const payoutCurrency = this.$refs.lookup.payoutCurrency
      this.amount = this.previousStep === 'BeneficiarySelection' ? this.$store.getters.getLookupAmount || defaultAmount : defaultAmount
      this.$refs.lookup.setAmount(this.amount);
      this.$refs.lookup.setCurency(lookUpCurrency);
      console.log(this.$refs.lookup.currencies);
      console.log(this.$refs.lookup.payoutCurrency);

      this.$store.commit('setLookupAmount', this.amount);
      this.$store.commit('setLookupCurrency', lookUpCurrency);

      if (isExoticCurrency && this.$refs.lookup.payoutCurrency === defaultCurrency) {
        this.$refs.lookup.changeAmountTypeByVar(false)
      } else {
        this.selectedCurrency = this.$refs.lookup.currencies[0].currency
        this.selectedCountry.allowedCurrencies = allowedCurrencies.filter((currency) => currency !== defaultCurrency)
      }
      if (payoutCurrency && !allowedCurrencies.includes(payoutCurrency)) this.selectedCountry.allowedCurrencies = [...allowedCurrencies, payoutCurrency]
      // if (this.deliveryOptions.length && this.deliveryOptions.every((option) => option.destinationCountry === iso)) {
      //   EventBus.$emit('unload');
      //   return
      // }
      this.attemptLookUp()
    },

    isExoticCurrency(currency) {
      return !defaultCurrencies.includes(currency)
    },

    setSelectedMethod(method) {
      this.selectedMethod = method
    },
    formatAmount(event) {
      let input = event.target.value;
      input = input.replace(/[^0-9.]/g, '');
      if (input.startsWith('0') && !input.startsWith('0.')) {
        input = input.substring(1);
      }
      this.amount = input;
      this.$store.commit('setLookupAmount', this.amount);

      event.target.value = this.formatNumber(input);
    },
    formatNumber(value) {
      if (!value) return '';
      const parts = value.split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return parts.join('.');
    },

    getFlagByCurrency(currency) {

      let countryIso = ''
      switch (currency) {
        case 'EUR':
          countryIso = 'EUU'
          break;
        case 'ILS':
          countryIso = 'ISR'
          break;
        case 'USD':
          countryIso = 'USA'
          break;
        default:
          countryIso = this.selectedCountry.iso
          break;
      }
      return require(`../../../../assets/mygmt_v2_images/countries/${countryIso}.svg`)

    },

    findRecentByBeneficiary(recentTransactions, beneficiaryId, deliveryOptions) {
      const recentTransaction = recentTransactions.find(transaction => transaction.beneficiaryId === beneficiaryId);

      if (recentTransaction) {
        return deliveryOptions.find(option =>
          option.destinationCountry === recentTransaction.countryIso &&
          option.transactionClass === recentTransaction.transactionClass && !option.unAviable
        );
      }

      return null;
    },

    findRecentRegularly(recentTransactions, deliveryOptions) {
      if (!this.isLoggedIn || !recentTransactions) return recentDeliveryOption
      let recentTransactionIndex = null;
      let recentDeliveryOption = null;

      deliveryOptions.forEach((option) => {
        if (option.amountLimited || !option.canSend || option.amount <= 0) {
          option.unAviable = true;
          return;
        }

        const transactionIndex = recentTransactions.findIndex(transaction =>
          transaction.countryIso === option.destinationCountry &&
          transaction.transactionClass === option.transactionClass && !option.unAviable
        );

        if (transactionIndex !== -1 && (recentTransactionIndex === null || transactionIndex < recentTransactionIndex)) {
          recentTransactionIndex = transactionIndex;
          recentDeliveryOption = option;
        }
      });

      return recentDeliveryOption;
    },

    findPopularOption(deliveryOptions) {
      let popularOption = null;

      deliveryOptions.forEach(option => {
        if (option.priority !== null && !option.isRecentTransaction && !option.unAviable) {
          if (!popularOption || option.priority < popularOption.priority) {
            popularOption = option;
          }
        }
      });

      return popularOption;
    },
    markUnAviable(option) {
      if (option.amountLimited || !option.canSend || option.amount <= 0) {
        option.unAviable = true;
      } else {
        option.unAviable = false;
      }
    }
    //     processDeliveryOptions(deliveryOptions, recentTransactions) {
    //     console.log("🚀 ~ processDeliveryOptions ~ recentTransactions:", recentTransactions)
    //     console.log("🚀 ~ processDeliveryOptions ~ deliveryOptions:", deliveryOptions)
    //   let recentDeliveryOption = null;
    //   let recentTransactionIndex = null;
    //   let popularOption = null;

    //   // Step 1: Iterate through deliveryOptions
    //   deliveryOptions.forEach((option, index) => {
    //     option.amount = option.amount
    //     // Find matching recent transaction for this deliveryOption
    //     const transactionIndex = recentTransactions.findIndex(transaction =>
    //       transaction.countryIso === option.destinationCountry &&
    //       transaction.transactionClass === option.transactionClass
    //     );

    //     // Step 2: Check for the first match in recentTransactions (lowest index)
    //     if (transactionIndex !== -1) {
    //       if (recentTransactionIndex === null || transactionIndex < recentTransactionIndex) {
    //         recentTransactionIndex = transactionIndex;
    //         recentDeliveryOption = option;
    //       }
    //     }

    //     // Step 3: Check for the lowest priority that is not null
    //     if (option.priority !== null && !option.isRecentTransaction) {
    //       if (!popularOption || option.priority < popularOption.priority) {
    //         popularOption = option;
    //       }
    //     }
    //   });

    //   // Step 4: Set isRecentTransaction true for the corresponding delivery option
    //   if (recentDeliveryOption) {
    //     console.log("🚀 ~ processDeliveryOptions ~ recentDeliveryOption:", recentDeliveryOption)
    //     recentDeliveryOption.isRecentTransaction = true;
    //   }

    //   // Step 5: Set isPopular true for the delivery option with the lowest priority (if it's not the recent one)
    //   if (popularOption && (!recentDeliveryOption || popularOption !== recentDeliveryOption)) {
    //     popularOption.isPopular = true;
    //   }

    //   // Step 6: Sort the deliveryOptions array so that isRecentTransaction comes first, then isPopular
    //   // deliveryOptions.sort((a, b) => {
    //   //   if (a.isRecentTransaction) return -1;
    //   //   if (b.isRecentTransaction) return 1;
    //   //   if (a.isPopular) return -1;
    //   //   if (b.isPopular) return 1;
    //   //   return 0; // Keep the order of other options as it is
    //   // });

    //   this.$store.commit('setTransferStep1Response', deliveryOptions.map((i)=> i));


    //   return deliveryOptions;
    // }
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/styles/partials/variables";

[dir="rtl"] 

.limit-height {
  min-height: 150px;
}

.btn-thanks {
 margin-bottom: 40px;
  button {
  width: 100%;
  height: 45px;
  }
}

.error-message {
  color: #000;
  font-family: Assistant;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px
}

.grey-unaviable {
  background: #F3F3F3 !important;
  box-shadow: 0px 4px 24px 0px rgba(177, 177, 177, 0.13) !important;
  min-height: 150px !important;

}

.backdrop {
  backdrop-filter: blur(10px);
}

.drawer-wrapper-card {
  left: 0px;
  bottom: 0px;
  border-radius: 20px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  width: 100vw;
  padding-bottom: 0px;
  min-height: 30%;
  // position: absolute;
  // inset-block-start: 500px;
}

.overall-layout {
  max-height: 70vh;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: visible;
  overflow-y: auto;
}

.transfer-container {
  //  padding: 0px 20px !important;
}


.white-screen {
  // height: 100vh;
  // width: 100vw;
  background-color: white;
  opacity: 0.5;
  position: 'absolute';
  z-index: 999999999;

}

.currency-wrapper {

  .currency-options-container:last-child {
    border-bottom: none;
  }
}


.more-information {
  color: var(--Main-Blue, #0E1F81);
  font-family: Assistant;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;

  img {
    margin-right: 5px
  }
}

[dir="rtl"] .more-information img{  
    margin-right: 0px;
    margin-left: 5px;

  
}

.more-information :hover {
  color: var(--Main-Blue, #1832c5);

}



.currency-options-container {

  color: #000;
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Assistant;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  height: 70px;
  border-bottom: 1px solid #EEE;
  // z-index: 10;

  .country-img {
    margin-right: 10px;
  }
}

h4 {
  margin-bottom: 20px;
  color: #000;
  font-size: 20px;
  font-weight: 600;
}

[dir='rtl'] .amount-container .mock-input {
  padding: 0px 16px 0px 10px;

}

.amount-container {

  .mock-input {
    width: 100%;
    height: 64px;
    border-radius: 5px;
    border: 1px solid #E4E8EB;
    background: #FFF;
    padding: 0px 10px 0px 16px;
    cursor: text;
    margin-bottom: 20px;
    
    .flag-container {
      width: 20px;height: 20px;
      margin-right: 3px;
      gap: 3px;
    }

    input {
      border: none;
      color: #161932;
      font-family: Assistant;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      width: 75%;
    }

    input[type=number] {
      -moz-appearance: textfield;
    }

    .currency-select {
      border: none;
      background-color: white;
      cursor: pointer;
      height: 40px;
      width: 95px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

}

.calculate-btn {
  height: 45px;
  width: 100%;
  margin-bottom: 26px;
}

.cash-back {
  height: 80px;
  border-radius: 8px;
  border: 1px solid #E4E8EB;
  box-shadow: 0px 4px 24px 0px rgba(177, 177, 177, 0.13);
}

.closer {
  // background-color: rgba(128, 128, 128, 0.26);
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
}


.currency-select-desktop {
  width: 200px;
  position: absolute;
  background-color: white;
  top: 40px;
  z-index: 1001;
  left: -99px;
  border-radius: 5px;
  border: 1px solid #EBEBEB;
  background: #FFF;
  box-shadow: 0px 4px 24px 0px rgba(177, 177, 177, 0.20);

  .currency-options-container {
    padding: 10px;
  }

  .currency-options-container:hover {
    color: #0E1F81;
    background-color: #F1F6F9;

  }
}

.padding-left-20 {
  padding-left: 20px;
}

.method-filter-container {
  margin-top: 20px;
  overflow-x: auto;
  margin-bottom: 10px;




  .method {
    // transition: all 3.3;
    margin-bottom: 20px;
    margin-right: 10px;
    // min-width: 84px;
    width: 114px;
    max-width: 114px;

    color: #0E1F81;
    text-align: center;
    font-family: Assistant;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    // margin-right: 19px;
    height: 68px;
    // min-width: 72px;
    justify-content: space-between;
    // width: 90px;
    // min-width: 90px;

    .text-container {
      // width: 180px;
      font-size: 14px;
      white-space: nowrap;
      // margin-top: 2px;
    }



    .img-container {
      border-radius: 100%;
      min-width: 60px;
      min-height: 60px;
      border: 1.5px solid #EBEBEB;
      margin-bottom: 7px;


      img {
        width: 28px;
        height: 28px;
      }
    }

    &:first-of-type {
      margin-left: -15px
    }
  }

  .method:hover {
    .img-container {
      // border-radius: 100%;
      // min-width: 46px;
      // min-height: 46px;
      border: 1.5px solid #0E1F81;
      // img {
      //   width: 23px;
      //   height: 23px;
      // }

    }

    .text-container {
      // width: 115px;
      // font-size: 12px;
      // font-weight: bold;
      text-shadow: 0px 0px 1px black;
    }
  }

}



.selected-method {
  // font-weight: 700;
  text-shadow: 0px 0px 1px black;


  .img-container {
    border: 1.5px solid #0E1F81 !important;
    box-shadow: 0px 0.5px 3px #0e1f81a3;

  }
}

.delivery-options-container {
  border-radius: 8px;
  border: 1px solid #EBEBEB;
  background: var(--Neutral-White, #FFF);
  box-shadow: 0px 4px 24px 0px rgba(177, 177, 177, 0.13);
  min-height: 263px;
  margin-bottom: 20px;

  .option-header {
    border-bottom: 1px solid #EBEBEB;
    padding: 14px;
    font-weight: 700;

    img {
      margin-right: 10px;
    }

    

  }

  .option-info {
    height: 100%;
    padding: 10px 14px;
    color: #000;
    font-family: Assistant;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    .flex {
      margin-top: 4px;
    }

    .bold {
      font-size: 16px;
      font-weight: 700;
    }

    //  div {
    //   margin-bottom: 20px;
    //  }
  }

  .option-button {
    border-top: 1px solid #EBEBEB;
    height: 60px;
    padding: 12px 14px;

    button {
      width: 100%;
    }

  }

  &:hover {
    border: 1px solid #344985;
  }
}

[dir="rtl"] .delivery-options-container .option-header img {
    margin-right: 0px;
    margin-left: 10px;
  }

.recent-transaction {
  border-radius: 8px;
  border: 1px solid #6F8CDD;
  background: var(--Neutral-White, #FFF);
  box-shadow: 0px 4px 12px 0px rgba(111, 140, 221, 0.16);
}

::v-deep .lines-box-wrapper {
    flex: 1;
  }


@media only screen and (min-width: $screen-tablet) {

  h4 {
    font-size: 18px;
  }

  .drawer-wrapper-card {
    width: 20%;
    border-radius: 0;
    text-align: center;
  }

  ::v-deep .go-back-wrapper {
    position: absolute;
    top: 15px;
    left: 5%;

  } 
   
   ::v-deep .head-line {
    display: flex;
    justify-content: center !important;
    border-bottom: 1px solid #D7D7D7;
   }


  .overall-layout {
    max-height: 50vh;
  }

  .mock-input {
    height: 58px;
  }

  .calculate-btn {
    height: 45px;
    width: 335px;
  }


  .method-filter-container {
    margin-top: 20px;


    .method {
      height: 80px;
      margin-bottom: 24px;

      .img-container {
        border-radius: 100%;
        min-width: 56px;
        min-height: 56px;
        border: 1.5px solid #EBEBEB;


        img {
          width: 29px;
          height: 29px;
        }
      }
    }
  }



  .delivery-options-container {
    border-radius: 8px;
    border: 1px solid #EBEBEB;
    background: var(--Neutral-White, #FFF);
    box-shadow: 0px 4px 24px 0px rgba(177, 177, 177, 0.13);
    min-height: 263px;

    .option-header {
      border-bottom: 1px solid #EBEBEB;
      padding: 14px;
      font-weight: 700;

      img {
        margin-right: 10px;
      }

    }

    .option-info {
      height: 100%;
      padding: 14px;
    }

    .option-button {
      border-top: 1px solid #EBEBEB;
      height: 74px;
      padding: 12px 14px;

      button {
        width: 100%;
        height: 46px;
      }

    }
  }

  .recent-transaction {
    border-radius: 8px;
    border: 1px solid #6F8CDD;
    background: var(--Neutral-White, #FFF);
    box-shadow: 0px 4px 12px 0px rgba(111, 140, 221, 0.16);
  }


}
</style>
